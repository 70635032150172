export enum BillTypes {
  VETERINARY = 1,
  FOOD = 2,
  RESIDENCE = 3,
  OTHER = 4
}

export const BillTypeLabels: Record<BillTypes, string> = {
  [BillTypes.VETERINARY]: 'common.billType_veterinary',
  [BillTypes.FOOD]: 'common.billType_food',
  [BillTypes.RESIDENCE]: 'common.billType_residence',
  [BillTypes.OTHER]: 'common.billType_other'
};
