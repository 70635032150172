<app-titlebar
  icon="fas fa-map-marker"
  [title]="titleText | translate"
  [parentTitle]="'titles.localización_list' | translate"
  parentPath="/localization-list"
></app-titlebar>
<div *ngIf="!showLoading" class="c-localization-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div>
      <div>
        <form [formGroup]="localizationForm" (ngSubmit)="sendLocalization()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-map-marker"></i>
              {{ 'titles.generalData' | translate }}:
            </legend>
            <div class="c-localization-detail__fieldset-content">
              <input type="hidden" formControlName="tenantId" />
              <input type="hidden" formControlName="id" />

              <div class="u-input">
                <bindy-input-text
                  formControlName="name"
                  label="{{ 'forms.name' | translate }}"
                  placeholder="{{ 'forms.name_placeholder' | translate }}"
                  required="true"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('name')">
                  <p
                    class="u-error"
                    *ngIf="localizationForm.get('name').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="address"
                  label="{{ 'forms.address' | translate }}"
                  placeholder="{{ 'forms.address_placeholder' | translate }}"
                  required="true"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('address')">
                  <p
                    class="u-error"
                    *ngIf="localizationForm.get('address').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>

              <div class="u-input">
                <p class="u-input__text">
                  {{ 'forms.city' | translate }}
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="u-input__content">
                  <input
                    type="text"
                    class="u-input__input"
                    placeholder="{{ 'forms.city_placeholder' | translate }}"
                    formControlName="city"
                  />
                </div>
                <ng-container *ngIf="inputValidation('city')">
                  <p
                    class="u-error"
                    *ngIf="localizationForm.get('city').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'forms.localization_type' | translate }}
                </p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="localizationTypeId"
                  >
                    <mat-option [value]="0">{{
                      'forms.select' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let localizationType of localizationTypes"
                      [value]="localizationType"
                    >
                      {{ localizationTypeLabels[localizationType] | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <!-- <div class="c-localization-detail__double">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="lat"
                    label="Latitud"
                    placeholder="Introduce la latitud"
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="lng"
                    label="Longitud"
                    placeholder="Introduce la longitud"
                  ></bindy-input-text>
                </div>
              </div> -->
            </div>
            <div class="u-detail__button">
              <div class="u-detail__button-left">
                <bindy-button
                  *ngIf="showDeleteButton && iCanDeleteLocalization"
                  text="{{ 'common.button_delete' | translate }}"
                  icon="fa-trash-alt"
                  color="danger"
                  (onClick)="deleteLocalization()"
                ></bindy-button>
                <div
                  *ngIf="showDeleteButton && !iCanDeleteLocalization"
                  class="c-localization-detail__error_lbl"
                >
                  {{ 'localizations.cantDelete' | translate }}
                </div>
              </div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
