export const SETTINGS = {
  VERSION: '2.4',
  APIRUL: 'https://orcaapp.org/api/',
  USERS_IMAGE_PATH: 'https://orcaapp.org/content/images/users/',
  ANIMALS_IMAGE_PATH: 'https://orcaapp.org/content/images/animals/',
  NEWS_IMAGE_PATH: 'https://orcaapp.org/content/images/news/',
  EVENTS_IMAGE_PATH: 'https://orcaapp.org/content/images/events/',
  TENANT_IMAGE_PATH: 'https://orcaapp.org/content/images/tenants/',
  DOWNLOADS_PATH: 'https://orcaapp.org/content/downloads/',
  SPECIE_IMAGE_PATH: '../assets/img/species/',
  LANGUAGE_IMAGE_PATH: '../assets/img/languages/',
  SNACKBAR_CONFIG_DURATION: 3000,
  USER_PROFILE_COOKIE: 'ORCA_USER_PROFILE',
  MOBILE_WINDOWS_SIZE: 992,
  LANGUAGE_COOKIE: 'LANGUAGE_SELECTED',
  LANGUAGE_DEFAULT: 'es'
};
