import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { ITenant } from 'src/app/interfaces/tenant';
import { ITenantsFilters } from 'src/app/interfaces/filters/tenantsFilters';
import { IImageUpload } from 'src/app/interfaces/imageUpload';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private apiUrl = SETTINGS.APIRUL + 'tenant/';

  constructor(private httpClient: HttpClient) {}

  getTenantsByFilters(filters: ITenantsFilters): Observable<ITenant[]> {
    return this.httpClient
      .post<ITenant[]>(this.apiUrl + 'getTenantsByFilters.php', filters)
      .pipe(
        map((data: ITenant[]) => {
          return data;
        })
      );
  }

  getTenantById(uuid: string): Observable<ITenant> {
    const params = new HttpParams().set('tenantUUID', uuid);

    return this.httpClient
      .get<ITenant>(this.apiUrl + 'getTenantById.php', { params })
      .pipe(
        map((data: ITenant) => {
          return data;
        })
      );
  }

  editTenant(tenant: ITenant): Observable<boolean> {
    return this.httpClient
      .post<boolean>(this.apiUrl + 'editTenant.php', tenant)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }

  postTenant(tenant: ITenant): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'addOrUpdateTenant.php', tenant)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  uploadImage(imageUpload: IImageUpload) {
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadTenantImage.php',
      imageUpload,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  deleteImage(tenantId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteTenantImage.php?tenantId=' + tenantId
    );
  }
}
