import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Languages, LanguagesLabels } from 'src/app/interfaces/enums/languages';
import { TranslateLocalService } from 'src/app/services/translate-local.service';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  constructor(
    private translateLocal: TranslateLocalService,
    private fb: UntypedFormBuilder
  ) {}

  languageLabels = LanguagesLabels;
  languages = Object.values(Languages);
  languageForm: UntypedFormGroup;
  selectedLanguage = this.translateLocal.languageSelected;

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.languageForm = this.fb.group({
      language: [this.translateLocal.languageSelected]
    });
  }

  selectLanguage() {
    this.translateLocal.changeAppLanguage(this.selectedLanguage);
  }

  getLanguageImage(lang: string): string {
    return SETTINGS.LANGUAGE_IMAGE_PATH + lang + '.svg';
  }
}
