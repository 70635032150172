import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { INews } from 'src/app/interfaces/news';
import { NewsService } from 'src/app/services/news.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  newsList: INews[] = [];
  showLoading: boolean;
  filtersVisibles: boolean;
  getScreenWidth: any;
  searchForm: UntypedFormGroup;
  months: any[] = [];
  years: any[] = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.setData();
    this.setScreenSize();
    this.getNews();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  getNews() {
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.newsService
      .getNewsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.newsList = data;
      });
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  search(): void {
    this.getNews();
  }

  cleanFilters(): void {
    this.initForm();
    this.getNews();
  }

  newsImage(image: string): string {
    return SETTINGS.NEWS_IMAGE_PATH + image;
  }

  private setData(): void {
    const date = new Date();
    const monthNames = [
      'common.month_january',
      'common.month_february',
      'common.month_march',
      'common.month_april',
      'common.month_may',
      'common.month_june',
      'common.month_july',
      'common.month_august',
      'common.month_september',
      'common.month_october',
      'common.month_november',
      'common.month_december'
    ];
    for (let i = 0; i < 12; i++) {
      this.months.push({
        text: monthNames[i],
        value: i + 1
      });
    }
    const startYear = 2021;
    const currentYear = date.getFullYear();
    if (currentYear > startYear) {
      for (let i = 2021; i <= currentYear; i++) {
        this.years.push({
          text: String(i),
          value: i
        });
      }
    }
  }

  private initForm() {
    this.searchForm = this.fb.group({
      title: [''],
      month: [0],
      year: [0],
      showNews: [true]
    });
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }
}
