import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AnimalDetailComponent } from './pages/animals/animal-detail/animal-detail.component';
import { AnimalListComponent } from './pages/animals/animal-list/animal-list.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { UserDetailComponent } from './pages/users/user-detail/user-detail.component';
import { LocalizationListComponent } from './pages/localizations/localization-list/localization-list.component';
import { SuggestionListComponent } from './pages/suggestions/suggestion-list/suggestion-list.component';
import { SuggestionDetailComponent } from './pages/suggestions/suggestion-detail/suggestion-detail.component';
import { LocalizationDetailComponent } from './pages/localizations/localization-detail/localization-detail.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { AuthGuard } from './authGuard';
import { Profiles } from './interfaces/enums/profiles';
import { NewsListComponent } from './pages/news/news-list/news-list.component';
import { NewsDetailComponent } from './pages/news/news-detail/news-detail.component';
import { NewsComponent } from './pages/news/news/news.component';
import { TenantProfileComponent } from './pages/tenant-profile/tenant-profile.component';
import { TenantSpeciesComponent } from './pages/tenant-species/tenant-species.component';
import { TenantListComponent } from './pages/tenants/tenant-list/tenant-list.component';
import { TenantDetailComponent } from './pages/tenants/tenant-detail/tenant-detail.component';
import { ControlPanelComponent } from './pages/control-panel/control-panel.component';
import { EventsComponent } from './pages/events/events-calendar/events.component';
import { EventsDetailComponent } from './pages/events/events-detail/events-detail.component';
import { LandingComponent } from './pages/landing/landing.component';
import { PublicLayoutComponent } from './layouts/public-layout/public.layout';
import { AppLayoutComponent } from './layouts/app-layout/app.layout';
import { DownloadsListComponent } from './pages/downloads/downloads-list/downloads-list.component';
import { UserSuplantComponent } from './pages/users/user-suplant/user-suplant.component';

const title = 'Orca App :: ';

export const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: '', component: LandingComponent, title: `${title} Welcome` },
      { path: 'login', component: LoginComponent, title: `${title} Login` }
    ]
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        title: `${title} Dashboard`
      },
      {
        path: 'user-list',
        component: UserListComponent,
        canActivate: [AuthGuard],
        title: `${title} Usuarios`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN]
        }
      },
      {
        path: 'user-suplant',
        component: UserSuplantComponent,
        canActivate: [AuthGuard],
        title: `${title} Usuarios`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'user-detail/:id',
        component: UserDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Usuarios`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN]
        }
      },
      {
        path: 'animal-detail/:id',
        component: AnimalDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Animales`
      },
      {
        path: 'animal-list/:id',
        component: AnimalListComponent,
        canActivate: [AuthGuard],
        title: `${title} Animales`
      },
      {
        path: 'animal-list',
        component: AnimalListComponent,
        canActivate: [AuthGuard],
        title: `${title} Animales`
      },
      {
        path: 'localization-list',
        component: LocalizationListComponent,
        canActivate: [AuthGuard],
        title: `${title} Localizaciones`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN]
        }
      },
      {
        path: 'localization-detail/:id',
        component: LocalizationDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Localizaciones`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN]
        }
      },
      {
        path: 'suggestion-list',
        component: SuggestionListComponent,
        canActivate: [AuthGuard],
        title: `${title} Sugerencias`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'suggestion-detail/:id',
        component: SuggestionDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Sugerencias`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN, Profiles.CONTRIBUTOR]
        }
      },
      {
        path: 'events',
        component: EventsComponent,
        canActivate: [AuthGuard],
        title: `${title} Eventos`
      },
      {
        path: 'events-detail/:id',
        component: EventsDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Eventos`
      },
      {
        path: 'my-profile',
        component: MyProfileComponent,
        title: `${title} Mi Perfil`
      },
      {
        path: 'news-list',
        component: NewsListComponent,
        canActivate: [AuthGuard],
        title: `${title} Noticias`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'news-detail/:id',
        component: NewsDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Noticias`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'news',
        canActivate: [AuthGuard],
        title: `${title} Noticias`,
        component: NewsComponent
      },
      {
        path: 'tenant-list',
        component: TenantListComponent,
        canActivate: [AuthGuard],
        title: `${title} Asociaciones`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'tenant-detail/:id/:uuid',
        component: TenantDetailComponent,
        canActivate: [AuthGuard],
        title: `${title} Asociaciones`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'tenant-profile',
        component: TenantProfileComponent,
        canActivate: [AuthGuard],
        title: `${title} Asociaciones`
      },
      {
        path: 'tenant-species',
        component: TenantSpeciesComponent,
        canActivate: [AuthGuard],
        title: `${title} Especies`,
        data: {
          role: [Profiles.GOD, Profiles.ADMIN]
        }
      },
      {
        path: 'control-panel',
        component: ControlPanelComponent,
        canActivate: [AuthGuard],
        title: `${title} Panel de Control`,
        data: {
          role: [Profiles.GOD]
        }
      },
      {
        path: 'downloads-list',
        component: DownloadsListComponent,
        canActivate: [AuthGuard],
        title: `${title} Descargas`
      }
    ]
  },
  // Default URL:
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  // 404 URL:
  { path: '**', redirectTo: 'landing', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
