import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/interfaces/user';
import { ProfileLabels, Profiles } from 'src/app/interfaces/enums/profiles';
import { ActivatedRoute, Router } from '@angular/router';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { ICountry } from 'src/app/interfaces/country';
import { MasterDataService } from 'src/app/services/master-data.service';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  uploadProgress: number;
  userForm: UntypedFormGroup;
  user: IUser;
  titleText = 'titles.createUser';
  isNewUser = true;
  inputDisabled = false;

  profileLabels = ProfileLabels;
  profiles = Object.values(Profiles).filter(
    (value) => typeof value === 'number' && value !== 1
  );

  private _userId: number;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private userProfileService: UserProfileService,
    private masterDataService: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get countries(): Array<ICountry> {
    return this.masterDataService.countries;
  }

  ngOnInit(): void {
    this._userId = Number(this.activatedRoute.snapshot.params.id);
    this.initForm();
    if (this._userId !== 0) {
      this.titleText = 'titles.editUser';
      this.isNewUser = false;
      this.getUserData();
      this.inputDisabled = true;
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  sendUser() {
    if (!this.userForm.valid) {
      markFormGroupTouched(this.userForm);
      return;
    }
    this.showLoading = true;
    const email = this.userForm.get('email').value;
    this.userService
      .checkIfUserExistByEmail(email)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((exist) => {
        if (exist) {
          this.showWarningToast('toast.USER_CREATE_EMAIL_EXIST');
          this.showLoading = false;
        } else {
          this.createUser();
        }
      });
  }

  createUser(): void {
    this.userService
      .postUser(this.userForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.showSuccessToast('toast.USER_CREATE_SUCCESS');
          this.goToList();
        })
      )
      .subscribe();
  }

  saveUserProfile() {
    const user = {
      id: this._userId,
      profileId: this.userForm?.value.profileId
    } as IUser;
    this.showLoading = true;
    this.userService
      .editUserProfile(user)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.showSuccessToast('toast.USER_EDIT_SUCCESS');
          this.goToList();
        })
      )
      .subscribe();
  }

  deleteUser(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_USER_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteUser();
        }
      });
  }

  confirmDeleteUser(): void {
    this.showLoading = true;
    this.userService
      .deleteUserById(this._userId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.USER_DELETE_SUCCESS');
          this.goToList();
        })
      )
      .subscribe();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.userForm, inputName);
  }

  private getUserData() {
    this.showLoading = true;
    this.userService
      .getUserById(this._userId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.userForm.patchValue(data[0]);
      });
  }

  private goToList() {
    this.router.navigate([`user-list`]);
  }

  private initForm() {
    this.userForm = this.fb.group({
      tenantId: [this.userProfileTenantId, Validators.required],
      name: ['', Validators.required],
      surName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      profileId: ['', Validators.required],
      countryId: [''],
      address: [''],
      city: [''],
      phone: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }

  private showWarningToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('w'));
  }
}
