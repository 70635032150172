import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IMasterData } from 'src/app/interfaces/master-data';
import { ICountry } from 'src/app/interfaces/country';
import { ISpecie } from 'src/app/interfaces/specie';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  private apiUrl = SETTINGS.APIRUL;

  public countries: ICountry[] = [];
  public species: ISpecie[] = [];
  public tenantSpecies: number[] = [];

  constructor(private httpClient: HttpClient) {}

  getMasterData(tenantId: string) {
    const params = new HttpParams().set('tenantId', tenantId);
    return this.httpClient
      .get<IMasterData>(this.apiUrl + 'getData.php', { params })
      .pipe(
        map((data: IMasterData) => {
          this.setMasterData(data);
        })
      );
  }

  setMasterData(data: IMasterData): void {
    this.countries = data.countries;
    this.species = data.species;
    this.updateTenantSpecies(data.tenantSpecies);
  }

  updateTenantSpecies(speciesIds: number[]): void {
    this.tenantSpecies = speciesIds;
  }
}
