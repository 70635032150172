import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IChartData } from 'src/app/interfaces/charts/chartData';
import { IAnimal } from '../interfaces/animal';
import { IDashboardTotals } from '../interfaces/dashboardTotals';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl = SETTINGS.APIRUL + 'dashboard/';

  constructor(private httpClient: HttpClient) {}

  getData(): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + 'getData.php').pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getTotals(tenantId: string): Observable<IDashboardTotals> {
    const params = new HttpParams().set('tenantId', tenantId);

    return this.httpClient
      .get<IDashboardTotals>(this.apiUrl + 'getTotals.php', { params: params })
      .pipe(
        map((data: IDashboardTotals) => {
          return data;
        })
      );
  }

  getSpeciesData(tenantId: string): Observable<IChartData[]> {
    const params = new HttpParams().set('tenantId', tenantId);

    return this.httpClient
      .get<IChartData[]>(this.apiUrl + 'getSpeciesData.php', { params: params })
      .pipe(
        map((data: IChartData[]) => {
          return data;
        })
      );
  }

  getLastCreatedAnimal(tenantId: string): Observable<IAnimal[]> {
    const params = new HttpParams().set('tenantId', tenantId);

    return this.httpClient
      .get<IAnimal[]>(this.apiUrl + 'getLastCreatedAnimal.php', {
        params: params
      })
      .pipe(
        map((data: IAnimal[]) => {
          return data;
        })
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error ocurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
