import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IAnimalImage } from 'src/app/interfaces/animalImage';

@Injectable({
  providedIn: 'root'
})
export class AnimalImageService {
  private apiUrl = SETTINGS.APIRUL + 'animalImage/';

  constructor(private httpClient: HttpClient) {}

  getAnimalImagesByAnimalId(animalId: number): Observable<IAnimalImage[]> {
    const params = new HttpParams().set('animalId', animalId);

    return this.httpClient
      .get<IAnimalImage[]>(this.apiUrl + 'getAnimalImageByAnimalId.php', {
        params
      })
      .pipe(
        map((data: IAnimalImage[]) => {
          return data;
        })
      );
  }

  uploadAnimalImage(animalImage: IAnimalImage) {
    //Mandatory: animalId, fileBase64, isPrincipal
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadAnimalImage.php',
      animalImage,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  deleteAnimalImage(animalImageId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteAnimalImage.php?animalImageId=' + animalImageId
    );
  }

  makeAnimalImageAsPrincipal(animalImage: IAnimalImage) {
    //Mandatory: id, animalId
    return this.httpClient.post<void>(
      this.apiUrl + 'makePrincipalAnimalImage.php',
      animalImage
    );
  }
}
