<app-titlebar
  [title]="'titles.animal_list' | translate"
  icon="fas fa-paw"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="animal-list">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addAnimal' | translate }}"
          icon="fa-plus"
          type="submit"
          color="success"
          (onClick)="openModalAddAnimal()"
        >
        </bindy-button>
        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <form
      [formGroup]="searchByLocationForm"
      *ngIf="!filtersVisibles && localizations.length > 0"
    >
      <div class="u-input u-input--select">
        <div class="u-input__content">
          <mat-select
            class="u-input__input"
            formControlName="localizationId"
            (selectionChange)="searchByLocation()"
          >
            <mat-option value="">{{
              'forms.select_location' | translate
            }}</mat-option>
            <mat-option value="0">{{
              'forms.noLocalization' | translate
            }}</mat-option>
            <mat-option
              *ngFor="let localization of localizations"
              [value]="localization.id"
            >
              {{ localization.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </form>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <input type="hidden" formControlName="tenantId" />
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <p class="u-input__text">{{ 'forms.name' | translate }}</p>
                <div class="u-input__content">
                  <input
                    type="text"
                    class="u-input__input"
                    id="input"
                    placeholder="{{ 'forms.name_placeholder' | translate }}"
                    formControlName="name"
                  />
                </div>
              </div>
              <div class="u-input">
                <p class="u-input__text">{{ 'forms.chip' | translate }}</p>
                <div class="u-input__content">
                  <input
                    type="text"
                    class="u-input__input"
                    id="input"
                    placeholder="{{ 'forms.chip_placeholder' | translate }}"
                    formControlName="chip"
                  />
                </div>
              </div>
              <div
                class="u-input u-input--select"
                *ngIf="this.species.length > 1"
              >
                <p class="u-input__text">{{ 'forms.specie' | translate }}</p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="specieId">
                    <mat-option value="">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let specie of species"
                      [value]="specie.id"
                    >
                      {{ specie.literal | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'forms.localization' | translate }}
                </p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="localizationId"
                  >
                    <mat-option value="">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option value="0">{{
                      'forms.noLocalization' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let localization of localizations"
                      [value]="localization.id"
                    >
                      {{ localization.name }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="animal-list__actions">
        <div class="u-actions__container">
          <div>
            <bindy-button
              text="{{ 'common.button_addAnimal' | translate }}"
              icon="fa-plus"
              type="submit"
              color="success"
              (onClick)="openModalAddAnimal()"
            >
            </bindy-button>
          </div>
        </div>
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ animals.length }}
        </div>
        <div class="animal-list__buttons-layout">
          <div
            class="animal-list__buttons-layout-btn"
            [ngClass]="{ 'animal-list__buttons-layout-btn--list': gridLayout }"
            (click)="setGridLayout()"
          ></div>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="animals.length === 0 && adoptedAnimals.length === 0 && passedAwayAnimals.length === 0" class="container">
      {{ 'animals.noAnimals' | translate }}
    </div> -->
    <div class="container">
      <div class="tab-slider--nav">
        <ul class="tab-slider--tabs">
          <li
            class="tab-slider--trigger"
            [class.active]="viewMode == 'inAdoptionTab'"
            rel="inAdoptionTab"
            (click)="viewMode = 'inAdoptionTab'"
          >
            {{ 'animals.in_adoption' | translate }}
            <span class="tab-slider--number">({{ animals.length }})</span>
          </li>
          <li
            class="tab-slider--trigger"
            [class.active]="viewMode == 'adoptedTab'"
            rel="adoptedTab"
            (click)="viewMode = 'adoptedTab'"
          >
            {{ 'animals.adopted' | translate }}
            <span class="tab-slider--number"
              >({{ adoptedAnimals.length }})</span
            >
          </li>
          <li
            class="tab-slider--trigger"
            [class.active]="viewMode == 'passawayTab'"
            rel="passawayTab"
            (click)="viewMode = 'passawayTab'"
          >
            🌈
            <span class="tab-slider--number"
              >({{ passedAwayAnimals.length }})</span
            >
          </li>
        </ul>
      </div>
      <fieldset class="u-fieldset u-fieldset--tabs">
        <div class="tab-slider--container" [ngSwitch]="viewMode">
          <div
            id="inAdoptionTab"
            class="tab-slider--body"
            *ngSwitchCase="'inAdoptionTab'"
          >
            <div
              *ngIf="animals.length > 0"
              class="animal-list__table"
              [ngClass]="{ 'animal-list__table--grid': gridLayout }"
            >
              <ng-container *ngFor="let animal of animals">
                <animal-card
                  [animalInput]="animal"
                  [gridLayoutInput]="gridLayout"
                  (click)="goToDetail(animal.id)"
                >
                </animal-card>
              </ng-container>
            </div>
            <div class="u-nocontent-text" *ngIf="animals.length === 0">
              {{ 'animals.noFoundAnimalsActives' | translate }}
            </div>
          </div>
          <div
            id="adoptedTab"
            class="tab-slider--body"
            *ngSwitchCase="'adoptedTab'"
          >
            <div
              *ngIf="adoptedAnimals.length > 0"
              class="animal-list__table"
              [ngClass]="{ 'animal-list__table--grid': gridLayout }"
            >
              <ng-container *ngFor="let animal of adoptedAnimals">
                <animal-card
                  [animalInput]="animal"
                  [gridLayoutInput]="gridLayout"
                  (click)="goToDetail(animal.id)"
                >
                </animal-card>
              </ng-container>
            </div>

            <div class="u-nocontent-text" *ngIf="adoptedAnimals.length === 0">
              {{ 'animals.noFoundAnimalsAdopted' | translate }}
            </div>
          </div>
          <div
            id="passawayTab"
            class="tab-slider--body"
            *ngSwitchCase="'passawayTab'"
          >
            <div
              *ngIf="passedAwayAnimals.length > 0"
              class="animal-list__table"
              [ngClass]="{ 'animal-list__table--grid': gridLayout }"
            >
              <ng-container *ngFor="let animal of passedAwayAnimals">
                <animal-card
                  [animalInput]="animal"
                  [gridLayoutInput]="gridLayout"
                  (click)="goToDetail(animal.id)"
                >
                </animal-card>
              </ng-container>
            </div>

            <div
              class="u-nocontent-text"
              *ngIf="passedAwayAnimals.length === 0"
            >
              {{ 'animals.noFoundAnimalsPassedAway' | translate }}
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
</div>

<app-modal-add-animal
  [openModal]="open"
  (closeModal)="closeModalAddAnimal()"
></app-modal-add-animal>
