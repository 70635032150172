export enum SuggestionSubjects {
  SUGGESTION = 'SUGGESTION',
  ERROR = 'ERROR',
  DEVELOPER_MESSAGE = 'DEVELOPER_MESSAGE'
}

export const SuggestionSubjectsLabels: Record<SuggestionSubjects, string> = {
  [SuggestionSubjects.SUGGESTION]: 'common.suggestion_suggestion',
  [SuggestionSubjects.ERROR]: 'common.suggestion_error',
  [SuggestionSubjects.DEVELOPER_MESSAGE]: 'common.suggestion_dev'
};
