import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SETTINGS } from '../SETTINGS';

@Injectable({
  providedIn: 'root'
})
export class TranslateLocalService {
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService
  ) {}

  public languageSelected: string;

  public initLanguageCookie() {
    const userLanguageStorage = localStorage.getItem(SETTINGS.LANGUAGE_COOKIE);

    if (userLanguageStorage) {
      this.changeAppLanguage(userLanguageStorage);
    } else {
      this.languageSelected = SETTINGS.LANGUAGE_DEFAULT;
    }
  }

  public changeAppLanguage(lang: string): void {
    this.translateService.use(lang);
    this.languageSelected = lang;
    this.setLanguageCookie(lang);
  }

  public setLanguageCookie(lang: string) {
    localStorage.setItem(SETTINGS.LANGUAGE_COOKIE, lang);
  }
}
