<div class="visible-xs">
  <div class="main-menu__brand">
    <div class="main-menu__brand-logo">
      <img src="assets/img/logo30.png" alt="" />
      <div class="main-menu__brand-text">
        {{ 'common.orca_app' | translate }}
      </div>
    </div>
    <div class="navbar__burger" (click)="presentMenu($event)">
      <span
        class="navbar__burger-menu"
        [ngClass]="isMenuVisible ? 'navbar__burger-menu--open' : ''"
      >
        <span class="navbar__burger-menu-line"></span>
        <span class="navbar__burger-menu-line"></span>
        <span class="navbar__burger-menu-line"></span>
      </span>
    </div>
    <div
      class="main-menu__suplant-identity"
      *ngIf="suplantingUser"
      (click)="returnToSuplantedUser()"
    >
      <img
        class="u-circle-image main-menu__bottom-img"
        [src]="suplantUserImage()"
      />
    </div>
  </div>
</div>
<nav class="main-menu" [ngClass]="isMenuVisible ? 'main-menu--open' : ''">
  <div
    class="main-menu__panel"
    [ngClass]="isMenuVisible ? 'main-menu__panel--open' : ''"
  >
    <div class="hidden-xs">
      <div class="main-menu__brand">
        <div class="main-menu__brand-logo">
          <img src="assets/img/logo30.png" alt="" />
          <div>
            <div class="main-menu__brand-text">
              {{ 'common.orca_app' | translate }}
            </div>
          </div>
          <div class="main-menu__brand-version">v {{ settings.VERSION }}</div>
        </div>
        <div
          class="main-menu__suplant-identity"
          *ngIf="suplantingUser"
          (click)="returnToSuplantedUser()"
        >
          <img
            class="u-circle-image main-menu__bottom-img"
            [src]="suplantUserImage()"
          />
        </div>
        <div class="navbar__burger" (click)="presentMenu($event)">
          <span
            class="navbar__burger-menu"
            [ngClass]="isMenuVisible ? 'navbar__burger-menu--open' : ''"
          >
            <span class="navbar__burger-menu-line"></span>
            <span class="navbar__burger-menu-line"></span>
            <span class="navbar__burger-menu-line"></span>
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="main-menu__content">
        <div class="main-menu__user">
          <div class="main-menu__user-text">{{ userProfileTenantName }}</div>
        </div>
        <div class="main-menu__user-img hidden-xs">
          <img src="{{ tenantImage() }}" alt="" />
        </div>
        <div class="main-menu__list">
          <a
            class="main-menu__item"
            routerLink="/dashboard"
            (click)="closeMenu()"
            ><i class="fas fa-home"></i
            ><span>{{ 'menu.home' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/control-panel"
            (click)="closeMenu()"
            *ngIf="activateControlMenuMenu"
            ><i class="fas fa-solar-panel"></i>
            <span>{{ 'menu.control_panel' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/animal-list"
            (click)="closeMenu()"
            ><i class="fas fa-paw"></i
            ><span>{{ 'menu.animals' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/user-list"
            (click)="closeMenu()"
            *ngIf="activateUserMenu"
            ><i class="fas fa-users"></i
            ><span>{{ 'menu.users' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/user-suplant"
            (click)="closeMenu()"
            *ngIf="activateUserSuplantMenu"
            ><i class="fas fa-users"></i
            ><span>{{ 'menu.usersSuplant' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/localization-list"
            (click)="closeMenu()"
            *ngIf="activateLocalizationMenu"
            ><i class="fas fa-map-marker"></i
            ><span>{{ 'menu.localizations' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/suggestion-list"
            (click)="closeMenu()"
            *ngIf="activateSuggestionListMenu"
            ><i class="fas fa-inbox"></i
            ><span>{{ 'menu.suggestions' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/downloads-list"
            (click)="closeMenu()"
            ><i class="fas fa-download"></i
            ><span>{{ 'menu.downloads' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/suggestion-detail/0"
            (click)="closeMenu()"
            *ngIf="activateAddSuggestionMenu"
            ><i class="fas fa-inbox"></i
            ><span>{{ 'menu.suggestions' | translate }}</span></a
          >
          <a class="main-menu__item" routerLink="/events/" (click)="closeMenu()"
            ><i class="far fa-calendar-alt"></i>
            <span>{{ 'menu.events' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/news-list"
            (click)="closeMenu()"
            *ngIf="activateNewsAdminMenu"
            ><i class="fas fa-newspaper"></i
            ><span>{{ 'menu.news_admin' | translate }}</span></a
          >
          <a class="main-menu__item" routerLink="/news" (click)="closeMenu()"
            ><i class="fas fa-newspaper"></i
            ><span>{{ 'menu.news' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/tenant-list"
            (click)="closeMenu()"
            *ngIf="activateTenantAdminMenu"
            ><i class="fas fa-building"></i
            ><span>{{ 'menu.tenants_admin' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/tenant-profile"
            (click)="closeMenu()"
            ><i class="fas fa-briefcase"></i
            ><span>{{ 'menu.tenant' | translate }}</span></a
          >
          <a
            class="main-menu__item"
            routerLink="/tenant-species"
            (click)="closeMenu()"
            *ngIf="activateTenantSpeciesMenu"
            ><i class="fas fa-dog"></i
            ><span>{{ 'menu.tenantSpecies' | translate }}</span></a
          >
        </div>
        <div class="main-menu__data">
          <div class="main-menu__bottom">
            <img
              class="u-circle-image main-menu__bottom-img"
              [src]="
                !!userData.image
                  ? userImage(userData.image)
                  : '/assets/img/user_default.png'
              "
            />
            <div>
              <p class="main-menu__bottom-text u-text-overflow">
                {{ userData.name }}
              </p>
              <p class="main-menu__bottom-text u-text-overflow">
                {{ profileLabels[userData.profileId] | translate }}
              </p>
            </div>
            <div
              (click)="closeMenu()"
              routerLink="/my-profile"
              class="main-menu__cog"
            >
              <i class="fas fa-cog"></i>
            </div>
          </div>
          <a
            class="main-menu__close-session"
            (click)="logout()"
            (click)="closeMenu()"
            ><i class="fas fa-sign-out-alt"></i
            ><span>{{ 'menu.close_session' | translate }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</nav>
