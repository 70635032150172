import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  SuggestionSubjects,
  SuggestionSubjectsLabels
} from 'src/app/interfaces/enums/suggestionSubjects';
import { ISuggestion } from 'src/app/interfaces/suggestion';
import { SuggestionService } from 'src/app/services/suggestion.service';
import { Router } from '@angular/router';
import {
  SuggestionStatus,
  SuggestionStatusLabels
} from 'src/app/interfaces/enums/suggestionStatus';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'app-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.scss']
})
export class SuggestionListComponent implements OnInit, OnDestroy {
  suggestions: ISuggestion[] = [];
  searchForm: UntypedFormGroup;
  showLoading: boolean;
  filtersVisibles: boolean;
  subjectsLabels = SuggestionSubjectsLabels;
  subjects = Object.values(SuggestionSubjects);
  suggestionStatusLabels = SuggestionStatusLabels;
  statuses = Object.values(SuggestionStatus);
  isDone = this.statuses[2];
  isDoneSearch = false;

  getScreenWidth: any;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private suggestionService: SuggestionService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getSuggestions();
    this.setScreenSize();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  search() {
    if (this.checkIfAllInputsAreEmpty()) {
      return;
    }
    this.getSuggestions();
  }

  cleanFilters(): void {
    this.initForm();
    this.getSuggestions();
  }

  getSuggestions() {
    this.isDoneSearch = this.searchForm.get('status').value === this.isDone;
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.suggestionService
      .getSuggestionsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.suggestions = data;
      });
  }

  goToSuggestionDetail(id: number) {
    this.router.navigate([`suggestion-detail/${id}`]);
  }

  private checkIfAllInputsAreEmpty(): boolean {
    return (
      this.searchForm.get('message').value === '' &&
      this.searchForm.get('subject').value === '' &&
      this.searchForm.get('status').value === ''
    );
  }

  private initForm() {
    this.searchForm = this.fb.group({
      message: [''],
      subject: [''],
      status: [SuggestionStatus.NEW]
    });
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }
}
