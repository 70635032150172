import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ISpecie } from 'src/app/interfaces/specie';
import { SETTINGS } from 'src/app/SETTINGS';
import { TenantSpecieService } from 'src/app/services/tenantSpecie.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { ITenantSpecies } from 'src/app/interfaces/tenantSpecies';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tenant-species',
  templateUrl: './tenant-species.component.html',
  styleUrls: ['./tenant-species.component.scss']
})
export class TenantSpeciesComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  hasSelectedSpecies: boolean;
  tenantSpecies: Array<ISpecie> = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private userProfileService: UserProfileService,
    private masterDataService: MasterDataService,
    private tenantSpecieService: TenantSpecieService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getTenantSpecies();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  specieImage(image: string): string {
    return SETTINGS.SPECIE_IMAGE_PATH + image + '.svg';
  }

  activeSpecie(specieId: number): void {
    const specieSelected = this.tenantSpecies.find((s) => s.id === specieId);
    specieSelected.activeForTenant = !specieSelected.activeForTenant;
  }

  saveTenantSpecie(): void {
    const speciesSelected = this.tenantSpecies.filter((s) => s.activeForTenant);
    if (speciesSelected.length === 0) {
      this.showWarningToast('toast.TENANT_SPECIE_NOT_SELECTED');
    } else {
      const specieIds = speciesSelected.map((s) => s.id);
      this.saveTenantSpecies(specieIds);
    }
  }

  private getTenantSpecies(): void {
    this.showLoading = true;
    this.tenantSpecieService
      .getTenantSpecieByTenantId(this.userProfileTenantId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data: number[]) => {
        if (data) {
          this.hasSelectedSpecies = true;
        }
        this.fetchTenantSpecies(data);
      });
  }

  private fetchTenantSpecies(tenantSpeciesIds: number[]): void {
    const masterSpecies = this.masterDataService.species;
    for (const specie of masterSpecies) {
      if (tenantSpeciesIds.includes(specie.id)) {
        specie.activeForTenant = true;
      }
      this.tenantSpecies.push(specie);
    }
  }

  private saveTenantSpecies(speciesId: number[]): void {
    this.showLoading = true;
    const tenantSpecies: ITenantSpecies = {
      tenantId: this.userProfileTenantId,
      speciesId
    };

    this.tenantSpecieService
      .editTenantSpecies(tenantSpecies)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.showSuccessToast('toast.TENANT_SPECIE_SUCCESS');
        this.updateMasterData(speciesId);
      });
  }

  private updateMasterData(speciesId: number[]): void {
    this.masterDataService.updateTenantSpecies(speciesId);
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }

  private showWarningToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('w'));
  }
}
