<div mat-dialog-title class="confirm-dialog-title">
  <i class="fas fa-exclamation"></i> {{ 'modals.confirm_title' | translate }}
</div>
<div mat-dialog-content class="confirm-dialog-message">
  <p>{{ message | translate }}</p>
</div>
<div mat-dialog-actions class="confirm-dialog-actions">
  <div>
    <bindy-button
      text="{{ 'common.button_cancel' | translate }}"
      color="secondary"
      (onClick)="closeDialog()"
    ></bindy-button>
    <bindy-button
      text="{{ 'common.button_continue' | translate }}"
      (onClick)="confirm()"
    >
    </bindy-button>
  </div>
</div>
