import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILogin } from 'src/app/interfaces/login';
import { SETTINGS } from 'src/app/SETTINGS';
import { IUserProfile } from 'src/app/interfaces/user-profile';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private apiUrl = SETTINGS.APIRUL;

  constructor(private httpClient: HttpClient) {}

  postUserOnLogin(loginData: ILogin): Observable<IUserProfile> {
    return this.httpClient
      .post<IUserProfile>(this.apiUrl + 'login.php', loginData)
      .pipe(
        map((data: IUserProfile) => {
          return data;
        })
      );
  }

  recoverPassword(email: string): Observable<boolean> {
    const data = {
      email: email
    } as ILogin;
    return this.httpClient
      .post<boolean>(this.apiUrl + 'recoverPassword.php', data)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }
}
