import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITenant } from 'src/app/interfaces/tenant';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { TenantService } from 'src/app/services/tenant.service';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'app-tenant-detail',
  templateUrl: './tenant-detail.component.html',
  styleUrls: ['./tenant-detail.component.scss']
})
export class TenantDetailComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  tenantForm: UntypedFormGroup;
  tenant: ITenant;

  private _tenantId: number;
  private _tenantUUID: string;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();
  private _tenantImage;
  private _tenantImageUpload: IImageUpload = null;

  constructor(
    private tenantService: TenantService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._tenantId = Number(this.activatedRoute.snapshot.params.id);
    this.initForm();
    if (this._tenantId !== 0) {
      this._tenantUUID = this.activatedRoute.snapshot.params.uuid;
      this.getTenantData();
    } else {
      const newUUID = uuidv4();
      this.tenantForm.get('uuid').setValue(newUUID);
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.tenantForm, inputName);
  }

  private getTenantData() {
    this.showLoading = true;
    this.tenantService
      .getTenantById(this._tenantUUID)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this._tenantImage = data[0].image;
        this.tenantForm.patchValue(data[0]);
      });
  }

  sendTenant() {
    if (!this.tenantForm.valid) {
      markFormGroupTouched(this.tenantForm);
      return;
    }
    this.showLoading = true;

    this.tenantService
      .postTenant(this.tenantForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((tenantId: number) => {
        this.checkIfUploadImage(tenantId);
      });
  }

  checkIfUploadImage(tenantId: number): void {
    if (!this._tenantImageUpload) {
      this.finalizeUpload();
      return;
    }
    this._tenantImageUpload.id = tenantId;
    this.tenantService
      .uploadImage(this._tenantImageUpload)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.finalizeUpload();
        })
      )
      .subscribe();
  }

  finalizeUpload(): void {
    this.showLoading = false;
    this.showSuccessToast('toast.TENANT_PROFILE_EDIT_SUCCESS');
    this.router.navigate([`tenant-list`]);
  }

  setTenantImage(imageUpload: IImageUpload) {
    this._tenantImageUpload = imageUpload;
  }

  getTenantImage(): string {
    if (!this._tenantImage) {
      return '';
    }
    return SETTINGS.TENANT_IMAGE_PATH + this._tenantImage;
  }

  deleteTenantImage(): void {
    this.tenantService
      .deleteImage(this._tenantId)
      .pipe(
        finalize(() => {
          this._tenantImage = null;
          this.showSuccessToast('toast.IMAGE_DELETE_SUCCESS');
        })
      )
      .subscribe();
  }

  private initForm() {
    this.tenantForm = this.fb.group({
      id: [null],
      uuid: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.email]],
      phone: [''],
      hasWebPage: [''],
      hasAdoptionPage: [''],
      hasAdoptedPage: [''],
      hasSponsorPage: [''],
      webUrl: [''],
      facebook: [''],
      instagram: [''],
      twitter: [''],
      youtube: [''],
      tiktok: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
