import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { ITenant } from 'src/app/interfaces/tenant';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { TenantService } from 'src/app/services/tenant.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { markFormGroupTouched } from 'src/app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Profiles } from 'src/app/interfaces/enums/profiles';

@Component({
  selector: 'app-tenant-profile',
  templateUrl: './tenant-profile.component.html',
  styleUrls: ['./tenant-profile.component.scss']
})
export class TenantProfileComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  tenantForm: UntypedFormGroup;
  tenant: ITenant;
  checkIfICanSaveData = false;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private userProfileService: UserProfileService,
    private tenantService: TenantService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getTenantData();
    if (
      Number(this.userProfileService.userProfile.profileId) ===
        Profiles.ADMIN ||
      Number(this.userProfileService.userProfile.profileId) === Profiles.GOD
    ) {
      this.checkIfICanSaveData = true;
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get tenantHasWebURL(): boolean {
    return this.userProfileService.userProfile.tenantHasWebPage;
  }

  get tenantWebURL(): string {
    return this.userProfileService.userProfile.tenantWebUrl;
  }

  private getTenantData() {
    this.showLoading = true;
    this.tenantService
      .getTenantById(this.userProfileTenantId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.tenantForm.patchValue(data[0]);
      });
  }

  sendTenant() {
    if (!this.tenantForm.valid) {
      markFormGroupTouched(this.tenantForm);
      return;
    }
    this.showLoading = true;

    this.tenantService
      .editTenant(this.tenantForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.showSuccessToast('toast.TENANT_PROFILE_EDIT_SUCCESS');
          this.userProfileService.updateUserProfileTenantData(
            this.tenantForm?.value
          );
          this.getTenantData();
        })
      )
      .subscribe();
  }

  private initForm() {
    this.tenantForm = this.fb.group({
      uuid: [this.userProfileTenantId],
      facebook: [''],
      instagram: [''],
      twitter: [''],
      youtube: [''],
      tiktok: [''],
      email: ['', [Validators.email]],
      phone: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
