import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'bindy-input-text',
  templateUrl: './bindy-input-text.component.html',
  host: { '(blur)': 'onTouched($event)' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BindyInputTextComponent),
      multi: true
    }
  ]
})
export class BindyInputTextComponent implements ControlValueAccessor {
  @Input() required = false;
  @Input() disabled = false;
  @Input() type = 'text';
  @Input() placeholder: string;
  @Input() label: string;
  @Input() name: string;

  inputName = new UntypedFormControl();

  get value(): string {
    return this.inputName.value;
  }

  set value(value: string) {
    this.inputName.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor() {
    this.inputName.valueChanges.subscribe((value) => {
      this.onChange(value);
      this.onTouched();
    });
  }

  onChange: any = () => {
    //console.log('¿DO SOMETHING?');
  };
  onTouched: any = () => {
    //console.log('¿DO SOMETHING?');
  };

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
