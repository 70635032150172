<div
  class="animal-card"
  [ngClass]="{ 'animal-card--grid': gridLayout }"
  (click)="goToAnimalDetail(animalInput.id)"
>
  <div
    class="animal-card__photo {{
      animalInput.principalImageFileName ? 'has-img' : 'has-no-img'
    }}"
    [ngStyle]="
      animalInput.principalImageFileName
        ? {
            'background-image':
              'url(' + animalImage(animalInput.principalImageFileName) + ')'
          }
        : { 'background-image': 'url(assets/img/animal_default.jpg)' }
    "
  ></div>
  <div class="animal-card__info">
    <div class="animal-card__info__name u-overflow">{{ animalInput.name }}</div>
  </div>
  <div class="animal-card__data u-text-overflow">
    <i class="fas fa-map-marker-alt color-red"></i>
    {{
      animalInput.localizationName
        ? animalInput.localizationName
        : ('animals.noLocalization' | translate)
    }}
  </div>
  <div class="hidden-xs">
    <div class="animal-card__data">
      <div>
        <i class="fas fa-venus-mars color-blue"></i>
        {{ genderLabels[animalInput.genderId] | translate }}
      </div>
    </div>
  </div>
</div>
