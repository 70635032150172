import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IAnimal } from 'src/app/interfaces/animal';
import { LocalizationTypeLabels } from 'src/app/interfaces/enums/localizationType';
import { IAnimalsFilters } from 'src/app/interfaces/filters/animalsFilters';
import { ILocalization } from 'src/app/interfaces/localization';
import { AnimalService } from 'src/app/services/animal.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'app-localization-list',
  templateUrl: './localization-list.component.html',
  styleUrls: ['./localization-list.component.scss']
})
export class LocalizationListComponent implements OnInit, OnDestroy {
  localizations: ILocalization[] = [];
  searchForm: UntypedFormGroup;
  showLoading = false;
  filtersVisibles: boolean;
  getScreenWidth: any;
  localizationTypeLabels = LocalizationTypeLabels;
  animalsByLocalization: IAnimal[] = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private userProfileService: UserProfileService,
    private animalService: AnimalService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getLocalizations();
    this.setScreenSize();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  search() {
    if (this.checkIfAllInputsAreEmpty()) {
      return;
    }
    this.getLocalizations();
  }

  cleanFilters(): void {
    this.initForm();
    this.getLocalizations();
  }

  goToLocalizationDetail(id: number): void {
    this.router.navigate([`localization-detail/${id}`]);
  }

  goToAnimalsWithLocation(localization: ILocalization) {
    this.router.navigate([`animal-list/${localization.id}`]);
  }

  private getLocalizations(): void {
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.localizationService
      .getLocalizationsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data) => {
        this.localizations = data;
        this.getAnimalsByLocalizations();
      });
  }

  private getAnimalsByLocalizations(): void {
    if (this.localizations.length === 0) {
      this.showLoading = false;
      return;
    }
    const localizationsIdsArray = this.localizations.map((l) => String(l.id));
    let animals: IAnimal[] = [];
    const filters = {
      tenantId: this.userProfileService.userTenantId,
      localizationIds: localizationsIdsArray.join(',')
    } as IAnimalsFilters;

    this.animalService
      .getAnimalsByFilters(filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        animals = data;
        this.assignAnimalsToLocalizations(animals);
      });
  }

  private assignAnimalsToLocalizations(animals: IAnimal[]): void {
    for (const localization of this.localizations) {
      const animalsByLocalization = animals.filter(
        (a) => a.localizationId === localization.id
      );
      localization.animals = animalsByLocalization;
      this.animalsByLocalization = animalsByLocalization;
    }
  }

  private checkIfAllInputsAreEmpty(): boolean {
    return (
      this.searchForm.get('name').value === '' &&
      this.searchForm.get('city').value === ''
    );
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      tenantId: [this.userProfileService.userTenantId],
      name: [''],
      city: ['']
    });
  }

  private setScreenSize(): void {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }
}
