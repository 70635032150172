import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { IImageUpload } from 'src/app/interfaces/imageUpload';

@Component({
  selector: 'bindy-file-upload',
  templateUrl: 'bindy-file-upload.component.html',
  styleUrls: ['bindy-file-upload.component.scss']
})
export class BindyFileUploadComponent {
  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  @Input() id: number;
  @Input() imageURL: string;
  @Input() freeAspect = false;

  @Output() onUpload = new EventEmitter<IImageUpload>();
  @Output() onDelete = new EventEmitter();

  fileName = '';
  userId = 2;
  showCropperTool = false;
  url: string;
  file: File;

  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  maintainAspectRatio = true;
  aspectRatio = 1;
  resizeToWidth = 400;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.freeAspect) {
      this.maintainAspectRatio = false;
      this.resizeToWidth = 550;
    }
  }

  deleteImageFromServer(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_IMAGE_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.onDelete.emit();
        }
      });
  }

  deletePreviewedImage(): void {
    this.showCropperTool = false;
    this.fileUpload.nativeElement.value = '';
    this.onUpload.emit(null);
  }

  onFileChange(event: any): void {
    this.showCropperTool = true;
    this.imgChangeEvt = event;
    this.file = event.target.files[0];
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    const fileUpload = {
      base64: this.cropImgPreview,
      fileName: this.file.name,
      id: this.id
    } as IImageUpload;
    this.onUpload.emit(fileUpload);
  }

  imgLoad() {
    this.showCropperTool = true;
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    this.showErrorToast('toast.IMAGE_UPLOAD_FAILED');
  }

  private showErrorToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('e'));
  }
}
