<div *ngIf="openModal" class="c-modal-add-user-admin">
  <div class="c-modal-add-user-admin__content">
    <div class="c-modal-add-user-admin__content-header">
      <p class="c-modal-add-user-admin__content-title">
        {{ 'modals.newAdmin_title' | translate }}
      </p>
      <div (click)="close()"><i class="fas fa-times"></i></div>
    </div>
    <form
      class="c-modal-add-user-admin__content-form"
      [formGroup]="userForm"
      (ngSubmit)="submit()"
    >
      <div class="u-input">
        <bindy-input-text
          formControlName="name"
          label="{{ 'forms.name' | translate }}"
          placeholder="{{ 'forms.name_placeholder' | translate }}"
          required="true"
        ></bindy-input-text>
        <ng-container *ngIf="inputValidation('name')">
          <p class="u-error" *ngIf="userForm.get('name').errors?.required">
            {{ 'common.mandatoryField' | translate }}
          </p>
        </ng-container>
      </div>

      <div class="u-input">
        <bindy-input-text
          formControlName="surName"
          label="{{ 'forms.surname' | translate }}"
          placeholder="{{ 'forms.surname_placeholder' | translate }}"
          required="true"
        ></bindy-input-text>
        <ng-container *ngIf="inputValidation('surName')">
          <p class="u-error" *ngIf="userForm.get('surName').errors?.required">
            {{ 'common.mandatoryField' | translate }}
          </p>
        </ng-container>
      </div>

      <div class="u-input">
        <bindy-input-text
          formControlName="email"
          label="{{ 'forms.email' | translate }}"
          placeholder="{{ 'forms.email_placeholder' | translate }}"
          required="true"
          type="email"
        ></bindy-input-text>
        <ng-container *ngIf="inputValidation('email')">
          <p class="u-error" *ngIf="userForm.get('email').errors?.required">
            {{ 'common.mandatoryField' | translate }}
          </p>
          <p class="u-error" *ngIf="userForm.get('email').errors?.email">
            {{ 'forms.email_isWrong' | translate }}
          </p>
        </ng-container>
      </div>
    </form>
    <div>
      <bindy-button
        text="{{ 'common.button_continue' | translate }}"
        color="primary"
        (click)="saveUserForm()"
      ></bindy-button>
    </div>
  </div>
</div>
