import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IAnimal } from 'src/app/interfaces/animal';
import { GenderLabels } from 'src/app/interfaces/enums/genders';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'animal-card',
  templateUrl: './animal-card.component.html',
  styleUrls: ['./animal-card.component.scss']
})
export class AnimalCardComponent {
  @Input() animalInput: IAnimal;
  @Input() set gridLayoutInput(gridLayout: boolean) {
    this.gridLayout = gridLayout;
  }

  public gridLayout = false;
  public genderLabels = GenderLabels;

  constructor(private router: Router) {}

  goToAnimalDetail(animalId: string): void {
    this.router.navigate([`animal-detail/${animalId}`]);
  }

  animalImage(image: string): string {
    return SETTINGS.ANIMALS_IMAGE_PATH + image;
  }
}
