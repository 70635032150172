import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SETTINGS } from 'src/app/SETTINGS';
import { ITenant } from 'src/app/interfaces/tenant';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit, OnDestroy {
  tenants: ITenant[] = [];
  searchForm: UntypedFormGroup;
  showLoading: boolean;
  filtersVisibles: boolean;
  getScreenWidth: any;
  showAdminModal = false;
  tenantSelectedId = '';

  private isModalVisible = false;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private tenantService: TenantService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getTenants();
    this.setScreenSize();
  }

  ngOnDestroy(): void {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  search(): void {
    if (this.checkIfAllInputsAreEmpty()) {
      return;
    }
    this.getTenants();
  }

  cleanFilters(): void {
    this.initForm();
    this.getTenants();
  }

  getTenants(): void {
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.tenantService
      .getTenantsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.tenants = data;
      });
  }

  goToTenantDetail(id: number, uuid = '0') {
    this.router.navigate([`tenant-detail/${id}/${uuid}`]);
  }

  tenantImage(image: string): string {
    return SETTINGS.TENANT_IMAGE_PATH + image;
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  showNewAdminModal(tenantId: string): void {
    this.tenantSelectedId = tenantId;
    this.showAdminModal = true;
    this.presentModal();
    // event.preventDefault();
  }

  closeModalAdminModal(): void {
    this.tenantSelectedId = '';
    this.showAdminModal = false;
    this.closeModal();
  }

  private presentModal() {
    const htmlTag = document.getElementById('html');
    this.isModalVisible = !this.isModalVisible;
    if (this.isModalVisible) {
      document.body.classList.add('overflow-hidden');
      htmlTag.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      this.removeOverflowHidden();
    }
  }

  private closeModal() {
    document.body.classList.remove('overflow-hidden');
    this.isModalVisible = false;
    this.removeOverflowHidden();
  }

  private removeOverflowHidden() {
    const htmlTag = document.getElementById('html');
    htmlTag.classList.remove('overflow-hidden');
  }

  private checkIfAllInputsAreEmpty(): boolean {
    return this.searchForm.get('name').value === '';
  }

  private initForm() {
    this.searchForm = this.fb.group({
      name: ['']
    });
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }
}
