<div class="bindy-file-upload">
  <div class="bindy-file-upload__content">
    <input
      type="file"
      class="bindy-file-upload-file-input"
      #fileUpload
      (change)="onFileChange($event)"
    />

    <div *ngIf="!showCropperTool">
      <bindy-button
        *ngIf="!imageURL"
        text="{{ 'common.upload_photo' | translate }}"
        icon="fa-upload"
        color="third"
        (onClick)="fileUpload.click()"
      ></bindy-button>
      <img *ngIf="imageURL" class="bindy-file-upload__img" [src]="imageURL" />
    </div>
    <image-cropper
      *ngIf="showCropperTool"
      [imageChangedEvent]="imgChangeEvt"
      [maintainAspectRatio]="maintainAspectRatio"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth"
      format="jpg"
      (imageCropped)="cropImg($event)"
      (imageLoaded)="imgLoad()"
      (cropperReady)="initCropper()"
      (loadImageFailed)="imgFailed()"
    >
    </image-cropper>
    <div
      *ngIf="showCropperTool"
      (click)="deletePreviewedImage()"
      class="bindy-file-upload-delete"
    >
      {{ 'common.delete_photo' | translate }}
    </div>
    <div
      *ngIf="imageURL"
      (click)="deleteImageFromServer()"
      class="bindy-file-upload-delete"
    >
      {{ 'common.delete_photo' | translate }}
    </div>
  </div>
</div>

<!-- <div>
  <h6>Image Preview</h6>
  <img [src]="cropImgPreview" />
</div> -->
