<div *ngIf="openModal" class="c-modal-welcome">
  <div class="c-modal-welcome__content">
    <div class="c-modal-welcome__content-header">
      <p class="c-modal-welcome__content-title">
        {{ 'modals.welcome_title' | translate }}
      </p>
    </div>

    <p>{{ 'modals.welcome_text' | translate }}</p>
    <br />
    <p *ngIf="checkIfIsAdminUser() && !this.hasSpecies">
      {{ 'modals.welcome_text_species' | translate }}
    </p>

    <app-loading [size]="'small'" *ngIf="showLoading"></app-loading>

    <div
      *ngIf="checkIfIsAdminUser() && !this.hasSpecies && !showLoading"
      class="c-modal-welcome__chips"
    >
      <div
        class="c-modal-welcome__one"
        *ngFor="let specie of tenantSpecies"
        [ngClass]="
          specie.activeForTenant ? 'c-modal-welcome__one_selected' : ''
        "
        (click)="activeSpecie(specie.id)"
      >
        <div class="c-modal-welcome__one_lbl">
          {{ specie.literal | translate }}
        </div>
      </div>
    </div>

    <div class="c-modal-welcome__buttons">
      <bindy-button
        text="{{ 'common.button_go' | translate }}"
        color="secondary"
        (onClick)="saveTenantSpecie()"
      ></bindy-button>
    </div>
  </div>
</div>
