<app-titlebar
  [title]="'titles.tenant_profile' | translate"
  icon="fas fa-briefcase"
></app-titlebar>
<div class="c-tenant-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div>
      <div *ngIf="!showLoading">
        <form
          [formGroup]="tenantForm"
          (ngSubmit)="sendTenant()"
          class="c-tenant-detail__form"
        >
          <input type="hidden" formControlName="uuid" />
          <div>
            <fieldset class="u-fieldset">
              <legend class="u-subtitle u-legend">
                <i class="fas fa-rss"></i> {{ 'titles.social' | translate }}
              </legend>
              <div class="c-tenant-detail__fieldset-content">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="facebook"
                    label="Facebook"
                    placeholder=""
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="instagram"
                    label="Instagram"
                    placeholder=""
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="twitter"
                    label="Twitter"
                    placeholder=""
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="tiktok"
                    label="Tik Tok"
                    placeholder=""
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="youtube"
                    label="Youtube"
                    placeholder=""
                  ></bindy-input-text>
                </div>
              </div>
            </fieldset>
          </div>

          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-otter"></i> {{ 'titles.moreData' | translate }}
            </legend>
            <div class="c-tenant-detail__fieldset-content">
              <div class="u-input">
                <div *ngIf="tenantHasWebURL">
                  <p class="u-input__text">{{ 'forms.webPage' | translate }}</p>
                  <div class="c-tenant-detail__lbl break-all">
                    <a href="{{ tenantWebURL }}" target="_blank">{{
                      tenantWebURL
                    }}</a>
                  </div>
                </div>
              </div>
              <div class="c-tenant-detail__grid">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="email"
                    label="{{ 'common.email' | translate }}"
                    placeholder="{{
                      'myProfile.email_placeholder' | translate
                    }}"
                    type="email"
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="phone"
                    label="{{ 'common.phone' | translate }}"
                    placeholder="{{
                      'myProfile.phone_placeholder' | translate
                    }}"
                    type="number"
                  ></bindy-input-text>
                </div>
              </div>
            </div>
            <div class="u-detail__button" *ngIf="checkIfICanSaveData">
              <div></div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
