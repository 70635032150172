<app-titlebar
  [title]="'titles.localización_list' | translate"
  icon="fas fa-map-marker"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="user-list">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addlocalization' | translate }}"
          icon="fa-plus"
          color="success"
          (onClick)="goToLocalizationDetail(0)"
        ></bindy-button>

        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}:
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="name"
                  label="{{ 'forms.name' | translate }}"
                  placeholder="{{ 'forms.name_placeholder' | translate }}"
                ></bindy-input-text>
              </div>
              <div class="u-input u-input--select">
                <bindy-input-text
                  formControlName="city"
                  label="{{ 'forms.city' | translate }}"
                  placeholder="{{ 'forms.city_placeholder' | translate }}"
                ></bindy-input-text>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addlocalization' | translate }}"
          icon="fa-plus"
          color="success"
          (onClick)="goToLocalizationDetail(0)"
        ></bindy-button>
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ localizations.length }}
        </div>
      </div>
    </div>
    <div *ngIf="localizations.length > 0" class="c-localizations__grid">
      <localization-card
        *ngFor="let localization of localizations"
        [localizationInput]="localization"
        (click)="goToAnimalsWithLocation(localization)"
      >
      </localization-card>
    </div>

    <div class="u-nocontent-text" *ngIf="localizations.length === 0">
      {{ 'localizations.noLocalizationYet' | translate }}
    </div>
  </div>
</div>
