import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { ITenantSpecies } from 'src/app/interfaces/tenantSpecies';

@Injectable({
  providedIn: 'root'
})
export class TenantSpecieService {
  private apiUrl = SETTINGS.APIRUL + 'tenantSpecie/';

  constructor(private httpClient: HttpClient) {}

  getTenantSpecieByTenantId(tenantId: string): Observable<number[]> {
    const params = new HttpParams().set('tenantId', tenantId);

    return this.httpClient
      .get<number[]>(this.apiUrl + 'getTenantSpeciesByTenantId.php', { params })
      .pipe(
        map((data: number[]) => {
          return data;
        })
      );
  }

  editTenantSpecies(tenantSpecies: ITenantSpecies): Observable<boolean> {
    return this.httpClient
      .post<boolean>(this.apiUrl + 'addTenantSpecies.php', tenantSpecies)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }
}
