<app-titlebar
  [title]="'titles.my_profile' | translate"
  icon="fas fa-cog"
></app-titlebar>
<div class="c-profile-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div>
      <div *ngIf="!showLoading">
        <form
          [formGroup]="userForm"
          (ngSubmit)="sendUser()"
          class="c-profile-detail__form"
        >
          <div class="c-profile-detail__header">
            <fieldset class="u-fieldset">
              <legend class="u-subtitle u-legend">
                <i class="fas fa-user"></i>
                {{ 'titles.generalData' | translate }}
              </legend>
              <div class="c-profile-detail__fieldset-content">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="name"
                    label="{{ 'common.name' | translate }}"
                    placeholder="{{ 'myProfile.name_placeholder' | translate }}"
                    required="true"
                  ></bindy-input-text>
                  <ng-container *ngIf="inputValidation('name')">
                    <p
                      class="u-error"
                      *ngIf="userForm.get('name').errors?.required"
                    >
                      {{ 'common.mandatoryField' | translate }}
                    </p>
                  </ng-container>
                </div>
                <div class="u-input">
                  <div class="u-input">
                    <bindy-input-text
                      formControlName="surName"
                      label="{{ 'common.surname' | translate }}"
                      placeholder="{{
                        'myProfile.surname_placeholder' | translate
                      }}"
                      required="true"
                    ></bindy-input-text>
                    <ng-container *ngIf="inputValidation('surName')">
                      <p
                        class="u-error"
                        *ngIf="userForm.get('surName').errors?.required"
                      >
                        {{ 'common.mandatoryField' | translate }}
                      </p>
                    </ng-container>
                  </div>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="email"
                    label="{{ 'common.email' | translate }}"
                    placeholder="{{
                      'myProfile.email_placeholder' | translate
                    }}"
                    required="true"
                    type="email"
                  ></bindy-input-text>
                  <ng-container *ngIf="inputValidation('email')">
                    <p
                      class="u-error"
                      *ngIf="userForm.get('email').errors?.required"
                    >
                      {{ 'common.mandatoryField' | translate }}
                    </p>
                    <p
                      class="u-error"
                      *ngIf="userForm.get('email').errors?.email"
                    >
                      {{ 'myProfile.email_wrong' | translate }}
                    </p>
                  </ng-container>
                </div>
                <div class="u-input">
                  <app-language-selector></app-language-selector>
                </div>
              </div>
            </fieldset>

            <fieldset class="u-fieldset">
              <legend class="u-subtitle u-legend">
                <i class="fas fa-camera"></i> {{ 'titles.photo' | translate }}
              </legend>
              <bindy-file-upload
                [imageURL]="getUserImage()"
                [id]="userId"
                (onUpload)="setUserImage($event)"
                [uploadProgress]="uploadProgress"
                (onDelete)="deleteUserImage()"
              ></bindy-file-upload>
            </fieldset>
          </div>

          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-building"></i> {{ 'titles.address' | translate }}
            </legend>
            <div class="c-profile-detail__fieldset-content">
              <div class="u-input">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="address"
                    label="{{ 'common.adrress' | translate }}"
                    placeholder="{{
                      'myProfile.adrress_placeholder' | translate
                    }}"
                  ></bindy-input-text>
                </div>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="city"
                  label="{{ 'common.city' | translate }}"
                  placeholder="{{ 'myProfile.city_placeholder' | translate }}"
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <p class="u-input__text">
                  {{ 'common.country' | translate }}
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="countryId"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                    >
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </div>
                <ng-container *ngIf="inputValidation('countryId')">
                  <p
                    class="u-error"
                    *ngIf="userForm.get('countryId').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="phone"
                  label="{{ 'common.phone' | translate }}"
                  placeholder="{{ 'myProfile.phone_placeholder' | translate }}"
                  type="number"
                ></bindy-input-text>
              </div>
            </div>
            <div class="u-detail__button">
              <div></div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </form>

        <form
          [formGroup]="passwordForm"
          (ngSubmit)="sendChangePassword()"
          class="c-profile-detail__form"
        >
          <fieldset class="u-fieldset c-profile-detail__password">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-key"></i> {{ 'titles.password' | translate }}
            </legend>
            <p>{{ 'myProfile.passwordText' | translate }}</p>
            <div class="c-profile-detail__fieldset-content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="password"
                  type="password"
                  label="{{ 'myProfile.new_password' | translate }}"
                  placeholder=""
                ></bindy-input-text>
                <ng-container *ngIf="inputValidationPassword('password')">
                  <p
                    class="u-error"
                    *ngIf="passwordForm.get('password').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                  <p
                    class="u-error"
                    *ngIf="passwordForm.get('password').errors?.minlength"
                  >
                    {{ 'myProfile.passwordText' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="repeatPassword"
                  type="password"
                  label="{{ 'myProfile.repeat_password' | translate }}"
                  placeholder=""
                ></bindy-input-text>
                <ng-container *ngIf="inputValidationPassword('repeatPassword')">
                  <p
                    class="u-error"
                    *ngIf="passwordForm.get('repeatPassword').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                  <p
                    class="u-error"
                    *ngIf="passwordForm.get('repeatPassword').errors?.minlength"
                  >
                    {{ 'myProfile.passwordText' | translate }}
                  </p>
                  <p
                    class="u-error"
                    *ngIf="
                      passwordForm.get('password').value !==
                      passwordForm.get('repeatPassword').value
                    "
                  >
                    {{ 'myProfile.passwordDifferntError' | translate }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="c-profile-detail__button">
              <div></div>
              <div>
                <bindy-button
                  text="{{ 'common.button_password' | translate }}"
                  icon="fa-key"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
