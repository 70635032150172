import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BindyCommonModule } from './common/bindy-common.module';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BindyCommonModule
  ]
})
export class ComponentsModule {}
