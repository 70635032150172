<div class="c-localization-card">
  <div class="c-localization-card__info">
    <div class="c-localization-card__info-header">
      <div class="c-localization-card__info-header-text">
        <div>
          <strong>{{ localizationInput.name }}</strong>
        </div>
        <div
          class="u-link"
          (click)="goToLocalizationDetail(localizationInput.id)"
        >
          {{ 'common.edit' | translate }}
        </div>
      </div>
      <div>
        <div class="c-localization-card__info-header-data">
          <div>
            <img
              src="../../../assets/img/localization-icons/{{
                localizationInput.localizationTypeId
              }}.svg"
              alt=""
            />
          </div>
          <div>
            <div>
              <strong>{{ 'common.adrress' | translate }}:</strong>
              {{ localizationInput.address }}
            </div>
            <div>
              <strong>{{ 'common.city' | translate }}:</strong>
              {{ localizationInput.city }}
            </div>
            <div>
              <strong>{{ 'common.type' | translate }}:</strong>
              {{
                localizationTypeLabelsInput[
                  localizationInput.localizationTypeId
                ] | translate
              }}
            </div>
            <div>
              <strong>{{ 'localizations.totalAnimals' | translate }}:</strong>
              {{ localizationInput.animals?.length }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="localizationInput.animals?.length > 0; let i = index"
    class="c-localization-card__info-footer"
  >
    <div>
      <strong>{{ 'common.animals' | translate }}:</strong>
    </div>
    <div class="c-localization-card__labels">
      <div
        class="c-localization-card__label"
        *ngFor="let animal of localizationInput.animals"
        (click)="goToAnimal(animal.id)"
      >
        <i *ngIf="animal.gender === genders.MALE" class="fas fa-mars"></i>
        <i *ngIf="animal.gender === genders.FEMALE" class="fas fa-venus"></i>
        <i
          *ngIf="animal.gender === genders.UNKNOWN"
          class="fas fa-question"
        ></i>
        {{ animal.name }}
      </div>
    </div>
  </div>
  <div
    *ngIf="localizationInput.animals?.length === 0"
    class="c-localization-card__info-footer"
  >
    <div
      class="c-localization-card__info-text"
      [innerHTML]="'localizations.noAnimalsHere' | translate"
    ></div>
  </div>
</div>
