import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IAnimal } from 'src/app/interfaces/animal';
import { Profiles } from 'src/app/interfaces/enums/profiles';
import { ILocalizationsFilters } from 'src/app/interfaces/filters/localizationsFilters';
import { ILocalization } from 'src/app/interfaces/localization';
import { ISpecie } from 'src/app/interfaces/specie';
import { AnimalService } from 'src/app/services/animal.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'animal-list',
  templateUrl: './animal-list.component.html',
  styleUrls: ['./animal-list.component.scss']
})
export class AnimalListComponent implements OnInit, OnDestroy {
  viewMode = 'inAdoptionTab';

  animals: IAnimal[] = [];
  adoptedAnimals: IAnimal[] = [];
  passedAwayAnimals: IAnimal[] = [];
  searchForm: UntypedFormGroup;
  searchByLocationForm: UntypedFormGroup;
  animalsLoaded = false;
  isAdoptedSelected = false;
  gridLayout = true;
  errorMessage: string;
  filtersVisibles: boolean;
  showLoading = false;
  open = false;
  _listFilter: string;
  getScreenWidth: any;

  localizations: ILocalization[] = [];

  private isModalVisible = false;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private animalService: AnimalService,
    private fb: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private localizationService: LocalizationService,
    private activatedRoute: ActivatedRoute,
    private userProfileService: UserProfileService
  ) {}

  get listFilter(): string {
    return this._listFilter;
  }

  set listFilter(value: string) {
    this._listFilter = value;
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get species(): Array<ISpecie> {
    const tenantSpecies = this.masterDataService.tenantSpecies;
    return this.masterDataService.species.filter(function (item) {
      return tenantSpecies.indexOf(item.id) !== -1;
    });
  }

  ngOnInit(): void {
    const localizationId = Number(this.activatedRoute.snapshot.params.id);
    this.getData();
    this.initForm(localizationId);
    this.setScreenSize();
    this.getAnimals();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  setGridLayout() {
    this.gridLayout = !this.gridLayout;
  }

  openModalAddAnimal() {
    this.open = true;
    this.presentModal();
  }

  closeModalAddAnimal(): void {
    this.open = false;
    this.closeModal();
  }

  getData() {
    this.showLoading = true;
    const filters = {} as ILocalizationsFilters;
    const userProfile = this.userProfileService.userProfile;
    if (userProfile.profileId !== Profiles.GOD) {
      //PROFILES
      filters.tenantId = userProfile.tenantId;
    }
    this.localizationService
      .getLocalizationsByFilters(filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.localizations = data;
      });
  }

  search(): void {
    if (this.checkIfAllInputsAreEmpty()) {
      return;
    }
    this.getAnimals();
  }

  searchByLocation(): void {
    this.getAnimalsByLocation();
  }

  cleanFilters(): void {
    this.initForm();
    this.getAnimals();
  }

  setAdoptedAnimals(animals: IAnimal[]): IAnimal[] {
    return animals.filter((animal) => animal.adoptionDate !== null);
  }

  private getAnimalsByLocation(): void {
    this.showLoading = true;
    this.animalService
      .getAnimalsByFilters(this.searchByLocationForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.animals = data;
      });
  }

  private getAnimals(): void {
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.animalService
      .getAnimalsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.adoptedAnimals = this.setAdoptedAnimals(data);
        this.passedAwayAnimals = this.setPassedAwayAnimals(data);
        this.animals = this.setInAdoptionAnimals(data);
      });
  }

  private setPassedAwayAnimals(animals: IAnimal[]): IAnimal[] {
    return animals.filter((animal) => animal.passAwayDate !== null);
  }

  private setInAdoptionAnimals(animals: IAnimal[]): IAnimal[] {
    return animals.filter(
      (animal) => animal.adoptionDate === null && animal.passAwayDate === null
    );
  }

  private checkIfAllInputsAreEmpty(): boolean {
    return (
      this.searchForm.get('name').value === '' &&
      this.searchForm.get('chip').value === '' &&
      this.searchForm.get('specieId').value === '' &&
      this.searchForm.get('localizationId').value === ''
    );
  }

  private initForm(localizationId?: number) {
    this.searchForm = this.fb.group({
      tenantId: [this.userProfileService.userTenantId],
      name: [''],
      chip: [''],
      specieId: [''],
      localizationId: [localizationId ? localizationId : '']
    });

    this.searchByLocationForm = this.fb.group({
      tenantId: [this.userProfileService.userTenantId],
      localizationId: [localizationId ? localizationId : '']
    });
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }

  private presentModal() {
    const htmlTag = document.getElementById('html');
    this.isModalVisible = !this.isModalVisible;
    if (this.isModalVisible) {
      document.body.classList.add('overflow-hidden');
      htmlTag.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      this.removeOverflowHidden();
    }
  }

  private closeModal() {
    document.body.classList.remove('overflow-hidden');
    this.isModalVisible = false;
    this.removeOverflowHidden();
  }

  private removeOverflowHidden() {
    const htmlTag = document.getElementById('html');
    htmlTag.classList.remove('overflow-hidden');
  }
}
