<app-titlebar
  [title]="'titles.species' | translate"
  icon="fas fa-dog"
></app-titlebar>
<div class="c-tenant-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>
    <div>
      <div *ngIf="!showLoading">
        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-info"></i> {{ 'titles.information' | translate }}
          </legend>
          <div class="u-fieldset-info">
            <p>{{ 'tenant.species_info' | translate }}</p>
          </div>
        </fieldset>

        <div class="c-species__cont">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-dog"></i> {{ 'titles.species' | translate }}
            </legend>
            <div class="c-species__chips">
              <div
                class="c-species__one"
                *ngFor="let specie of tenantSpecies"
                [ngClass]="
                  specie.activeForTenant ? 'c-species__one_selected' : ''
                "
                (click)="activeSpecie(specie.id)"
              >
                <div
                  class="c-species__one_icon"
                  [ngStyle]="{
                    'background-image': 'url(' + specieImage(specie.icon) + ')'
                  }"
                ></div>
                <div class="c-species__one_lbl">
                  {{ specie.literal | translate }}
                </div>
              </div>
            </div>
            <div class="u-detail__button">
              <div></div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  (click)="saveTenantSpecie()"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
