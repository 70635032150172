import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SETTINGS } from 'src/app/SETTINGS';
import { INews } from 'src/app/interfaces/news';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy {
  newsList: INews[] = [];
  searchForm: UntypedFormGroup;
  showLoading: boolean;
  filtersVisibles: boolean;

  getScreenWidth: any;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getNews();
    this.setScreenSize();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  showFilters() {
    this.filtersVisibles = !this.filtersVisibles;
  }

  search() {
    if (this.checkIfAllInputsAreEmpty()) {
      return;
    }
    this.getNews();
  }

  cleanFilters(): void {
    this.initForm();
    this.getNews();
  }

  getNews() {
    this.showLoading = true;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = false;
    }
    this.newsService
      .getNewsByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.newsList = data;
      });
  }

  goToNewsDetail(id: number) {
    this.router.navigate([`news-detail/${id}`]);
  }

  newsImage(image: string): string {
    return SETTINGS.NEWS_IMAGE_PATH + image;
  }

  private checkIfAllInputsAreEmpty(): boolean {
    return this.searchForm.get('title').value === '';
  }

  private initForm() {
    this.searchForm = this.fb.group({
      title: ['']
    });
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth > SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.filtersVisibles = true;
    }
  }
}
