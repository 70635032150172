<app-titlebar
  [title]="'titles.users_list' | translate"
  icon="fas fa-users"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="user-list">
  <div class="u-content">
    <fieldset class="u-fieldset">
      <legend class="u-subtitle u-legend">
        <i class="fas fa-info"></i> {{ 'titles.information' | translate }}
      </legend>
      <p
        class="u-fieldset-info"
        [innerHTML]="'users.listInformation' | translate"
      ></p>
    </fieldset>

    <div class="u-actions__container">
      <bindy-button
        text="{{ 'common.button_addUser' | translate }}"
        icon="fa-plus"
        color="success"
        (onClick)="goToUserDetail(0)"
      ></bindy-button>
      <div class="u-list__total">
        {{ 'common.total_results' | translate }}: {{ users.length }}
      </div>
    </div>

    <div *ngIf="users.length > 0" class="u-table">
      <div class="u-table__header user-list-table-header">
        <div></div>
        <div>{{ 'common.name' | translate }}</div>
        <div>{{ 'common.surname' | translate }}</div>
        <div>{{ 'common.email' | translate }}</div>
        <div>{{ 'common.city' | translate }}</div>
        <div>{{ 'common.profile' | translate }}</div>
        <div>{{ 'common.phone' | translate }}</div>
      </div>
      <div *ngIf="users.length > 0">
        <div
          class="u-table__data user-list-table-data"
          *ngFor="let user of users"
          (click)="goToUserDetail(user.id)"
        >
          <div
            class="u-table__circle_image"
            (click)="$event.stopPropagation(); suplantIdentity(user.id)"
          >
            <img
              [src]="
                !!user.image
                  ? userImage(user.image)
                  : '/assets/img/user_default.png'
              "
            />
          </div>
          <div class="hidden-xs">{{ user.name }}</div>
          <div class="hidden-xs">{{ user.surName }}</div>
          <div class="hidden-xs">{{ user.email }}</div>
          <div class="hidden-xs">{{ user.city }}</div>
          <div class="hidden-xs">
            {{ profileLabels[user.profileId] | translate }}
          </div>
          <div class="hidden-xs">{{ user.phone }}</div>
          <div class="visible-xs">
            <div>{{ user.name }} {{ user.surName }}</div>
            <div>{{ user.email }}</div>
            <div>
              <strong
                >Perfil: {{ profileLabels[user.profileId] | translate }}</strong
              >
            </div>
            <div>{{ user.phone }}</div>
          </div>
        </div>
      </div>
      <div class="u-table__no_results" *ngIf="users.length === 0">
        {{ 'common.noResult' | translate }}
      </div>
    </div>
  </div>
</div>
