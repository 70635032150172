<div class="landing">
  <div class="landing__container">
    <div class="landing__header-full">
      <div class="landing__header">
        <div class="landing__header-logo">
          <img src="assets/img/logoWithTitle.png" />
        </div>
        <div class="landing__header-menu">
          <ul>
            <li><a (click)="goToScroll('what')">¿Qué es Orca App?</a></li>
            <li><a (click)="goToScroll('how')">¿Cómo funciona?</a></li>
            <li><a (click)="goToScroll('contact')">Contacto</a></li>
            <li><a (click)="goToScroll('opinions')">Testimonios</a></li>
            <li><a (click)="goToScroll('agata')">Ágata</a></li>
          </ul>
        </div>
        <div>
          <div class="landing__header-button landing__button">
            <a routerLink="/login">Acceso Usuarios</a>
          </div>
        </div>
      </div>
    </div>
    <div class="landing__full">
      <div class="landing__full-image">
        <div class="landing__content">
          <div class="landing__full-title">
            <h1>La App de gestión para asociaciones de animales</h1>
          </div>
        </div>
      </div>
    </div>

    <div id="what" class="landing__block">
      <div class="landing__content">
        <h3>¿Qué es Orca App?</h3>
        <h2>Gestión gratuíta para asociaciones animalistas</h2>

        <div class="landing__info-two-columns">
          <div><img src="assets/img/orcaSun.jpeg" /></div>
          <div>
            <div class="landing__info-text">
              Orca App es nuestro bebé más preciado. Un panel de administración
              para asociaciones super fácil de usar, amigable y rápido, que
              ofrece la gestión completa de los animales de las protectoras,
              refugios, etc... entre otras funcionalidades. Sus principales
              características son:
            </div>
            <div class="landing__info-list">
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">
                  Gestión de los animales al completo
                </div>
              </div>
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">
                  Usuarios con diferentes perfiles dentro de la misma asociación
                </div>
              </div>
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">
                  Recibimos y analizamos las sugerencias de nuestros usuarios
                </div>
              </div>
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">En español e ingles</div>
              </div>
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">
                  Constante actualización de nuevas funcionalidades
                </div>
              </div>
              <div class="landing__info-list-element">
                <div class="landing__info-list-icon">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                </div>
                <div class="landing__info-list-text">
                  Directo a tu web pública de manera muy sencilla y rápida
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="how" class="landing__block">
      <div class="landing__content">
        <h3>¿Cómo funciona?</h3>
        <h2>Algunos pantallazos</h2>

        <div class="landing__how-cont">
          <div class="landing__how-one">
            <div class="landing__how-image">
              <img src="assets/img/screenshot_1.jpg" />
            </div>
            <div class="landing__how-title">Accede a la App</div>
            <div class="landing__how-text">
              En la pantalla de inicio, tenemos un resumen de los números de la
              asociación, descargas, noticias, tutoriales...
            </div>
          </div>
          <div class="landing__how-one">
            <div class="landing__how-image">
              <img src="assets/img/screenshot_2.jpg" />
            </div>
            <div class="landing__how-title">Selecciona las especies</div>
            <div class="landing__how-text">
              Se pueden seleccionar en cualquier momento y ampliar las especies
              que gestionar dentro de la asociación.
            </div>
          </div>
          <div class="landing__how-one">
            <div class="landing__how-image">
              <img src="assets/img/screenshot_3.jpg" />
            </div>
            <div class="landing__how-title">Gestión de animales</div>
            <div class="landing__how-text">
              En el listado de animales podremos ver a todos los peludos,
              filtrar por nombre, número de chip y más. Podremos dar de alta
              nuevos animales y clasificarlos.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="landing__counters-cont">
      <h3>Nuestros números</h3>
      <div class="landing__counters">
        <div class="landing__counter">
          <div class="landing__counter-icon">
            <i class="fas fa-building"></i>
          </div>
          <div class="landing__counter-text">
            16<br /><span>Asociaciones</span>
          </div>
        </div>
        <div class="landing__counter">
          <div class="landing__counter-icon"><i class="fas fa-dog"></i></div>
          <div class="landing__counter-text">
            +300<br /><span>Animales</span>
          </div>
        </div>
        <div class="landing__counter">
          <div class="landing__counter-icon"><i class="fas fa-users"></i></div>
          <div class="landing__counter-text">
            +25<br /><span>Usuarios</span>
          </div>
        </div>
        <div class="landing__counter">
          <div class="landing__counter-icon"><i class="fas fa-paw"></i></div>
          <div class="landing__counter-text">
            +112<br /><span>Adoptados</span>
          </div>
        </div>
      </div>
    </div>

    <div id="contact" class="landing__contact-cont">
      <h3>Contacto</h3>
      <h2>Contacta con Nosotr@s</h2>
      <div class="landing__contact-info">
        Te invitamos a que pruebes Orca sin ningún compromiso y contarnos como
        fue tu experiencia.
        <br />
        Escríbenos a través de los siguientes medios y nos pondremos en contacto
        contigo.
      </div>
      <div class="landing__contact">
        <div>
          <div class="landing__contact-title">A través de nuestras redes</div>
          <div class="landing__contact-rrss">
            <a
              href="https://www.instagram.com/asociacion_agata/"
              target="_blank"
              ><img src="assets/img/instagram-brands-dark.svg" alt=""
            /></a>
            <a
              href="https://www.facebook.com/asocAyudaAnimalAgata"
              target="_blank"
              ><img src="assets/img/facebook-brands-dark.svg" alt=""
            /></a>
          </div>
        </div>
        <div>
          <div class="landing__contact-title">O escríbenos un E-Mail</div>
          <div class="landing__contact-mail">
            <a href="mailto:agataprotectora@gmail.com"
              >agataprotectora@gmail.com</a
            >
          </div>
        </div>
      </div>
    </div>

    <div id="opinions" class="landing__block">
      <div class="landing__content">
        <h3>Testimonios</h3>
        <h2>Los usuarios nos comentan</h2>
        <div class="landing__testimonies-cont">
          <div class="landing__testimonies-one" *ngFor="let tes of testimonies">
            <div class="landing__testimonies-image">
              <img [src]="tenantImage(tes.tenantImage)" />
            </div>
            <div class="landing__testimonies-label">{{ tes.name }}</div>
            <div class="landing__testimonies-text">{{ tes.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div id="agata" class="landing__mision-block">
      <div class="landing__content">
        <h3>Asociación de Ayuda Animal Ágata</h3>
        <h2>Nosotr@s</h2>

        <div class="landing__mision">
          <div class="landing__mision-box">
            <img src="assets/img/logoBox.jpg" />
            <p class="landing__mision-text">
              La aplicación Orca es el principal proyecto que hemos desarrollado
              desde la Asociación de Ayuda Animal Ágata, una pequeña asociación
              cuya principal misión es ayudar en la modernización digital de las
              asociaciones animalistas de manera voluntaria y utilizando
              nuestros conocimientos profesionales. <br /><br />
              Somos amantes de los animales y creemos que ayudando con esta
              digitalización contribuiremos a encontrar más adopciones, casas de
              acogida y más recursos para estas maravillosas criaturas y quienes
              luchas por ellas.
            </p>
          </div>
        </div>
        <div class="landing__mision-button landing__button">
          <a href="https://ayudaanimalagata.org/" target="_blank"
            >Quiero saber más</a
          >
        </div>
      </div>
    </div>

    <div class="landing__footer-full">
      <div class="landing__footer-cont">
        <div>
          <div class="landing__footer-title">¡AYÚDANOS A SEGUIR!</div>
          <div class="landing__footer-info">
            Forma parte de este pequeño gran proyecto y colabora en la
            digitalización de las asociaciones animalistas por solo 1€ al mes 😺
          </div>
          <div class="landing__mision-button landing__button">
            <a href="https://www.teaming.net/orcaapp" target="_blank"
              >¡Hazte Teamer!</a
            >
          </div>
        </div>
        <div>
          <div class="landing__footer-title">CONTÁCTANOS</div>
          <div class="landing__footer-info">
            <a href="mailto:agataprotectora@gmail.com"
              >agataprotectora@gmail.com</a
            >
          </div>
        </div>
        <div>
          <div class="landing__footer-title">SÍGUENOS EN REDES</div>
          <div class="landing__footer__rrss">
            <a
              href="https://www.instagram.com/asociacion_agata/"
              target="_blank"
              ><img src="assets/img/instagram-brands.svg" alt=""
            /></a>
            <a
              href="https://www.facebook.com/asocAyudaAnimalAgata"
              target="_blank"
              ><img src="assets/img/facebook-brands.svg" alt=""
            /></a>
          </div>
        </div>
      </div>
      <div class="landing__footer-agata">
        <a href="https://ayudaanimalagata.org/" target="_blank"
          ><img src="assets/img/madeWithLoveBy_onlyLyrics.svg" alt=""
        /></a>
      </div>
    </div>
  </div>
</div>
