import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IAnimal } from 'src/app/interfaces/animal';
import { IAnimalsFilters } from 'src/app/interfaces/filters/animalsFilters';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { IRandomAnimalName } from 'src/app/interfaces/randomAnimalName';

@Injectable({
  providedIn: 'root'
})
export class AnimalService {
  private apiUrl = SETTINGS.APIRUL + 'animal/';

  constructor(private httpClient: HttpClient) {}

  getAnimalsByFilters(filters: IAnimalsFilters): Observable<IAnimal[]> {
    return this.httpClient
      .post<IAnimal[]>(this.apiUrl + 'getAnimalsByFilters.php', filters)
      .pipe(
        map((data: IAnimal[]) => {
          return data;
        })
      );
  }

  getAnimalById(id: string): Observable<IAnimal> {
    const params = new HttpParams().set('animalId', id);

    return this.httpClient
      .get<IAnimal>(this.apiUrl + 'getAnimalById.php', { params })
      .pipe(
        map((data: IAnimal) => {
          return data;
        })
      );
  }

  postAnimal(animal: IAnimal): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'addAnimal.php', animal)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  editAnimal(animal: IAnimal): Observable<IAnimal> {
    return this.httpClient
      .post<IAnimal>(this.apiUrl + 'editAnimal.php', animal)
      .pipe(
        map((data: IAnimal) => {
          return data;
        })
      );
  }

  deleteAnimalById(animalId: number) {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteAnimal.php?animalId=' + animalId
    );
  }

  deleteAnimalImage(animalId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteAnimalImage.php?animalId=' + animalId
    );
  }

  uploadAnimalImage(imageUpload: IImageUpload) {
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadAnimalImage.php',
      imageUpload,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  getRandomName(): Observable<IRandomAnimalName> {
    return this.httpClient
      .get<IRandomAnimalName[]>(this.apiUrl + 'getRandomAnimalName.php')
      .pipe(
        map((data: IRandomAnimalName[]) => {
          return data[0];
        })
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error ocurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
