import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { UserProfileService } from './services/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkUserLogin(next);
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    if (this.userProfileService.isLoggedIn()) {
      const userProfile = Number(this.userProfileService.userProfile.profileId);
      if (route.data.role && route.data.role.indexOf(userProfile) === -1) {
        this.router.navigate(['']);
        return false;
      }
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
