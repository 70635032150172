<app-titlebar
  [title]="'titles.newsList' | translate"
  icon="fas fa-newspaper"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <div></div>
        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <p class="u-input__text">{{ 'forms.title' | translate }}</p>
                <div class="u-input__content">
                  <input
                    type="text"
                    class="u-input__input"
                    id="input"
                    placeholder="{{ 'forms.title_placeholder' | translate }}"
                    formControlName="title"
                  />
                </div>
              </div>

              <div class="u-input u-input--select">
                <p class="u-input__text">{{ 'forms.month' | translate }}</p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="month">
                    <mat-option [value]="0">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let month of months"
                      [value]="month.value"
                    >
                      {{ month.text | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">{{ 'forms.year' | translate }}</p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="year">
                    <mat-option [value]="0">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option *ngFor="let year of years" [value]="year.value">
                      {{ year.text }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="news__cont">
      <div class="news__block" *ngFor="let news of newsList; let i = index">
        <div class="news__block-title">
          <h5>
            <i class="far fa-newspaper"></i>
            {{ news.creationDate.replace(' ', 'T') | date : 'dd/MM/YYYY' }}
          </h5>
        </div>

        <div
          class="news__block-image"
          *ngIf="news.image"
          [ngStyle]="{
            'background-image': 'url(' + newsImage(news.image) + ')'
          }"
        ></div>
        <div class="news__block-news">
          <h3>{{ news.title }}</h3>
          <h4>{{ news.subtitle }}</h4>
          <p [innerHTML]="news.info"></p>
        </div>
      </div>
    </div>
  </div>
</div>
