import { Component, Input, OnInit } from '@angular/core';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() parentTitle: string;
  @Input() parentPath: string;

  public tenantHasWeb: boolean;

  constructor(private userProfileService: UserProfileService) {}

  ngOnInit(): void {
    this.tenantHasWeb = this.userProfileData.tenantHasWebPage;
  }

  get userProfileData(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  get tenantHasFacebook(): boolean {
    return (
      this.userProfileData.tenantFacebook &&
      this.userProfileData.tenantFacebook.trim() !== ''
    );
  }

  get tenantHasInstagram(): boolean {
    return (
      this.userProfileData.tenantInstagram &&
      this.userProfileData.tenantInstagram.trim() !== ''
    );
  }

  get tenantHasTwitter(): boolean {
    return (
      this.userProfileData.tenantTwitter &&
      this.userProfileData.tenantTwitter.trim() !== ''
    );
  }

  get tenantHasYoutube(): boolean {
    return (
      this.userProfileData.tenantYoutube &&
      this.userProfileData.tenantYoutube.trim() !== ''
    );
  }

  get tenantHasTiktok(): boolean {
    return (
      this.userProfileData.tenantTiktok &&
      this.userProfileData.tenantTiktok.trim() !== ''
    );
  }

  goToTenantWeb() {
    window.open(this.userProfileData.tenantWebUrl, '_blank');
  }

  goToFacebook() {
    window.open(this.userProfileData.tenantFacebook, '_blank');
  }

  goToInstagram() {
    window.open(this.userProfileData.tenantInstagram, '_blank');
  }

  goToTwitter() {
    window.open(this.userProfileData.tenantTwitter, '_blank');
  }

  goToYoutube() {
    window.open(this.userProfileData.tenantYoutube, '_blank');
  }

  goToTiktok() {
    window.open(this.userProfileData.tenantTiktok, '_blank');
  }
}
