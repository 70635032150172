<!-- <app-titlebar [title]="'titles.dashboard' | translate"></app-titlebar> -->
<div class="dashboard__content">
  <div class="dashboard__counters">
    <app-dashboard-counter
      backgroundColor="#3B8DCC"
      icon="fas fa-hippo"
      literal="dashboard.total_animals"
      [counter]="totals.animals"
      routerLink="/animal-list"
    ></app-dashboard-counter>
    <app-dashboard-counter
      backgroundColor="#00C8CF"
      icon="fas fa-map-marker"
      literal="dashboard.total_localizations"
      [counter]="totals.localizations"
      routerLink="/localization-list"
    ></app-dashboard-counter>
    <app-dashboard-counter
      backgroundColor="#29DFAF"
      icon="fas fa-home"
      literal="dashboard.total_adopteds"
      [counter]="totals.adopteds"
      routerLink="/animal-list"
    ></app-dashboard-counter>
    <app-dashboard-counter
      backgroundColor="#8E61B0"
      icon="fas fa-calendar"
      literal="dashboard.total_events"
      [counter]="totals.events"
      routerLink="/events/"
    ></app-dashboard-counter>
  </div>

  <div class="dashboard__columns">
    <div>
      <!-- users -->
      <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-hippo"></i>
          {{ 'titles.users_list' | translate }}
        </div>

        <div class="dashboard__users" routerLink="/user-list">
          <div class="dashboard__users-content">
            <div *ngFor="let user of users">
              <div
                *ngIf="user.profileId === 1"
                class="dashboard__users-img dashboard__users-img--big-whale"
              ></div>
              <div
                *ngIf="user.profileId === 2"
                class="dashboard__users-img dashboard__users-img--orca"
              ></div>
              <div
                *ngIf="user.profileId === 3"
                class="dashboard__users-img dashboard__users-img--dolphin"
              ></div>
              <p class="dashboard__users-name">{{ user.name }}</p>
              <p class="dashboard__users-text">
                {{ profileLabels[user.profileId] | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- links -->
      <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-link"></i>
          {{ 'titles.associonationLinks' | translate }}
        </div>
        <p [innerHTML]="'dashboard.links' | translate"></p>
        <div class="dashboard__icons">
          <div
            class="dashboard__icon"
            (click)="goToTenantWeb()"
            *ngIf="tenantHasWeb"
          >
            <i class="fas fa-globe"></i
            ><span> {{ 'dashboard.website' | translate }} </span>
          </div>
          <div
            class="dashboard__icon"
            (click)="goToFacebook()"
            *ngIf="tenantHasFacebook"
          >
            <i class="fab fa-facebook"></i>
            <span>{{ 'dashboard.facebook' | translate }} </span>
          </div>
          <div
            class="dashboard__icon"
            (click)="goToInstagram()"
            *ngIf="tenantHasInstagram"
          >
            <i class="fab fa-instagram"></i>
            <span>{{ 'dashboard.instagram' | translate }}</span>
          </div>
          <div
            class="dashboard__icon"
            (click)="goToTwitter()"
            *ngIf="tenantHasTwitter"
          >
            <i class="fab fa-twitter"></i>
            <span>{{ 'dashboard.twitter' | translate }} </span>
          </div>
          <div
            class="dashboard__icon"
            (click)="goToYoutube()"
            *ngIf="tenantHasYoutube"
          >
            <i class="fab fa-youtube"></i>
            <span>{{ 'dashboard.youtube' | translate }} </span>
          </div>
          <div
            class="dashboard__icon"
            (click)="goToTiktok()"
            *ngIf="tenantHasTiktok"
          >
            <i class="fab fa-tiktok"></i>
            <span>{{ 'dashboard.tiktok' | translate }} </span>
          </div>
        </div>
      </div>

      <!-- newStuff -->
      <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-kiwi-bird"></i>
          {{ 'titles.newStuff' | translate }}
        </div>
        <div class="dashboard__novelty">
          <div class="dashboard__novelty-date">11/03/2024</div>
          <ul class="dashboard__novelty-list">
            <li>
              <p>{{ 'dashboardNovelty.improveLayoutAnimalDetail' | translate }}</p>
            </li>
          </ul>
        </div>
        <div class="dashboard__novelty">
          <div class="dashboard__novelty-date">01/04/2023</div>
          <ul class="dashboard__novelty-list">
            <li><p>{{ 'dashboardNovelty.animalBills' | translate }}</p></li>
            <li><p>{{ 'dashboardNovelty.usersBoxOnDashboard' | translate }}</p></li>
          </ul>
        </div>
        <!-- <div class="dashboard__novelty">
          <div class="dashboard__novelty-date">24/02/2023</div>
          <ul class="dashboard__novelty-list">
            <li>{{ 'dashboardNovelty.randomName' | translate }}</li>
            <li>{{ 'dashboardNovelty.novelty' | translate }}</li>
            <li>{{ 'dashboardNovelty.downloads' | translate }}</li>
            <li>{{ 'dashboardNovelty.linkOnAnimal' | translate }}</li>
          </ul>
        </div> -->
      </div>

      <!-- donations -->
      <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-paw"></i> {{ 'titles.donations' | translate }}
        </div>
        <p [innerHTML]="'dashboard.donations_message' | translate"></p>
        <a href="https://www.teaming.net/orcaapp" target="_blank">
          <img
            class="dashboard__block-img"
            src="../../../assets/img/logo_teaming.png"
            alt="Teaming"
            title="Teaming"
          />
        </a>
      </div>
    </div>

    <div>
      <!-- news -->
      <!-- <div class="dashboard__block dashboard__block-news" *ngIf="news">
        <div class="dashboard__block-title">
          <i class="far fa-newspaper"></i> {{ 'titles.news' | translate }}
        </div>
        <div
          class="dashboard__block-news-image"
          *ngIf="news.image"
          [ngStyle]="{
            'background-image': 'url(' + newsImage(news.image) + ')'
          }"
        ></div>
        <div class="dashboard__news">
          <h5>
            {{ news.creationDate.replace(' ', 'T') | date : 'dd/MM/YYYY' }}
          </h5>
          <h3>{{ news.title }}</h3>
          <h4>{{ news.subtitle }}</h4>
          <p [innerHTML]="news.info"></p>
        </div>
        <div class="dashboard__news-link" (click)="goToMoreNews()">
          {{ 'dashboard.moreNews' | translate }}
        </div>
      </div> -->

      <!-- ágata -->
      <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-heart"></i> {{ 'titles.hello' | translate }}
        </div>
        <p
          class="dashboard__welcome"
          [innerHTML]="'dashboard.hello_message' | translate"
        ></p>
        <a href="https://ayudaanimalagata.org/" target="_blank">
          <img
            class="dashboard__block-img"
            src="../../../assets/img/madeWithLoveBy.jpg"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
</div>

<!-- <div>
  <div class="dashboard__block">
    <div class="dashboard__block-title">
      <i class="fas fa-paw"></i> {{ 'titles.lastAnimal' | translate }}
    </div>
    <div class="dashboard__last_animal" *ngIf="lastCreatedAnimal">
      <div
        class="dashboard__last_animal_image {{
          lastCreatedAnimal.principalImageFileName
            ? 'has-img'
            : 'has-no-img'
        }}"
        [ngStyle]="
          lastCreatedAnimal.principalImageFileName
            ? {
                'background-image':
                  'url(' +
                  animalImage(lastCreatedAnimal.principalImageFileName) +
                  ')'
              }
            : { 'background-image': 'url(assets/img/animal_default.jpg)' }
        "
      ></div>
      <div class="dashboard__last_animal_data">
        <div class="dashboard__last_animal_name">
          {{ lastCreatedAnimal.name }}
        </div>
        <div class="dashboard__last_animal_action">
          <bindy-button
            text="{{ 'common.button_goToDetails' | translate }}"
            icon="fa-save"
            (onClick)="goToAnimalDetail(lastCreatedAnimal.id)"
          ></bindy-button>
        </div>
      </div>
    </div>
    <div class="dashboard__welcome" *ngIf="!lastCreatedAnimal">
      {{ 'dashboard.noAnimals' | translate }}
    </div>
  </div>
</div>

 <div class="dashboard__block">
        <div class="dashboard__block-title">
          <i class="fas fa-hand-peace"></i>
          {{ 'titles.finishProfile' | translate }}
        </div>
        <div class="dashb_oard_we-come-info">
          <div>
            <mat-stepper [orientation]="stepperOrientation" #stepper>
              <mat-step
                label="{{ 'dashboard.label_adress' | translate }}"
                state="address"
              >
                <div
                  class="dashboard__welcome-step-info"
                  [innerHTML]="'dashboard.profile_step1' | translate"
                ></div>
              </mat-step>
              <mat-step
                label="{{ 'dashboard.label_phone' | translate }}"
                state="phone"
              >
                <div
                  class="dashboard__welcome-step-info"
                  [innerHTML]="'dashboard.profile_step2' | translate"
                ></div>
              </mat-step>
              <mat-step
                label="{{ 'dashboard.label_photo' | translate }}"
                state="photo"
              >
                <div
                  class="dashboard__welcome-step-info"
                  [innerHTML]="'dashboard.profile_step3' | translate"
                ></div>
              </mat-step>
              <mat-step
                label="{{ 'dashboard.label_complete' | translate }}"
                state="done"
              >
                <div
                  class="dashboard__welcome-step-info"
                  [innerHTML]="'dashboard.profile_step4' | translate"
                ></div>
              </mat-step>
            </mat-stepper>
          </div>
        </div>
      </div>


      <div *ngIf="showSpeciesChart" class="dashboard__block" #ContainerRef>
        <div class="dashboard__block-title">
          <i class="fas fa-hippo"></i>
          {{ 'titles.animalsBySpecie' | translate }}
        </div>
        <div class="dashboard__species_chart">
          <ngx-charts-pie-grid [results]="chartData"> </ngx-charts-pie-grid>
        </div>
      </div>

-->

<app-modal-welcome
  [openModal]="open"
  (closeModal)="closeModalWelcome()"
></app-modal-welcome>
