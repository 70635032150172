<app-titlebar
  icon="far fa-calendar-alt"
  [title]="'titles.eventDetail' | translate"
  [parentTitle]="'titles.events' | translate"
  parentPath="/events"
></app-titlebar>
<div class="c-event-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div *ngIf="!showLoading">
      <form [formGroup]="eventForm" (ngSubmit)="sendForm()">
        <div class="c-event-detail__header">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="far fa-calendar-alt"></i>
              {{ 'titles.eventDetail' | translate }}:
            </legend>
            <div class="c-event-detail__fieldset-content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="title"
                  label="{{ 'forms.title' | translate }}"
                  placeholder="{{ 'forms.title_placeholder' | translate }}"
                  required="true"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('title')">
                  <p
                    class="u-error"
                    *ngIf="eventForm.get('title').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="c-event-detail__double-col">
                <div class="u-input">
                  <p class="u-input__text">
                    {{ 'forms.startDate' | translate }}
                  </p>
                  <div class="u-input__content u-input__content--icon">
                    <input
                      [matDatepicker]="startDateDatepicker"
                      format="yyyy-MM-dd"
                      placeholder="{{ 'forms.startDate' | translate }}"
                      class="u-input__input"
                      formControlName="startDate"
                      (click)="startDateDatepicker.open()"
                    />
                    <ng-container *ngIf="inputValidation('startDate')">
                      <p
                        class="u-error"
                        *ngIf="eventForm.get('startDate').errors?.required"
                      >
                        {{ 'common.mandatoryField' | translate }}
                      </p>
                    </ng-container>
                    <mat-datepicker-toggle
                      [for]="startDateDatepicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDateDatepicker></mat-datepicker>
                  </div>
                </div>
                <div class="u-input">
                  <p class="u-input__text">{{ 'forms.endDate' | translate }}</p>
                  <div class="u-input__content u-input__content--icon">
                    <input
                      [matDatepicker]="endDateDatepicker"
                      format="yyyy-MM-dd"
                      placeholder="{{ 'forms.endDate' | translate }}"
                      class="u-input__input"
                      formControlName="endDate"
                      (click)="endDateDatepicker.open()"
                    />
                    <mat-datepicker-toggle
                      [for]="endDateDatepicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDateDatepicker></mat-datepicker>
                  </div>
                </div>
              </div>

              <div class="c-event-detail__double-col">
                <div class="u-input">
                  <p class="u-input__text">
                    {{ 'forms.startHour' | translate }}
                  </p>
                  <div class="u-input__content u-input__content--icon-time">
                    <input
                      type="time"
                      placeholder="{{ 'forms.hourFormat' | translate }}"
                      class="u-input__input"
                      formControlName="startHour"
                    />
                  </div>
                </div>
                <div class="u-input">
                  <p class="u-input__text">{{ 'forms.endHour' | translate }}</p>
                  <div class="u-input__content u-input__content--icon-time">
                    <input
                      type="time"
                      placeholder="{{ 'forms.hourFormat' | translate }}"
                      class="u-input__input"
                      formControlName="endHour"
                    />
                  </div>
                </div>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="address"
                  label="{{ 'forms.address' | translate }}"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                ></bindy-input-text>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="city"
                  label="{{ 'forms.city' | translate }}"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                ></bindy-input-text>
              </div>

              <div class="u-input">
                <p class="u-input__text">
                  {{ 'forms.description' | translate }}
                </p>
                <div class="">
                  <textarea
                    type="text"
                    class="u-input__textarea"
                    id="description"
                    rows="2"
                    placeholder="{{ 'forms.writeSomething' | translate }}"
                    formControlName="description"
                  ></textarea>
                </div>
              </div>

              <div class="u-input" *ngIf="tenantHasWeb">
                <p class="u-input__text"></p>
                <label class="u-input__checkbox u-input__text"
                  >{{ 'forms.showInWeb' | translate }}
                  <input type="checkbox" formControlName="showInWeb" checked />
                  <span class="u-input__checkbox-checkmark"></span>
                </label>
              </div>
              <div class="u-detail__button">
                <div class="u-detail__button-left">
                  <bindy-button
                    *ngIf="showDeleteButton"
                    text="{{ 'common.button_delete' | translate }}"
                    icon="fa-trash-alt"
                    color="danger"
                    (onClick)="deleteEvent()"
                  ></bindy-button>
                </div>
                <div>
                  <bindy-button
                    text="{{ 'common.button_save' | translate }}"
                    icon="fa-save"
                    type="submit"
                  ></bindy-button>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-camera"></i> {{ 'titles.photo' | translate }}
            </legend>
            <bindy-file-upload
              [imageURL]="getEventImage()"
              [id]="_eventId"
              (onUpload)="setEventImage($event)"
              (onDelete)="deleteEventImage()"
              freeAspect="true"
            ></bindy-file-upload>
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</div>
