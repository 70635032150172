<app-titlebar
  [title]="'titles.downloads' | translate"
  icon="fas fa-download"
></app-titlebar>

<div class="downloads">
  <div class="u-content">
    <fieldset class="u-fieldset">
      <div class="downloads__title">
        <i class="fas fa-download"></i>{{ 'downloads.orcaApp' | translate }}
      </div>
      <div
        class="downloads__document"
        (click)="downloadFile(downloads.ORCA_TUTORIAL, 'OrcaApp.pdf')"
      >
        <div>
          <img
            class="downloads__document-img"
            src="assets/img/logo50.png"
            alt=""
          />
        </div>
        <div>
          {{ 'downloads.tutorial' | translate }}
          <div class="downloads__document-text hidden-xs">
            {{ 'downloads.size' | translate }}: 2.007 Kb
          </div>
        </div>
      </div>

      <div class="downloads__title">
        <i class="fas fa-download"></i>{{ 'downloads.rrss' | translate }}
      </div>
      <div class="downloads__documents">
        <div
          class="downloads__document"
          (click)="downloadFile(downloads.LINK_TREE, 'AñadirLinktree.pdf')"
        >
          <div>
            <img
              class="downloads__document-img downloads__document-img--linktree"
              src="assets/img/linktree-50.jpg"
              alt=""
            />
          </div>
          <div>
            {{ 'downloads.linktree' | translate }}
            <div class="downloads__document-text hidden-xs">
              {{ 'downloads.size' | translate }}: 826 Kb
            </div>
          </div>
        </div>
        <div
          class="downloads__document"
          (click)="
            downloadFile(
              downloads.IMPROVE_IG_PROFILE,
              'Guia_para_optimizar_tu_perfil_de_Instagram_Mujeres_con_marcas.pdf'
            )
          "
        >
          <div>
            <img
              class="downloads__document-img"
              src="assets/img/mcm-50.jpg"
              alt=""
            />
          </div>
          <div>
            {{ 'downloads.improve_instagram_profile' | translate }}
            <div class="downloads__document-text hidden-xs">
              {{ 'downloads.size' | translate }}: 7.708 Kb
            </div>
          </div>
        </div>
      </div>

      <div class="downloads__title">
        <i class="fas fa-download"></i>{{ 'downloads.others' | translate }}
      </div>

      <div
        class="downloads__document"
        (click)="downloadFile(downloads.AMAZON_WISHLIST, 'amazonWishList.pdf')"
      >
        <div>
          <img
            class="downloads__document-img"
            src="assets/img/amazon-50.jpg"
            alt=""
          />
        </div>
        <div>
          {{ 'downloads.amazon' | translate }}
          <div class="downloads__document-text hidden-xs">
            {{ 'downloads.size' | translate }}: 900 Kb
          </div>
        </div>
      </div>

      <div class="downloads__title">
        <i class="fas fa-download"></i>
        {{ 'downloads.electronicSignature' | translate }}
      </div>
      <div class="downloads__documents">
        <div
          class="downloads__document"
          (click)="
            downloadFile(
              downloads.PHISICAL_PERSON_GUIDE,
              'firmaElectronicaPersonaFisica.pdf'
            )
          "
        >
          <div>
            <img
              class="downloads__document-icon-download"
              src="assets/img/download-icon-blue.svg"
              alt=""
            />
          </div>
          <div>
            {{ 'downloads.phisical_person_signature' | translate }}
            <div class="downloads__document-text hidden-xs">
              {{ 'downloads.size' | translate }}: 262 Kb
            </div>
          </div>
        </div>
        <div
          class="downloads__document"
          (click)="
            downloadFile(
              downloads.JURIDIC_PERSON_GUIDE,
              'firmaElectronicaPersonaJuridica.pdf'
            )
          "
        >
          <div>
            <img
              class="downloads__document-icon-download"
              src="assets/img/download-icon-blue.svg"
              alt=""
            />
          </div>
          <div>
            {{ 'downloads.juridical_person_signature' | translate }}
            <div class="downloads__document-text hidden-xs">
              {{ 'downloads.size' | translate }}: 1.205 Kb
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>
