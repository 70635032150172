<app-titlebar
  [title]="'titles.usersSuplant' | translate"
  icon="fas fa-users"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="user-suplant">
  <div class="u-content">
    <div class="u-filters__container">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}
            </legend>
            <div class="u-filters__content">
              <div class="u-input u-input--select">
                <p class="u-input__text">{{ 'forms.tenant' | translate }}</p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="tenantId">
                    <mat-option value="">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let tenant of tenants"
                      [value]="tenant.uuid"
                    >
                      {{ tenant.name }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="name"
                  label="{{ 'forms.name' | translate }}"
                  placeholder="{{ 'forms.name_placeholder' | translate }}"
                  type="text"
                ></bindy-input-text>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">{{ 'forms.profile' | translate }}</p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="profileId"
                  >
                    <mat-option value="">{{
                      'forms.select_all' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let profile of profiles"
                      [value]="profile"
                    >
                      {{ profileLabels[profile] | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="u-actions__container">
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ users.length }}
        </div>
      </div>
    </div>

    <div *ngIf="users.length > 0" class="u-table">
      <div class="u-table__header user-suplant__table-header">
        <div></div>
        <div>{{ 'common.name' | translate }}</div>
        <div>{{ 'common.surname' | translate }}</div>
        <div>{{ 'titles.tenant_detail' | translate }}</div>
        <div>{{ 'common.profile' | translate }}</div>
      </div>
      <div *ngIf="users.length > 0">
        <div
          class="u-table__data user-suplant__table-data"
          *ngFor="let user of users"
          (click)="suplantIdentity(user.id)"
        >
          <div class="u-table__circle_image">
            <img
              [src]="
                !!user.image
                  ? userImage(user.image)
                  : '/assets/img/user_default.png'
              "
            />
          </div>
          <div class="hidden-xs">{{ user.name }}</div>
          <div class="hidden-xs">{{ user.surName }}</div>
          <div class="hidden-xs">{{ getTenantName(user.tenantId) }}</div>
          <div class="hidden-xs">
            {{ profileLabels[user.profileId] | translate }}
          </div>
          <div class="visible-xs">
            <div>{{ user.name }} {{ user.surName }}</div>
            <div>{{ getTenantName(user.tenantId) }}</div>
            <div>
              <strong
                ><b>Perfil</b>:
                {{ profileLabels[user.profileId] | translate }}</strong
              >
            </div>
          </div>
        </div>
      </div>
      <div class="u-table__no_results" *ngIf="users.length === 0">
        {{ 'common.noResult' | translate }}
      </div>
    </div>
  </div>
</div>
