<app-titlebar
  [title]="'titles.events' | translate"
  icon="far fa-calendar-alt"
></app-titlebar>
<div class="c-events">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div *ngIf="!showLoading">
      <fieldset class="u-fieldset">
        <legend class="u-subtitle u-legend">
          <i class="fas fa-info"></i> {{ 'titles.information' | translate }}
        </legend>
        <div class="u-fieldset-info">
          <p>{{ 'events.informationCalendar' | translate }}</p>
        </div>
      </fieldset>
      <fieldset class="u-fieldset c-events__calendar">
        <legend class="u-subtitle u-legend">
          <i class="fas fa-calendar-alt"></i>
          {{ 'titles.calendar' | translate }}
        </legend>
        <full-calendar [options]="calendarOptions"> </full-calendar>
      </fieldset>
    </div>
  </div>
</div>
