<app-titlebar
  icon="fas fa-building"
  [title]="'titles.tenant_detail' | translate"
  [parentTitle]="'titles.tenant_list' | translate"
  parentPath="/tenant-list"
></app-titlebar>
<div class="c-tenant-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>
    <div>
      <div *ngIf="!showLoading">
        <form
          [formGroup]="tenantForm"
          (ngSubmit)="sendTenant()"
          class="c-tenant-detail__form"
        >
          <input type="hidden" formControlName="id" />

          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-otter"></i>
              {{ 'titles.generalData' | translate }}
            </legend>
            <div class="c-tenant-detail__fieldset-content">
              <div class="c-tenant-detail__grid">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="name"
                    label="{{ 'common.name' | translate }}"
                    placeholder="{{ 'forms.name_placeholder' | translate }}"
                    required="true"
                  ></bindy-input-text>
                  <ng-container *ngIf="inputValidation('name')">
                    <p
                      class="u-error"
                      *ngIf="tenantForm.get('name').errors?.required"
                    >
                      {{ 'common.mandatoryField' | translate }}
                    </p>
                  </ng-container>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="uuid"
                    label="{{ 'common.id' | translate }}"
                    placeholder="{{ 'forms.id_placeholder' | translate }}"
                    disabled="true"
                  ></bindy-input-text>
                </div>

                <div class="u-input">
                  <bindy-input-text
                    formControlName="email"
                    label="{{ 'common.email' | translate }}"
                    placeholder="{{
                      'myProfile.email_placeholder' | translate
                    }}"
                    type="email"
                  ></bindy-input-text>
                  <ng-container *ngIf="inputValidation('email')">
                    <p
                      class="u-error"
                      *ngIf="tenantForm.get('email').errors?.email"
                    >
                      {{ 'myProfile.email_wrong' | translate }}
                    </p>
                  </ng-container>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="phone"
                    label="{{ 'common.phone' | translate }}"
                    placeholder="{{
                      'myProfile.phone_placeholder' | translate
                    }}"
                    type="number"
                  ></bindy-input-text>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="webUrl"
                    label="{{ 'tenant.webUrl' | translate }}"
                    placeholder="{{ 'tenant.webUrl_placeholder' | translate }}"
                  ></bindy-input-text>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="c-tenant-detail__two-cols">
            <fieldset class="u-fieldset">
              <legend class="u-subtitle u-legend">
                <i class="fas fa-columns"></i>
                {{ 'titles.moreData' | translate }}
              </legend>
              <div class="c-tenant-detail__checkbox-content">
                <div class="u-input">
                  <label class="u-input__checkbox u-input__text"
                    >{{ 'tenant.hasWebPage' | translate }}
                    <input type="checkbox" formControlName="hasWebPage" />
                    <span class="u-input__checkbox-checkmark"></span>
                  </label>
                </div>
                <div class="u-input">
                  <label class="u-input__checkbox u-input__text"
                    >{{ 'tenant.hasAdoptionPage' | translate }}
                    <input type="checkbox" formControlName="hasAdoptionPage" />
                    <span class="u-input__checkbox-checkmark"></span>
                  </label>
                </div>
                <div class="u-input">
                  <label class="u-input__checkbox u-input__text"
                    >{{ 'tenant.hasAdoptedPage' | translate }}
                    <input type="checkbox" formControlName="hasAdoptedPage" />
                    <span class="u-input__checkbox-checkmark"></span>
                  </label>
                </div>
                <div class="u-input">
                  <label class="u-input__checkbox u-input__text"
                    >{{ 'tenant.hasSponsorPage' | translate }}
                    <input type="checkbox" formControlName="hasSponsorPage" />
                    <span class="u-input__checkbox-checkmark"></span>
                  </label>
                </div>
              </div>
            </fieldset>

            <fieldset class="u-fieldset">
              <legend class="u-subtitle u-legend">
                <i class="fas fa-camera"></i> {{ 'titles.photo' | translate }}
              </legend>
              <bindy-file-upload
                [imageURL]="getTenantImage()"
                [id]="_tenantId"
                (onUpload)="setTenantImage($event)"
                [uploadProgress]="uploadProgress"
                (onDelete)="deleteTenantImage()"
              ></bindy-file-upload>
            </fieldset>
          </div>
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-rss"></i> {{ 'titles.social' | translate }}
            </legend>
            <div class="c-tenant-detail__fieldset-content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="facebook"
                  label="Facebook"
                  placeholder=""
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="instagram"
                  label="Instagram"
                  placeholder=""
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="twitter"
                  label="Twitter"
                  placeholder=""
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="tiktok"
                  label="Tik Tok"
                  placeholder=""
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="youtube"
                  label="Youtube"
                  placeholder=""
                ></bindy-input-text>
              </div>
            </div>
            <div class="u-detail__button">
              <div></div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
