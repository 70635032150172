import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { IEventsFilters } from 'src/app/interfaces/filters/eventsFilters';
import { EventService } from 'src/app/services/event.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { SETTINGS } from 'src/app/SETTINGS';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { MatDialog } from '@angular/material/dialog';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent implements OnInit {
  showLoading = false;
  eventForm: UntypedFormGroup;
  _eventId: number;
  showDeleteButton = false;
  tenantHasWeb = false;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();
  private _eventImageUpload: IImageUpload = null;
  private _eventHasImage = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private userProfileService: UserProfileService,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get userProfileId(): number {
    return this.userProfileService.userProfile.id;
  }

  ngOnInit() {
    this._eventId = Number(this.activatedRoute.snapshot.params.id);
    this.tenantHasWeb = this.userProfileService.userProfile.tenantHasWebPage;
    this.initForm();
    if (this._eventId !== 0) {
      this.showDeleteButton = true;
      this.getEventById();
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  getEventById(): void {
    this.showLoading = true;
    const filters: IEventsFilters = {
      tenantId: this.userProfileTenantId,
      month: 0,
      year: 0,
      id: this._eventId
    };

    this.eventService
      .getEventsByFilters(filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data) => {
        this.showLoading = false;
        this.eventForm.patchValue(data[0]);
        this._eventHasImage = data[0].hasImage;
      });
  }

  sendForm() {
    if (!this.eventForm.valid) {
      markFormGroupTouched(this.eventForm);
      return;
    }
    this.showLoading = true;

    this.formatDate('startDate');
    this.formatDate('endDate');
    this.formatHour('startHour');
    this.formatHour('endHour');

    this.eventService
      .postEvent(this.eventForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((eventId: number) => {
        this.checkIfUploadImage(eventId);
      });
  }

  checkIfUploadImage(eventId: number): void {
    if (!this._eventImageUpload) {
      this.finalizeUploadEvent();
      return;
    }
    this._eventImageUpload.id = eventId;
    this.eventService
      .uploadEventImage(this._eventImageUpload)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.finalizeUploadEvent();
        })
      )
      .subscribe();
  }

  finalizeUploadEvent(): void {
    this.showLoading = false;
    let successMessage = 'toast.EVENT_CREATE_SUCCESS';
    if (this._eventId !== 0) {
      successMessage = 'toast.EVENT_EDIT_SUCCESS';
    }
    this.showSuccessToast(successMessage);
    this.router.navigate([`events`]);
  }

  setEventImage(imageUpload: IImageUpload) {
    this._eventImageUpload = imageUpload;
  }

  getEventImage() {
    if (!this._eventHasImage) {
      return '';
    }
    return `${SETTINGS.EVENTS_IMAGE_PATH}event__${this._eventId}.jpg`;
  }

  deleteEventImage(): void {
    this.eventService
      .deleteEventImage(this._eventId)
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.IMAGE_DELETE_SUCCESS');
          this._eventHasImage = false;
        })
      )
      .subscribe();
  }

  deleteEvent(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_EVENT_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteEvent();
        }
      });
  }

  confirmDeleteEvent(): void {
    this.showLoading = true;
    this.eventService
      .deleteEventById(this._eventId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.EVENT_DELETE_SUCCESS');
          this.router.navigate([`events`]);
        })
      )
      .subscribe();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.eventForm, inputName);
  }

  private initForm() {
    this.eventForm = this.fb.group({
      id: [null],
      tenantId: [this.userProfileTenantId],
      creatorUserId: [this.userProfileId],
      title: ['', Validators.required],
      description: [''],
      address: [''],
      city: [''],
      startDate: ['', Validators.required],
      endDate: [''],
      startHour: [''],
      endHour: [''],
      showInWeb: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }

  private formatDate(inputName: string) {
    if (this.eventForm.get(inputName).value) {
      this.eventForm
        .get(inputName)
        .setValue(
          formatDate(this.eventForm.get(inputName).value, 'dd-MM-yyy', 'en')
        );
    }
  }

  private formatHour(inputName: string) {
    const hour = this.eventForm.get(inputName).value;
    if (!hour) {
      this.eventForm.get(inputName).setValue(null);
    }
  }
}
