export enum Profiles {
  GOD = 1,
  ADMIN = 2,
  CONTRIBUTOR = 3
}

export const ProfileLabels: Record<Profiles, string> = {
  [Profiles.GOD]: 'common.profile_god',
  [Profiles.ADMIN]: 'common.profile_admin',
  [Profiles.CONTRIBUTOR]: 'common.profile_volunteer'
};
