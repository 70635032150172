<div class="bindy-file-upload-multiple">
  <div class="bindy-file-upload-multiple__photos">
    <div
      class="bindy-file-upload-multiple__photos-item"
      *ngFor="
        let image of animalImages | slice : 0 : 4;
        trackBy: trackByFn;
        let i = index
      "
    >
      <div
        class="bindy-file-upload-multiple__content"
        [class]="'bindy-file-upload-multiple__content--' + (i + 1)"
      >
        <input
          type="file"
          class="bindy-file-upload-multiple-file-input"
          #fileUpload
          (change)="onFileChange($event)"
          (click)="$event.target.value = null"
        />

        <div
          class="bindy-file-upload-multiple__button"
          *ngIf="!image.fileName"
          (click)="fileUpload.click()"
        >
          <i class="fas fa-camera"></i>
        </div>
        <div *ngIf="image.fileName" class="bindy-file-upload-multiple__preview">
          <div
            class="bindy-file-upload-multiple__preview-delete"
            (click)="deleteImageFromServer(image)"
          >
            <i class="fas fa-times-circle"></i>
          </div>
          <img
            class="bindy-file-upload-multiple__img"
            [src]="imagePath + image.fileName"
          />
        </div>

        <div *ngIf="openModal" class="c-modal-crop">
          <div class="c-modal-crop__content">
            <div class="c-modal-crop__content-header">
              <p class="c-modal-crop__content-title">
                {{ 'modals.newAnimal_title' | translate }}
              </p>
              <div (click)="close()"><i class="fas fa-times"></i></div>
            </div>
            <div>
              <image-cropper
                class="image-cropper"
                *ngIf="showCropperTool"
                [imageChangedEvent]="imgChangeEvt"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="resizeToWidth"
                format="jpg"
                (imageCropped)="cropImg($event)"
                (imageLoaded)="imgLoad()"
                (cropperReady)="initCropper()"
                (loadImageFailed)="imgFailed()"
              >
              </image-cropper>
            </div>
            <div class="c-modal-crop__footer">
              <bindy-button
                text="{{ 'common.upload_photo' | translate }}"
                color="third"
                (onClick)="saveImage()"
              ></bindy-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
