<div class="login">
  <div class="login__overlay"></div>
  <div class="login__loginBox">
    <h2>{{ 'login.welcome' | translate }}</h2>
    <div class="login__userImage">
      <img
        decoding="async"
        src="/assets/img/logo120.png"
        alt="Orca Logo"
        title="Orca Logo"
      />
    </div>

    <div *ngIf="showLoading" class="login__loading">
      <img src="assets/img/loading.gif" alt="" />
    </div>

    <div *ngIf="!showLoading">
      <app-language-selector *ngIf="showLogin"></app-language-selector>
      <div *ngIf="showLogin">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="login__form">
            <div class="u-input">
              <bindy-input-text
                type="email"
                name="email"
                formControlName="email"
                label="{{ 'login.email_input' | translate }}"
                placeholder="{{ 'login.email_placeholder' | translate }}"
              ></bindy-input-text>
            </div>

            <div class="u-input">
              <bindy-input-text
                type="password"
                name="password"
                formControlName="password"
                label="{{ 'login.password_input' | translate }}"
                placeholder="{{ 'login.password_placeholder' | translate }}"
              ></bindy-input-text>
            </div>

            <div class="login__lbl_error" *ngIf="showErrorUserText">
              {{ 'login.user_wrong' | translate }}
            </div>

            <button
              class="login__button"
              type="submit"
              [disabled]="!loginForm.valid"
            >
              {{ 'common.button_send' | translate }}
            </button>
            <div class="login__forget">
              <div class="login__forget-lnk" (click)="recoverPassword()">
                {{ 'login.forget_link' | translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="showRecovery">
        <form [formGroup]="recoverForm" (ngSubmit)="onSubmitRecover()">
          <div class="login__form">
            <div class="login__recover-text">
              {{ 'login.recover_text' | translate }}
            </div>
            <div class="u-input">
              <bindy-input-text
                type="email"
                name="email"
                formControlName="email"
                label="{{ 'login.email_input' | translate }}"
                placeholder="{{ 'login.email_placeholder' | translate }}"
              ></bindy-input-text>
              <ng-container *ngIf="inputValidation('email')">
                <p
                  class="u-error"
                  *ngIf="recoverForm.get('email').errors?.required"
                >
                  {{ 'common.mandatoryField' | translate }}
                </p>
                <p
                  class="u-error"
                  *ngIf="recoverForm.get('email').errors?.email"
                >
                  {{ 'myProfile.email_wrong' | translate }}
                </p>
              </ng-container>
            </div>
            <button
              class="login__button"
              type="submit"
              [disabled]="!loginForm.valid"
            >
              {{ 'common.button_send' | translate }}
            </button>
            <div class="login__forget">
              <div class="login__forget-lnk" (click)="backToLogin()">
                {{ 'login.forget_back' | translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="showEmailSent">
        <div class="login__email-sent" *ngIf="!showEmailError">
          {{ 'login.email_sent' | translate }}
        </div>
        <div class="login__email-error" *ngIf="showEmailError">
          {{ 'login.email_error' | translate }}
        </div>
        <div class="login__forget">
          <div class="login__forget-lnk" (click)="backToLogin()">
            {{ 'login.forget_back' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
