export enum LocalizationType {
  UNKNOWN = 0,
  HOUSE = 1,
  COLONY = 2,
  SHELTER = 3
}

export const LocalizationTypeLabels: Record<LocalizationType, string> = {
  [LocalizationType.UNKNOWN]: 'common.localizationType_unknown',
  [LocalizationType.HOUSE]: 'common.localizationType_house',
  [LocalizationType.COLONY]: 'common.localizationType_colony',
  [LocalizationType.SHELTER]: 'common.localizationType_shelter'
};
