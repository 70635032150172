import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IUser } from 'src/app/interfaces/user';
import { IUsersFilters } from 'src/app/interfaces/filters/usersFilters';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { IUserProfile } from '../interfaces/user-profile';
import { UserProfileService } from './user-profile.service';
import { IUserFirstTime } from '../interfaces/userFirstTime.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = SETTINGS.APIRUL + 'user/';

  public suplantedUser: IUserProfile = null;

  constructor(
    private httpClient: HttpClient,
    private userProfileService: UserProfileService
  ) {}

  setFirstTimeTrue(userFirstTimeParams: IUserFirstTime) {
    return this.httpClient.post<boolean>(
      this.apiUrl + 'editUserFirstTime.php',
      userFirstTimeParams
    );
  }

  getUsersByFilters(filters: IUsersFilters): Observable<IUser[]> {
    return this.httpClient
      .post<IUser[]>(this.apiUrl + 'getUsersByFilters.php', filters)
      .pipe(
        map((data: IUser[]) => {
          return data;
        })
      );
  }

  getUserById(id: number): Observable<IUser> {
    const params = new HttpParams().set('userId', String(id));

    return this.httpClient
      .get<IUser>(this.apiUrl + 'getUserById.php', { params })
      .pipe(
        map((data: IUser) => {
          return data;
        })
      );
  }

  checkIfUserExistByEmail(email: string): Observable<boolean> {
    const params = new HttpParams().set('email', email);

    return this.httpClient
      .get<boolean>(this.apiUrl + 'checkUserByEmail.php', { params })
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }

  postUser(user: IUser): Observable<number> {
    return this.httpClient.post<number>(this.apiUrl + 'addUser.php', user).pipe(
      map((data: number) => {
        return data;
      })
    );
  }

  editUser(user: IUser): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'editUser.php', user)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  editUserProfile(user: IUser): Observable<boolean> {
    return this.httpClient
      .post<boolean>(this.apiUrl + 'editUserProfile.php', user)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }

  editUserPassword(user: IUser): Observable<boolean> {
    return this.httpClient
      .post<boolean>(this.apiUrl + 'editUserPassword.php', user)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }

  deleteUserById(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteUser.php?userId=' + id
    );
  }

  deleteUserImage(userId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteUserImage.php?userId=' + userId
    );
  }

  uploadUserImage(imageUpload: IImageUpload) {
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadUserImage.php',
      imageUpload,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  suplantIdentity(userId: number): Observable<IUserProfile> {
    this.setSuplantedUser(userId);
    const params = new HttpParams().set('userId', userId);

    return this.httpClient
      .get<IUserProfile>(this.apiUrl + 'suplantIdentity.php', { params })
      .pipe(
        map((data: IUserProfile) => {
          return data;
        })
      );
  }

  setSuplantedUser(userId: number): void {
    if (this.suplantedUser !== null && this.suplantedUser.id === userId) {
      this.deleteSuplantedUser();
    } else {
      this.suplantedUser = this.userProfileService.userProfile;
    }
  }

  deleteSuplantedUser(): void {
    this.suplantedUser = null;
  }
}
