<p class="u-input__text">
  {{ label }}
  <span *ngIf="required" class="u-required">{{
    'common.required' | translate
  }}</span>
</p>
<div class="u-input__content">
  <input
    [type]="type"
    [placeholder]="placeholder"
    [formControl]="inputName"
    [readonly]="disabled"
    [ngClass]="{ 'is-disabled': disabled }"
    class="u-input__input"
  />
</div>
