import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IControlPanelData } from 'src/app/interfaces/controlPanelData';
import { ControlPanelService } from 'src/app/services/controlPanel.service';

@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  getScreenWidth: any;

  logsLoading = true;
  logList: IControlPanelData[] = [];
  downloadControlLoading = true;
  downloadControlList: IControlPanelData[] = [];
  lastAnimalsLoading = true;
  lastAnimalsList: IControlPanelData[] = [];
  lastEventsLoading = true;
  lastEventsList: IControlPanelData[] = [];
  lastAnimalBillsLoading = true;
  lastAnimalBillsList: IControlPanelData[] = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(private controlPanelService: ControlPanelService) {}

  ngOnInit(): void {
    // this.setScreenSize();
    this.getLogs();
    this.getDownloadControl();
    this.getLastAnimals();
    this.getEvents();
    this.getAnimalBills();
  }

  // ngOnDestroy() {
  //   this._ngUnsuscribe.next();
  //   this._ngUnsuscribe.complete();
  // }

  // private setScreenSize() {
  //   this.getScreenWidth = window.innerWidth;
  //   if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
  //     this.stepperOrientation = 'vertical';
  //   }
  // }

  private getEvents(): void {
    this.controlPanelService
      .getEvents()
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.lastEventsLoading = false)))
      .subscribe((data) => {
        this.lastEventsList = data;
      });
  }

  private getLogs(): void {
    this.controlPanelService
      .getLogs()
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.logsLoading = false)))
      .subscribe((data) => {
        this.logList = data;
      });
  }

  private getDownloadControl(): void {
    this.controlPanelService
      .getDownloadControl()
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.downloadControlLoading = false)))
      .subscribe((data) => {
        this.downloadControlList = data;
      });
  }

  private getLastAnimals(): void {
    this.controlPanelService
      .getLastAnimals()
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.lastAnimalsLoading = false)))
      .subscribe((data) => {
        this.lastAnimalsList = data;
      });
  }

  private getAnimalBills(): void {
    this.controlPanelService
      .getAnimalsBills()
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.lastAnimalBillsLoading = false)))
      .subscribe((data) => {
        this.lastAnimalBillsList = data;
      });
  }
}
