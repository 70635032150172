<app-titlebar
  icon="fas fa-inbox"
  [title]="'titles.suggestion_detail' | translate"
  [parentTitle]="'titles.suggestion_list' | translate"
  parentPath="/suggestion-list"
></app-titlebar>
<div class="c-suggestion-detail">
  <div class="u-content">
    <div>
      <div *ngIf="!showLoading">
        <form [formGroup]="suggestionForm" (ngSubmit)="sendSuggestion()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-inbox"></i>
              {{ 'titles.suggestion_detail' | translate }}:
            </legend>
            <input type="text" formControlName="userId" hidden />
            <div class="u-filters__content u-filters__content--blocks">
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'suggestions.subject' | translate }}:
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="u-input__content">
                  <select
                    name=""
                    id=""
                    class="u-input__input"
                    formControlName="subject"
                  >
                    <option *ngFor="let subject of subjects" [value]="subject">
                      {{ subjectsLabels[subject] | translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="u-input">
                <p class="u-input__text">
                  {{ 'suggestions.messagge' | translate }}:
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="">
                  <textarea
                    type="text"
                    class="u-input__textarea"
                    id="message"
                    rows="10"
                    placeholder="{{ 'forms.writeSomething' | translate }}"
                    formControlName="message"
                    [class.is-invalid]="suggestionForm.get('message').touched"
                  ></textarea>
                </div>
                <ng-container *ngIf="inputValidation('message')">
                  <p
                    class="u-error"
                    *ngIf="suggestionForm.get('message').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="u-detail__button c-suggestion-detail__buttons">
                <div class="u-detail__button-left">
                  <div *ngIf="!isNew">
                    <bindy-button
                      text="{{ 'common.button_delete' | translate }}"
                      icon="fa-trash-alt"
                      color="danger"
                      (onClick)="deleteSuggestion()"
                    ></bindy-button>
                  </div>
                </div>
                <div class="c-suggestion-detail__buttons-actions" *ngIf="isNew">
                  <bindy-button
                    text="{{ 'common.button_send' | translate }}"
                    icon="fa-save"
                    color="secondary"
                    type="submit"
                  ></bindy-button>
                </div>
                <div
                  class="c-suggestion-detail__buttons-actions"
                  *ngIf="!isNew"
                >
                  <bindy-button
                    text="{{ 'common.button_working' | translate }}"
                    color="secondary"
                    (onClick)="changeStatus(statuses.WORKING)"
                  ></bindy-button>
                  <bindy-button
                    text="{{ 'common.button_notDone' | translate }}"
                    color="third"
                    (onClick)="changeStatus(statuses.NOT_DONE)"
                  ></bindy-button>
                  <bindy-button
                    text="{{ 'common.button_done' | translate }}"
                    color="success"
                    (onClick)="changeStatus(statuses.DONE)"
                  ></bindy-button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <app-loading *ngIf="showLoading"></app-loading>
  </div>
</div>
