import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { Languages } from './interfaces/enums/languages';
import { TranslateLocalService } from './services/translate-local.service';
import { SETTINGS } from './SETTINGS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'orca-app';

  constructor(
    private userProfileService: UserProfileService,
    private translateService: TranslateService,
    private translateLocalService: TranslateLocalService
  ) {}

  ngOnInit() {
    this.userProfileService.checkUserStorage();
    this.configureLanguage();
  }

  configureLanguage(): void {
    const languages = Object.values(Languages);

    this.translateService.addLangs(languages);
    this.translateService.setDefaultLang(SETTINGS.LANGUAGE_DEFAULT);

    this.translateLocalService.initLanguageCookie();
  }
}
