import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { ProfileLabels, Profiles } from 'src/app/interfaces/enums/profiles';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserService } from 'src/app/services/user.service';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  constructor(
    private userProfileService: UserProfileService,
    private userService: UserService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  isMenuVisible = false;
  profileLabels = ProfileLabels;
  settings = SETTINGS;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.userData;
  }

  tenantImage(): string {
    return `${this.settings.TENANT_IMAGE_PATH}${this.userProfileTenantImage}`;
  }

  presentMenu(e) {
    const htmlTag = document.getElementById('html');
    this.isMenuVisible = !this.isMenuVisible;
    if (this.isMenuVisible) {
      document.body.classList.add('overflow-hidden');
      htmlTag.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      this.removeOverflowHidden();
    }
  }

  closeMenu() {
    document.body.classList.remove('overflow-hidden');
    this.isMenuVisible = false;
    this.removeOverflowHidden();
  }

  userImage(image: string): string {
    return SETTINGS.USERS_IMAGE_PATH + image;
  }

  get userProfileName(): string {
    return `${this.userProfileService.userProfile.name} ${this.userProfileService.userProfile.surName}`;
  }

  get userProfileProfile(): Profiles {
    return this.userProfileService.userProfile.profileId;
  }

  get userProfileTenantName(): string {
    return this.userProfileService.userProfile.tenantName;
  }

  get userProfileTenantImage(): string {
    return this.userProfileService.userProfile.tenantImage;
  }

  get userData(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  get activateUserMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD, Profiles.ADMIN];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateLocalizationMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD, Profiles.ADMIN];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateSuggestionListMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateAddSuggestionMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.ADMIN, Profiles.CONTRIBUTOR];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateNewsAdminMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateTenantSpeciesMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD, Profiles.ADMIN];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateTenantAdminMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateControlMenuMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  get activateUserSuplantMenu(): boolean {
    const profilesAdmmited: number[] = [Profiles.GOD];
    return this.checkIfProfileIsInIncoming(profilesAdmmited);
  }

  // get activateEventsMenu(): boolean {
  //   const profilesAdmmited: number[] = [Profiles.GOD, Profiles.ADMIN];
  //   return this.checkIfProfileIsInIncoming(profilesAdmmited);
  // }

  get suplantingUser(): boolean {
    return this.userService.suplantedUser !== null;
  }

  suplantUserImage(): string {
    return this.suplantingUser
      ? SETTINGS.USERS_IMAGE_PATH + this.userService.suplantedUser.image
      : '';
  }

  returnToSuplantedUser(): void {
    const id = this.userService.suplantedUser.id;
    this.userService
      .suplantIdentity(id)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe()
      .subscribe((data: IUserProfile) => {
        this.userProfileService.loginSuccess(data);
        this.showSuccessToast('toast.USER_SUPLANT_SUCCESS');
        this.router.navigate([`dashboard`]);
      });
  }

  logout() {
    this.userService.deleteSuplantedUser();
    this.userProfileService.logout();
  }

  checkIfProfileIsInIncoming(profiles: number[]): boolean {
    const userProfile = Number(this.userProfileService.userProfile.profileId);
    return profiles.indexOf(userProfile) !== -1;
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }

  private removeOverflowHidden() {
    const htmlTag = document.getElementById('html');
    htmlTag.classList.remove('overflow-hidden');
  }
}
