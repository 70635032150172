<app-titlebar
  icon="fas fa-paw"
  [title]="('titles.animal_detail' | translate) + animalDetailForm.value.name"
  [parentTitle]="'titles.animal_list' | translate"
  parentPath="/animal-list"
></app-titlebar>

<div class="c-animal-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <form
      class="c-animal-detail__form"
      [formGroup]="animalDetailForm"
      (ngSubmit)="sendAnimal()"
    >
      <app-loading *ngIf="showLoading"></app-loading>

      <div *ngIf="!showLoading" class="c-animal-detail__wrapper">
        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-camera"></i> {{ 'titles.photo' | translate }}
          </legend>
          <bindy-file-upload-multiple
            [images]="imagesArray"
            [openModal]="openModal"
            [animalId]="animalId"
            (imageUploaded)="saveImage($event)"
            (onDelete)="removeImage($event)"
          ></bindy-file-upload-multiple>
        </fieldset>

        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-paw"></i> {{ 'titles.generalData' | translate }}
          </legend>
          <div class="c-animal-detail__fieldset-content">
            <div class="u-input">
              <bindy-input-text
                formControlName="name"
                label="{{ 'forms.name' | translate }}"
                placeholder="{{ 'forms.name_placeholder' | translate }}"
                required="true"
              ></bindy-input-text>
              <ng-container *ngIf="inputValidation('name')">
                <p
                  class="u-error"
                  *ngIf="animalDetailForm.get('name').errors?.required"
                >
                  {{ 'common.mandatoryField' | translate }}
                </p>
              </ng-container>
            </div>
            <div class="u-input u-input--select">
              <p class="u-input__text">
                {{ 'forms.specie' | translate }}
                <span class="u-required">{{
                  'common.required' | translate
                }}</span>
              </p>
              <div class="u-input__content">
                <mat-select class="u-input__input" formControlName="specieId">
                  <mat-option value="">{{
                    'forms.select' | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let specie of species"
                    [value]="specie.id"
                  >
                    {{ specie.literal | translate }}
                  </mat-option>
                </mat-select>
                <ng-container *ngIf="inputValidation('specieId')">
                  <p
                    class="u-error"
                    *ngIf="animalDetailForm.get('specieId').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="u-input u-input--select">
              <p class="u-input__text">
                {{ 'forms.gender' | translate }}
                <span class="u-required">{{
                  'common.required' | translate
                }}</span>
              </p>
              <div class="u-input__content">
                <mat-select class="u-input__input" formControlName="genderId">
                  <mat-option value="">
                    {{ 'forms.select' | translate }}
                  </mat-option>
                  <mat-option *ngFor="let gender of genders" [value]="gender">
                    {{ genderLabels[gender] | translate }}
                  </mat-option>
                </mat-select>
                <ng-container *ngIf="inputValidation('genderId')">
                  <p
                    class="u-error"
                    *ngIf="animalDetailForm.get('genderId').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="u-input">
              <bindy-input-text
                formControlName="subspecie"
                label="{{ 'forms.subSpecie' | translate }}"
                placeholder="{{ 'forms.subSpecie_placeholder' | translate }}"
              ></bindy-input-text>
            </div>
            <div class="u-input">
              <bindy-input-text
                formControlName="breed"
                label="{{ 'forms.breed' | translate }}"
                placeholder="{{ 'forms.breed_placeholder' | translate }}"
              ></bindy-input-text>
            </div>
            <div class="u-input">
              <bindy-input-text
                formControlName="fur"
                label="{{ 'forms.fur' | translate }}"
                placeholder="{{ 'forms.fur_placeholder' | translate }}"
              ></bindy-input-text>
            </div>
            <div class="u-input">
              <bindy-input-text
                formControlName="chip"
                label="{{ 'forms.chip' | translate }}"
                placeholder="{{ 'forms.chip_placeholder' | translate }}"
              ></bindy-input-text>
            </div>
            <div class="u-input u-input--select u-relative">
              <p class="u-input__text">
                {{ 'forms.localization' | translate }}
                <i
                  class="u-input__tooltip fas fa-info-circle"
                  (click)="openTooltip(1, 'animals.tooltip_localization')"
                ></i>
              </p>
              <div
                *ngIf="showBottomModalLocalization"
                class="bottom-sheet-wrapper"
                [ngClass]="showBottomModalLocalization ? 'show-modal' : ''"
              >
                <div class="backdrop"></div>
                <div class="bottom-sheet">
                  <div class="close center" (click)="closeModal()">
                    <p>Info:</p>
                    <i class="fas fa-times"></i>
                  </div>
                  <p>{{ modalInfoText }}</p>
                </div>
              </div>

              <div class="u-input__content">
                <mat-select
                  class="u-input__input"
                  formControlName="localizationId"
                >
                  <mat-option [value]="0">{{
                    'forms.noLocalization' | translate
                  }}</mat-option>
                  <mat-option
                    *ngFor="let localization of localizations"
                    [value]="localization.id"
                  >
                    {{ localization.name }}
                  </mat-option>
                </mat-select>
              </div>
            </div>
            <div class="u-input">
              <p class="u-input__text">{{ 'forms.birthDate' | translate }}</p>
              <div class="u-input__content u-input__content--icon">
                <input
                  [matDatepicker]="birthDateDatepicker"
                  format="yyyy-MM-dd"
                  placeholder="{{ 'forms.birthDate' | translate }}"
                  class="u-input__input"
                  formControlName="birthDate"
                  (click)="birthDateDatepicker.open()"
                />
                <mat-datepicker-toggle
                  [for]="birthDateDatepicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #birthDateDatepicker></mat-datepicker>
              </div>
            </div>
            <div class="u-input u-relative">
              <p class="u-input__text">
                {{ 'forms.adoptionDate' | translate }}
                <i
                  *ngIf="tenantHasWeb"
                  class="u-input__tooltip fas fa-info-circle"
                  (click)="openTooltip(2, 'animals.tooltip_dateInput')"
                ></i>
              </p>
              <div
                *ngIf="showBottomModalAdoptionDate"
                class="bottom-sheet-wrapper"
                [ngClass]="showBottomModalAdoptionDate ? 'show-modal' : ''"
              >
                <div class="backdrop"></div>
                <div class="bottom-sheet">
                  <div class="close center" (click)="closeModal()">
                    <p>Info:</p>
                    <i class="fas fa-times"></i>
                  </div>
                  <p>{{ modalInfoText }}</p>
                </div>
              </div>
              <div class="u-input__content u-input__content--icon">
                <input
                  [matDatepicker]="adoptionDate"
                  format="yyyy-MM-dd"
                  placeholder="{{ 'forms.adoptionDate' | translate }}"
                  class="u-input__input"
                  formControlName="adoptionDate"
                  (click)="adoptionDate.open()"
                />
                <mat-datepicker-toggle
                  [for]="adoptionDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #adoptionDate></mat-datepicker>
              </div>
            </div>
            <div class="u-input u-relative">
              <p class="u-input__text">
                {{ 'forms.passAwayDate' | translate }}
                <i
                  *ngIf="tenantHasWeb"
                  class="u-input__tooltip fas fa-info-circle"
                  (click)="openTooltip(3, 'animals.tooltip_passAway')"
                ></i>
              </p>
              <div
                *ngIf="showBottomModalPassAwayDate"
                class="bottom-sheet-wrapper"
                [ngClass]="showBottomModalPassAwayDate ? 'show-modal' : ''"
              >
                <div class="backdrop"></div>
                <div class="bottom-sheet">
                  <div class="close center" (click)="closeModal()">
                    <p>Info:</p>
                    <i class="fas fa-times"></i>
                  </div>
                  <p>{{ modalInfoText }}</p>
                </div>
              </div>
              <div class="u-input__content u-input__content--icon">
                <input
                  [matDatepicker]="passAwayDate"
                  format="yyyy-MM-dd"
                  placeholder="{{ 'forms.passAwayDate' | translate }}"
                  class="u-input__input"
                  formControlName="passAwayDate"
                  (click)="passAwayDate.open()"
                />
                <mat-datepicker-toggle
                  [for]="passAwayDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #passAwayDate></mat-datepicker>
              </div>
            </div>
            <div class="u-input">
              <bindy-input-text
                formControlName="creationDate"
                label="{{ 'forms.creationDate' | translate }}"
                placeholder="{{ 'forms.creationDate' | translate }}"
                disabled="true"
              ></bindy-input-text>
            </div>
          </div>
        </fieldset>

        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-stethoscope"></i> {{ 'titles.bills' | translate }}
          </legend>

          <div *ngIf="showBillsLoading" class="c-animal-detail__bill-loading">
            <img src="assets/img/loading.gif" alt="" />
          </div>
          <div *ngIf="!showBillsLoading">
            <div *ngIf="!showBillsForm">
              <div *ngIf="bills.length > 0" class="c-animal-detail__bill-total">
                Total: {{ billsTotal | number : '.2' : 'es-ES' }} €
              </div>
              <div
                *ngFor="let bill of bills"
                class="c-animal-detail__bill-block"
              >
                <div class="c-animal-detail__bill-data">
                  <div>
                    <div class="c-animal-detail__bill-date">
                      {{ bill.billDate | date : 'dd/MM/yyyy' }}
                    </div>
                    <div class="c-animal-detail__bill-title">
                      {{ bill.title }}
                    </div>
                  </div>
                  <div class="c-animal-detail__bill-price">
                    {{ bill.price | number : '.2' : 'es-ES' }} €
                  </div>
                  <div
                    class="c-animal-detail__bill-delete"
                    alt="{{ 'common.button_delete' | translate }}"
                    title="{{ 'common.button_delete' | translate }}"
                    (click)="deleteBill(bill.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </div>
                </div>
                <div
                  *ngIf="bill.description && bill.showDescription"
                  class="c-animal-detail__bill-info"
                >
                  <pre>{{ bill.description }}</pre>
                </div>
                <div
                  *ngIf="bill.description && bill.showDescription"
                  class="c-animal-detail__bill-show-more"
                  (click)="showHideDescription(bill, false)"
                >
                  &#8648; {{ 'animals.hidedescription' | translate }} &#8648;
                </div>
                <div
                  *ngIf="bill.description && !bill.showDescription"
                  class="c-animal-detail__bill-show-more"
                  (click)="showHideDescription(bill, true)"
                >
                  &#8650; {{ 'animals.showdescription' | translate }} &#8650;
                </div>
              </div>
            </div>
            <div *ngIf="bills.length === 0 && !showBillsForm">
              <p>{{ 'animals.noBillsText' | translate }}</p>
            </div>

            <div class="c-animal-detail__bill-buttons" *ngIf="!showBillsForm">
              <bindy-button
                text="{{ 'common.button_addBill' | translate }}"
                icon="fa-plus"
                color="third"
                (onClick)="showNewBillForm()"
              ></bindy-button>
            </div>

            <div *ngIf="showBillsForm">
              <div>{{ 'animals.billFormText' | translate }}</div>
              <form
                class="c-animal-detail__form"
                [formGroup]="billForm"
                (ngSubmit)="sendBill()"
              >
                <div class="c-animal-detail__bill-fieldset-content">
                  <div class="u-input">
                    <p class="u-input__text">
                      {{ 'forms.billDate' | translate }}
                      <span class="u-required">{{
                        'common.required' | translate
                      }}</span>
                    </p>
                    <div class="u-input__content u-input__content--icon">
                      <input
                        [matDatepicker]="billDate"
                        format="yyyy-MM-dd"
                        placeholder="{{ 'forms.billDate' | translate }}"
                        class="u-input__input"
                        formControlName="billDate"
                        (click)="billDate.open()"
                      />
                      <mat-datepicker-toggle
                        [for]="billDate"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #billDate></mat-datepicker>
                    </div>
                    <ng-container *ngIf="billInputValidation('billDate')">
                      <p
                        class="u-error"
                        *ngIf="billForm.get('billDate').errors?.required"
                      >
                        {{ 'common.mandatoryField' | translate }}
                      </p>
                    </ng-container>
                  </div>

                  <div class="u-input u-input--select">
                    <p class="u-input__text">
                      {{ 'forms.billType' | translate }}
                    </p>
                    <div class="u-input__content">
                      <mat-select
                        class="u-input__input"
                        formControlName="billType"
                      >
                        <mat-option
                          *ngFor="let type of billTypes"
                          [value]="type"
                        >
                          {{ billLabels[type] | translate }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>

                  <div class="u-input">
                    <p class="u-input__text">
                      {{ 'forms.price' | translate }} (€)
                    </p>
                    <div class="u-input__content">
                      <input
                        type="number"
                        formControlName="price"
                        class="u-input__input"
                        (keypress)="checkForDecimalKeys($event)"
                        (click)="$event.target.select()"
                      />
                    </div>
                  </div>
                </div>
                <div class="u-input">
                  <bindy-input-text
                    formControlName="title"
                    label="{{ 'forms.title' | translate }}"
                    placeholder="{{ 'forms.title_placeholder' | translate }}"
                    required="true"
                  ></bindy-input-text>
                  <ng-container *ngIf="billInputValidation('title')">
                    <p
                      class="u-error"
                      *ngIf="billForm.get('title').errors?.required"
                    >
                      {{ 'common.mandatoryField' | translate }}
                    </p>
                  </ng-container>
                </div>

                <div class="u-input c-animal-detail__bill-textarea-input">
                  <p class="u-input__text">
                    {{ 'forms.billDesription' | translate }}
                  </p>
                  <div class="">
                    <textarea
                      type="text"
                      class="u-input__textarea"
                      rows="2"
                      placeholder="{{ 'forms.writeSomething' | translate }}"
                      formControlName="description"
                    ></textarea>
                  </div>
                </div>
                <div class="c-animal-detail__bill-buttons">
                  <bindy-button
                    text="{{ 'common.button_cancel' | translate }}"
                    color="secondary"
                    (onClick)="closeNewBillForm()"
                  ></bindy-button>
                  <bindy-button
                    text="{{ 'common.button_saveBill' | translate }}"
                    icon="fa-save"
                    color="success"
                    (onClick)="addNewBill()"
                  ></bindy-button>
                </div>
              </form>
            </div>
          </div>
        </fieldset>

        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-plus-circle"></i>
            {{ 'titles.moreData' | translate }}
          </legend>
          <div class="c-animal-detail__fieldset-content">
            <div class="u-input">
              <p class="u-input__text"></p>
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.esterilized' | translate }}
                <input type="checkbox" formControlName="isSterilized" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
            <div class="u-input">
              <p class="u-input__text"></p>
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.vaccinated' | translate }}
                <input type="checkbox" formControlName="isVaccinated" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
            <div class="u-input" *ngIf="showPPPLicense">
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.neddLicensePPP' | translate }}
                <input type="checkbox" formControlName="needLicense" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset class="u-fieldset" *ngIf="tenantHasWeb">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-columns"></i>
            {{ 'titles.webVisualization' | translate }}
          </legend>
          <div class="c-animal-detail__fieldset-content">
            <div class="u-input">
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.showInAdpotions' | translate }}
                <input type="checkbox" formControlName="showInAdoptionPage" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
            <div class="u-input" *ngIf="showAdoptedPage">
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.showInAdopted' | translate }}
                <input type="checkbox" formControlName="showInAdoptedPage" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
            <div class="u-input" *ngIf="showSponsorPage">
              <label class="u-input__checkbox u-input__text"
                >{{ 'forms.showInSponsor' | translate }}
                <input type="checkbox" formControlName="showInSponsorPage" />
                <span class="u-input__checkbox-checkmark"></span>
              </label>
            </div>
          </div>
        </fieldset>

        <fieldset class="u-fieldset c-animal-detail__textareas">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-prescription-bottle"></i>
            {{ 'titles.optionalData' | translate }}
          </legend>
          <div>
            <p>{{ 'animals.visible_in_webpage' | translate }}</p>
          </div>
          <div class="c-animal-detail__fieldset-content-others">
            <div class="u-input">
              <bindy-input-text
                formControlName="link"
                label="{{ 'forms.link' | translate }}"
                placeholder="{{ 'forms.link_placeholder' | translate }}"
              ></bindy-input-text>
            </div>

            <div class="u-input" *ngIf="tenantHasWeb">
              <p class="u-input__text">
                {{ 'forms.contactInfo' | translate }}
                {{ 'forms.optionalContactInfo' | translate }}
              </p>
              <div class="">
                <textarea
                  type="text"
                  class="u-input__textarea"
                  id="contactInfo"
                  rows="2"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                  formControlName="contactInfo"
                ></textarea>
              </div>
            </div>

            <div class="u-input">
              <p class="u-input__text">
                {{ 'forms.history' | translate }}
                {{ 'forms.optional' | translate }}
              </p>
              <div class="">
                <textarea
                  type="text"
                  class="u-input__textarea"
                  id="story"
                  rows="2"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                  formControlName="story"
                ></textarea>
              </div>
            </div>
            <div class="u-input">
              <p class="u-input__text">
                {{ 'forms.behaviour' | translate }}
                {{ 'forms.optional' | translate }}
              </p>
              <div class="">
                <textarea
                  type="text"
                  class="u-input__textarea"
                  id="behaviour"
                  rows="2"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                  formControlName="behaviour"
                ></textarea>
              </div>
            </div>
            <div class="u-input">
              <p class="u-input__text">
                {{ 'forms.compatibleWith' | translate }}
                {{ 'forms.optional' | translate }}
              </p>
              <div class="">
                <textarea
                  type="text"
                  class="u-input__textarea"
                  id="compatibleWith"
                  rows="2"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                  formControlName="compatibleWith"
                ></textarea>
              </div>
            </div>
            <div class="u-input">
              <p class="u-input__text">
                {{ 'forms.health' | translate }}
                {{ 'forms.optional' | translate }}
              </p>
              <div class="">
                <textarea
                  type="text"
                  class="u-input__textarea"
                  id="health"
                  rows="2"
                  placeholder="{{ 'forms.writeSomething' | translate }}"
                  formControlName="health"
                ></textarea>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="u-fieldset c-animal-detail__textareas">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-prescription-bottle"></i>
            {{ 'titles.otherData' | translate }}
          </legend>
          <div class="u-input">
            <p class="u-input__text">
              {{ 'forms.extraInformation' | translate }}
            </p>
            <div class="">
              <textarea
                type="text"
                class="u-input__textarea"
                id="extraInformation"
                rows="2"
                placeholder="{{ 'forms.writeSomething' | translate }}"
                formControlName="extraInformation"
              ></textarea>
            </div>
            <div class="u-detail__button">
              <div class="u-detail__button-left">
                <bindy-button
                  text="{{ 'common.button_delete' | translate }}"
                  icon="fa-trash-alt"
                  color="danger"
                  (onClick)="deleteAnimal()"
                ></bindy-button>
              </div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
</div>
