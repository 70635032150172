<app-titlebar
  [title]="'titles.controlPanel' | translate"
  icon="fas fa-solar-panel"
></app-titlebar>
<div class="control-panel__content">
  <div class="control-panel__columns">
    <div class="control-panel__block">
      <div class="control-panel__block-title">
        {{ 'controlPanel.lastLogs' | translate }}
      </div>

      <app-loading *ngIf="logsLoading"></app-loading>

      <div *ngIf="!logsLoading" class="u-table">
        <div class="hidden-xs">
          <div class="u-table__header control-panel-table-header hidden-xs">
            <div>{{ 'controlPanel.userName' | translate }}</div>
            <div>{{ 'controlPanel.date' | translate }}</div>
            <div>{{ 'controlPanel.tenant' | translate }}</div>
          </div>
        </div>
        <div *ngIf="logList.length > 0">
          <div
            class="u-table__data control-panel-table-data"
            *ngFor="let log of logList"
          >
            <div>{{ log.userName }}</div>
            <div>{{ log.logDate | date }}</div>
            <div>{{ log.tenantName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="control-panel__block">
      <div class="control-panel__block-title">
        {{ 'controlPanel.lastAnimalsUploads' | translate }}
      </div>

      <app-loading *ngIf="lastAnimalsLoading"></app-loading>

      <div *ngIf="!lastAnimalsLoading" class="u-table">
        <div class="hidden-xs">
          <div class="u-table__header control-panel-table-header hidden-xs">
            <div>{{ 'controlPanel.animalName' | translate }}</div>
            <div>{{ 'controlPanel.date' | translate }}</div>
            <div>{{ 'controlPanel.tenant' | translate }}</div>
          </div>
        </div>
        <div *ngIf="lastAnimalsList.length > 0">
          <div
            class="u-table__data control-panel-table-data"
            *ngFor="let animalLog of lastAnimalsList"
          >
            <div>{{ animalLog.animalName }}</div>
            <div>{{ animalLog.logDate | date }}</div>
            <div>{{ animalLog.tenantName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="control-panel__block">
      <div class="control-panel__block-title">
        {{ 'controlPanel.lastAnimalBills' | translate }}
      </div>
      <app-loading *ngIf="lastAnimalBillsLoading"></app-loading>
      <div *ngIf="!lastAnimalBillsLoading" class="u-table">
        <div *ngIf="lastAnimalBillsList.length > 0">
          <div
            class="control-panel__bills-data"
            *ngFor="let event of lastAnimalBillsList"
          >
            <div class="control-panel__bills-top">
              <div class="control-panel__bills-tenant">
                {{ event.tenantName }}
              </div>
              <div>{{ event.animalName }}</div>
              <div class="control-panel__bills-date">
                {{ event.creationDate | date }}
              </div>
            </div>
            <div class="control-panel__bills-bottom">
              <div class="control-panel__bills-title">{{ event.title }}</div>
              <div class="control-panel__bills-price">
                {{ event.price | number : '.2' : 'es-ES' }} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="control-panel__block">
      <div class="control-panel__block-title">
        {{ 'controlPanel.lastEventsCreated' | translate }}
      </div>
      <app-loading *ngIf="lastEventsLoading"></app-loading>

      <div *ngIf="!lastEventsLoading" class="u-table">
        <div class="hidden-xs">
          <div class="u-table__header control-panel-table-header-4 hidden-xs">
            <div>{{ 'controlPanel.userName' | translate }}</div>
            <div>{{ 'controlPanel.title' | translate }}</div>
            <div>{{ 'controlPanel.date' | translate }}</div>
            <div>{{ 'controlPanel.tenant' | translate }}</div>
          </div>
        </div>
        <div *ngIf="lastEventsList.length > 0">
          <div
            class="u-table__data control-panel-table-data-4"
            *ngFor="let event of lastEventsList"
          >
            <div>{{ event.userName }}</div>
            <div>{{ event.title }}</div>
            <div>{{ event.creationDate | date }}</div>
            <div>{{ event.tenantName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="control-panel__block">
      <div class="control-panel__block-title">
        {{ 'controlPanel.lastDownloads' | translate }}
      </div>
      <app-loading *ngIf="downloadControlLoading"></app-loading>
      <div *ngIf="!downloadControlLoading" class="u-table">
        <div class="hidden-xs">
          <div class="u-table__header control-panel-table-header hidden-xs">
            <div>{{ 'controlPanel.userName' | translate }}</div>
            <div>{{ 'controlPanel.downloadName' | translate }}</div>
            <div>{{ 'controlPanel.date' | translate }}</div>
          </div>
        </div>
        <div *ngIf="downloadControlList.length > 0">
          <div
            class="u-table__data control-panel-table-data"
            *ngFor="let download of downloadControlList"
          >
            <div>{{ download.userName }}</div>
            <div>{{ download.downloadName }}</div>
            <div>{{ download.creationDate | date }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
