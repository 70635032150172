import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bindy-confirm',
  templateUrl: './bindy-confirm.component.html',
  styleUrls: ['./bindy-confirm.component.scss']
})
export class BindyConfirmComponent {
  constructor(
    public dialog: MatDialogRef<BindyConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) {}

  closeDialog(): void {
    this.dialog.close(false);
  }
  confirm(): void {
    this.dialog.close(true);
  }
}
