import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/interfaces/user';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { ICountry } from 'src/app/interfaces/country';
import { MasterDataService } from 'src/app/services/master-data.service';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { SETTINGS } from 'src/app/SETTINGS';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  userForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  user: IUser;

  showImageUploader = false;
  uploadProgress: number;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();
  private _userImage;

  constructor(
    private userService: UserService,
    private userProfileService: UserProfileService,
    private masterDataService: MasterDataService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {}

  get countries(): Array<ICountry> {
    return this.masterDataService.countries;
  }

  get userProfile(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  get userId(): number {
    return this.userProfile.id;
  }

  get userImage(): string {
    return this.userProfile.image;
  }

  ngOnInit(): void {
    this.initForm();
    this.getUserData();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  sendUser() {
    if (!this.userForm.valid) {
      markFormGroupTouched(this.userForm);
      return;
    }
    this.showLoading = true;
    forkJoin({
      userData: this.userService.editUser(this.userForm?.value),
      userImg: this.userService.uploadUserImage(this._userImage)
    })
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.getUserData(true);
          this.showSuccessToast('toast.PROFILE_EDIT_SUCCESS');
          this.userProfileService.updateUserProfileData(this.userForm?.value);
        })
      )
      .subscribe();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.userForm, inputName);
  }

  inputValidationPassword(inputName: string): boolean {
    return inputValidationUtil(this.passwordForm, inputName);
  }

  setUserImage(imageUpload: IImageUpload) {
    this._userImage = imageUpload;
  }

  getUserImage(): string {
    if (!this.userImage) {
      return '';
    }
    return SETTINGS.USERS_IMAGE_PATH + this.userImage;
  }

  deleteUserImage(): void {
    this.userService
      .deleteUserImage(this.userId)
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.IMAGE_DELETE_SUCCESS');
          this.userProfileService.updateUserProfileImage(null);
        })
      )
      .subscribe();
  }

  sendChangePassword(): void {
    if (!this.passwordForm.valid) {
      markFormGroupTouched(this.passwordForm);
      return;
    }
    if (
      this.passwordForm.get('password').value !==
      this.passwordForm.get('repeatPassword').value
    ) {
      return;
    }

    this.showLoading = true;

    this.userService
      .editUserPassword(this.passwordForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.initForm();
          this.getUserData(true);
          this.showSuccessToast('toast.PASSWORD_EDIT_SUCCESS');
        })
      )
      .subscribe();
  }

  private getUserData(fromUploadImage = false) {
    this.showLoading = true;
    this.userService
      .getUserById(this.userId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.userForm.patchValue(data[0]);
        if (fromUploadImage) {
          this.userProfileService.updateUserProfileImage(data[0].image);
        }
      });
  }

  private initForm() {
    this.userForm = this.fb.group({
      id: [this.userId],
      name: [this.userProfile.name, Validators.required],
      surName: [this.userProfile.surName, Validators.required],
      email: [this.userProfile.email, [Validators.required, Validators.email]],
      countryId: [this.userProfile.countryId, Validators.required],
      address: [this.userProfile.address],
      city: [this.userProfile.address],
      phone: [this.userProfile.phone]
    });
    this.passwordForm = this.fb.group({
      id: [this.userId],
      password: ['', [Validators.required, Validators.minLength(8)]],
      repeatPassword: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
