<app-titlebar
  icon="fas fa-newspaper"
  [title]="titleText | translate"
  [parentTitle]="'titles.newsList' | translate"
  parentPath="/news-list"
></app-titlebar>

<div *ngIf="!showLoading" class="c-news-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div>
      <div>
        <form
          [formGroup]="newsForm"
          (ngSubmit)="sendNews()"
          class="c-news__cont"
        >
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-newspaper"></i>
              {{ 'titles.newsDetail' | translate }}:
            </legend>
            <div class="u-filters__content u-filters__content--blocks">
              <input type="hidden" formControlName="id" />

              <div class="u-input">
                <bindy-input-text
                  formControlName="title"
                  label="{{ 'forms.title' | translate }}"
                  placeholder="{{ 'forms.title_placeholder' | translate }}"
                  required="true"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('title')">
                  <p
                    class="u-error"
                    *ngIf="newsForm.get('title').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="subtitle"
                  label="{{ 'forms.subtitle' | translate }}"
                  placeholder="{{ 'forms.subtitle_placeholder' | translate }}"
                ></bindy-input-text>
              </div>

              <div class="u-input">
                <p class="u-input__text">
                  {{ 'news.information' | translate }}:
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="">
                  <textarea
                    type="text"
                    class="u-input__textarea"
                    id="info"
                    rows="10"
                    placeholder="{{ 'forms.writeSomething' | translate }}"
                    formControlName="info"
                    [class.is-invalid]="newsForm.get('info').touched"
                  ></textarea>
                </div>
                <ng-container *ngIf="inputValidation('info')">
                  <p
                    class="u-error"
                    *ngIf="newsForm.get('info').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="u-detail__button">
              <div class="u-detail__button-left c-news-detail__buttons-actions">
                <bindy-button
                  *ngIf="showDeleteButton"
                  text="{{ 'common.button_delete' | translate }}"
                  icon="fa-trash-alt"
                  color="danger"
                  (onClick)="deleteNews()"
                ></bindy-button>
                <bindy-button
                  *ngIf="showNewsBool"
                  text="{{ 'common.button_hide' | translate }}"
                  icon="fa-eye-slash"
                  color="third"
                  (onClick)="hideNews()"
                ></bindy-button>
                <bindy-button
                  *ngIf="!showNewsBool"
                  text="{{ 'common.button_show' | translate }}"
                  icon="fa-eye"
                  color="third"
                  (onClick)="showNews()"
                ></bindy-button>
                <bindy-button
                  *ngIf="showMarkDashboardButton"
                  text="{{ 'common.button_newsOnDashboard' | translate }}"
                  icon="fa-columns"
                  color="third"
                  (onClick)="markNewsAsDashboard()"
                ></bindy-button>
              </div>
              <div>
                <bindy-button
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-camera"></i> {{ 'news.image' | translate }}
            </legend>
            <bindy-file-upload
              [imageURL]="getNewsImage()"
              [id]="_newsId"
              (onUpload)="setNewsImage($event)"
              [uploadProgress]="uploadProgress"
              (onDelete)="deleteNewsImage()"
              [freeAspect]="true"
            ></bindy-file-upload>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
