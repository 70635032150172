import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { ILocalization } from 'src/app/interfaces/localization';
import { ILocalizationsFilters } from 'src/app/interfaces/filters/localizationsFilters';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  private apiUrl = SETTINGS.APIRUL + '/localization/';

  constructor(private httpClient: HttpClient) {}

  getLocalizationsByFilters(
    filters: ILocalizationsFilters
  ): Observable<ILocalization[]> {
    return this.httpClient
      .post<ILocalization[]>(
        this.apiUrl + 'getLocalizationsByFilters.php',
        filters
      )
      .pipe(
        map((data: ILocalization[]) => {
          return data;
        })
      );
  }

  getLocalizationById(id: number): Observable<ILocalization> {
    const params = new HttpParams().set('localizationId', id.toString());

    return this.httpClient
      .get<ILocalization>(this.apiUrl + 'getLocalizationById.php', { params })
      .pipe(
        map((data: ILocalization) => {
          return data;
        })
      );
  }

  postLocalization(localization: ILocalization): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'addOrUpdateLocalization.php', localization)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  deleteLocalizationById(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteLocalization.php?localizationId=' + id
    );
  }
}
