import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { ISuggestion } from 'src/app/interfaces/suggestion';
import { ISuggestionsFilters } from 'src/app/interfaces/filters/suggestionsFilters';
import { SuggestionStatus } from 'src/app/interfaces/enums/suggestionStatus';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {
  private apiUrl = SETTINGS.APIRUL + '/suggestion/';

  constructor(private httpClient: HttpClient) {}

  getSuggestionsByFilters(
    filters: ISuggestionsFilters
  ): Observable<ISuggestion[]> {
    return this.httpClient
      .post<ISuggestion[]>(this.apiUrl + 'getSuggestionsByFilters.php', filters)
      .pipe(
        map((data: ISuggestion[]) => {
          return data;
        })
      );
  }

  postSuggestion(suggestion: ISuggestion): Observable<ISuggestion> {
    return this.httpClient
      .post<ISuggestion>(this.apiUrl + 'addSuggestion.php', suggestion)
      .pipe(
        map((data: ISuggestion) => {
          return data;
        })
      );
  }

  getSuggestionById(id: number): Observable<ISuggestion> {
    const params = new HttpParams().set('suggestionId', String(id));

    return this.httpClient
      .get<ISuggestion>(this.apiUrl + 'getSuggestionById.php', { params })
      .pipe(
        map((data: ISuggestion) => {
          return data;
        })
      );
  }

  changeSuggestionStatus(
    id: number,
    status: SuggestionStatus
  ): Observable<ISuggestion> {
    const suggestion = { id, status } as ISuggestion;
    return this.httpClient
      .post<ISuggestion>(this.apiUrl + 'changeSuggestionStatus.php', suggestion)
      .pipe(
        map((data: ISuggestion) => {
          return data;
        })
      );
  }

  deleteSuggestionById(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteSuggestion.php?suggestionId=' + id
    );
  }
}
