import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MenuComponent } from 'src/app/components/menu/menu.component';
import { RouterModule } from '@angular/router';
import { AnimalCardComponent } from 'src/app/components/animal-card/animal-card.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { TitlebarComponent } from 'src/app/components/titlebar/titlebar.component';
import { MaterialModule } from 'src/app/material.module';
import { AnimalListComponent } from './animals/animal-list/animal-list.component';
import { AnimalDetailComponent } from './animals/animal-detail/animal-detail.component';
import { LocalizationListComponent } from './localizations/localization-list/localization-list.component';
import { SuggestionListComponent } from './suggestions/suggestion-list/suggestion-list.component';
import { SuggestionDetailComponent } from './suggestions/suggestion-detail/suggestion-detail.component';
import { LocalizationDetailComponent } from './localizations/localization-detail/localization-detail.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { BindyCommonModule } from 'src/app/components/common/bindy-common.module';
import { ModalAddAnimalComponent } from 'src/app/components/modal-add-animal/modal-add-animal.component';
import { ThirdPartyModule } from 'src/app/third-party.module';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { NewsComponent } from './news/news/news.component';
import { LocalizationCardComponent } from 'src/app/components/localization-card/localization-card.component';
import { TenantProfileComponent } from './tenant-profile/tenant-profile.component';
import { TenantSpeciesComponent } from './tenant-species/tenant-species.component';
import { TranslateModule } from '@ngx-translate/core';
import { TenantListComponent } from './tenants/tenant-list/tenant-list.component';
import { TenantDetailComponent } from './tenants/tenant-detail/tenant-detail.component';
import { LanguageSelectorComponent } from 'src/app/components/language-selector/language-selector.component';
import { ControlPanelComponent } from './control-panel/control-panel.component';
import { ModalAddAdminComponent } from 'src/app/components/modal-add-admin/modal-add-admin.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EventsComponent } from './events/events-calendar/events.component';
import { EventsDetailComponent } from './events/events-detail/events-detail.component';
import { DashboardCounterComponent } from 'src/app/components/dashboard-counter/dashboard-counter.component';
import { LandingComponent } from './landing/landing.component';
import { DownloadsListComponent } from './downloads/downloads-list/downloads-list.component';
import { UserSuplantComponent } from './users/user-suplant/user-suplant.component';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { ModalWelcomeComponent } from '../components/modal-welcome/modal-welcome.component';

@NgModule({
  declarations: [
    LandingComponent,
    LoginComponent,
    AnimalListComponent,
    AnimalDetailComponent,
    UserListComponent,
    UserDetailComponent,
    UserSuplantComponent,
    DashboardComponent,
    MenuComponent,
    TitlebarComponent,
    AnimalCardComponent,
    LanguageSelectorComponent,
    LocalizationCardComponent,
    LocalizationListComponent,
    LocalizationDetailComponent,
    SuggestionListComponent,
    SuggestionDetailComponent,
    MyProfileComponent,
    ModalAddAnimalComponent,
    ModalAddAdminComponent,
    ModalWelcomeComponent,
    NewsListComponent,
    NewsDetailComponent,
    NewsComponent,
    TenantListComponent,
    TenantDetailComponent,
    TenantProfileComponent,
    TenantSpeciesComponent,
    ControlPanelComponent,
    EventsComponent,
    EventsDetailComponent,
    DashboardCounterComponent,
    DownloadsListComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    BindyCommonModule,
    ThirdPartyModule,
    TranslateModule,
    FullCalendarModule
  ],
  exports: [MenuComponent],
  providers: [],
  bootstrap: []
})
export class PagesModule {}
