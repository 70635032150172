import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bindy-button',
  templateUrl: './bindy-button.component.html',
  styleUrls: ['./bindy-button.component.scss']
})
export class BindyButtonComponent implements OnInit {
  //<bindy-button text="Brezo" icon="fa-save" type="submit" color="secondary" (onClick)="someFunction()" ></bindy-button>

  buttonClass = '';

  @Input() text = 'Put some text lazy dog!';
  @Input() icon = '';
  @Input() color = '';
  @Input() type = 'button';
  @Output() onClick = new EventEmitter();

  ngOnInit(): void {
    this.resolveClass();
  }

  private resolveClass() {
    if (this.color !== '') {
      this.buttonClass = `u-button--${this.color}`;
    }
  }

  fireEventClick(event: Event) {
    this.onClick.emit();
  }
}
