import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LocalizationService } from 'src/app/services/localization.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import {
  LocalizationType,
  LocalizationTypeLabels
} from 'src/app/interfaces/enums/localizationType';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-localization-detail',
  templateUrl: './localization-detail.component.html',
  styleUrls: ['./localization-detail.component.scss']
})
export class LocalizationDetailComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  showConfirmation: boolean;
  localizationForm: UntypedFormGroup;
  titleText = 'titles.createLocalization';
  showDeleteButton = false;
  iCanDeleteLocalization = false;

  localizationTypeLabels = LocalizationTypeLabels;
  localizationTypes = Object.values(LocalizationType).filter(
    (value) => typeof value === 'number'
  );

  private _localizationId: number;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private localizationService: LocalizationService,
    private userProfileService: UserProfileService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  ngOnInit(): void {
    this._localizationId = Number(this.activatedRoute.snapshot.params.id);
    this.initForm();
    if (this._localizationId !== 0) {
      this.titleText = 'titles.editLocalization';
      this.showDeleteButton = true;
      this.getLocalizationData();
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  sendLocalization() {
    if (!this.localizationForm.valid) {
      markFormGroupTouched(this.localizationForm);
      return;
    }
    this.showLoading = true;
    this.localizationService
      .postLocalization(this.localizationForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          let successMessage = 'toast.LOCALIZATION_CREATE_SUCCESS';
          if (this._localizationId !== 0) {
            successMessage = 'toast.LOCALIZATION_EDIT_SUCCESS';
          }
          this.showSuccessToast(successMessage);
          this.router.navigate([`localization-list`]);
        })
      )
      .subscribe();
  }

  closeConfirmation(): void {
    this.showConfirmation = false;
  }

  confirmDeleteLocalization(): void {
    this.showLoading = true;
    this.localizationService
      .deleteLocalizationById(this._localizationId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.LOCALIZATION_DELETE_SUCCESS');
          this.router.navigate([`localization-list`]);
        })
      )
      .subscribe();
  }

  deleteLocalization(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_LOCALIZATION_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteLocalization();
        }
      });
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.localizationForm, inputName);
  }

  private getLocalizationData() {
    this.showLoading = true;
    this.localizationService
      .getLocalizationById(this._localizationId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.localizationForm.patchValue(data[0]);
        if (data[0].totalAnimals === 0) {
          this.iCanDeleteLocalization = true;
        }
      });
  }

  private initForm() {
    this.localizationForm = this.fb.group({
      id: [null],
      tenantId: [this.userProfileTenantId],
      name: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      localizationTypeId: [0],
      lat: [''],
      lng: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
