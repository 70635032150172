import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAnimal } from 'src/app/interfaces/animal';
import { Genders } from 'src/app/interfaces/enums/genders';
import { LocalizationTypeLabels } from 'src/app/interfaces/enums/localizationType';
import { ISpecie } from 'src/app/interfaces/specie';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { ILocalization } from 'src/app/interfaces/localization';

@Component({
  selector: 'localization-card',
  templateUrl: './localization-card.component.html',
  styleUrls: ['./localization-card.component.scss']
})
export class LocalizationCardComponent {
  @Input() localizationInput: ILocalization;
  @Input() localizationTypeLabelsInput = LocalizationTypeLabels;
  @Input() animalsInLocalization: IAnimal[] = [];

  genders = Genders;

  get species(): Array<ISpecie> {
    return this.masterDataService.species;
  }

  constructor(
    private masterDataService: MasterDataService,
    private router: Router
  ) {}

  goToLocalizationDetail(id: number): void {
    this.router.navigate([`localization-detail/${id}`]);
  }

  goToAnimal(id: number) {
    this.router.navigate([`animal-detail/${id}`]);
  }

  animalImage(image: string): string {
    return SETTINGS.ANIMALS_IMAGE_PATH + image;
  }
}
