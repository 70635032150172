<div *ngIf="openModal" class="c-modal-add-animal">
  <div class="c-modal-add-animal__content">
    <div class="c-modal-add-animal__content-header">
      <p class="c-modal-add-animal__content-title">
        <i class="fas fa-paw"></i>{{ 'modals.newAnimal_title' | translate }}
      </p>
      <div (click)="close()"><i class="icon-close fas fa-times"></i></div>
    </div>
    <form
      class="c-modal-add-animal__content-form"
      [formGroup]="animalForm"
      (ngSubmit)="submit()"
    >
      <div>
        <p class="c-modal-add-animal__content-text">
          {{ 'modals.newAnimal_info' | translate }}
        </p>
      </div>
      <div class="c-modal-add-animal__content-input-icon">
        <div class="c-modal-add-animal__content-arrow">
          <div>{{ 'modals.newAnimal_randomName' | translate }}</div>
          <img
            class="c-modal-add-animal__content-arrow-img"
            src="assets/img/arrow.svg"
            alt=""
          />
        </div>
        <div class="u-input">
          <bindy-input-text
            formControlName="name"
            label="{{ 'forms.name' | translate }}"
            placeholder="{{ 'forms.name_placeholder' | translate }}"
            required="true"
          ></bindy-input-text>
        </div>
        <bindy-button
          text=""
          icon="fa-random"
          color="secondary"
          (click)="getRandomName()"
        ></bindy-button>
      </div>
      <div class="u-input u-input--select">
        <p class="u-input__text">
          {{ 'forms.gender' | translate }}
          <span class="u-required">{{ 'common.required' | translate }}</span>
        </p>
        <div class="u-input__content">
          <mat-select class="u-input__input" formControlName="genderId">
            <mat-option value=""> {{ 'forms.select' | translate }} </mat-option>
            <mat-option *ngFor="let gender of genders" [value]="gender">
              {{ genderLabels[gender] | translate }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="u-input u-input--select">
        <p class="u-input__text">
          {{ 'forms.specie' | translate }}
          <span class="u-required">{{ 'common.required' | translate }}</span>
        </p>
        <div class="u-input__content">
          <mat-select class="u-input__input" formControlName="specieId">
            <mat-option value=""> {{ 'forms.select' | translate }}</mat-option>
            <mat-option *ngFor="let specie of species" [value]="specie.id">
              {{ specie.literal | translate }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </form>
    <div class="c-modal-add-animal__buttons">
      <bindy-button
        text="{{ 'common.button_cancel' | translate }}"
        icon="fa-times"
        color="third"
        (click)="close()"
      ></bindy-button>
      <bindy-button
        text="{{ 'common.button_continue' | translate }}"
        icon="fa-save"
        color="primary"
        (click)="goToAnimalForm()"
      ></bindy-button>
    </div>
  </div>
</div>
