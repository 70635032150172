import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import { NewsService } from 'src/app/services/news.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { IImageUpload } from 'src/app/interfaces/imageUpload';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  showLoading: boolean;
  showConfirmation: boolean;
  newsForm: UntypedFormGroup;
  titleText = 'titles.createNews';
  showDeleteButton = false;
  showMarkDashboardButton = false;
  showNewsBool;

  private _newsId: number;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();
  private _newsImage;
  private _newsImageUpload: IImageUpload = null;

  constructor(
    private newsService: NewsService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this._newsId = Number(this.activatedRoute.snapshot.params.id);
    this.initForm();
    if (this._newsId !== 0) {
      this.titleText = 'titles.editNews';
      this.showDeleteButton = true;
      this.getNewsData();
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  sendNews() {
    if (!this.newsForm.valid) {
      markFormGroupTouched(this.newsForm);
      return;
    }
    this.showLoading = true;

    this.newsService
      .postNews(this.newsForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((newsId: number) => {
        this.checkIfUploadImage(newsId);
      });
  }

  checkIfUploadImage(newsId: number): void {
    if (!this._newsImageUpload) {
      this.finalizeUploadNews();
      return;
    }
    this._newsImageUpload.id = newsId;
    this.newsService
      .uploadNewsImage(this._newsImageUpload)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.finalizeUploadNews();
        })
      )
      .subscribe();
  }

  finalizeUploadNews(): void {
    this.showLoading = false;
    let successMessage = 'toast.NEWS_CREATE_SUCCESS';
    if (this._newsId !== 0) {
      successMessage = 'toast.NEWS_EDIT_SUCCESS';
    }
    this.showSuccessToast(successMessage);
    this.router.navigate([`news-list`]);
  }

  closeConfirmation(): void {
    this.showConfirmation = false;
  }

  confirmDeleteNews(): void {
    this.showLoading = true;
    this.newsService
      .deleteNewsById(this._newsId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.NEWS_DELETE_SUCCESS');
          this.router.navigate([`news-list`]);
        })
      )
      .subscribe();
  }

  deleteNews(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_NEWS_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteNews();
        }
      });
  }

  markNewsAsDashboard(): void {
    this.showLoading = true;
    this.newsService
      .markNewsAsDashboard(this._newsId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.NEWS_MARK_DASHBOARD_SUCCESS');
          this.router.navigate([`news-list`]);
        })
      )
      .subscribe();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.newsForm, inputName);
  }

  getNewsImage(): string {
    if (!this._newsImage) {
      return '';
    }
    return SETTINGS.NEWS_IMAGE_PATH + this._newsImage;
  }

  setNewsImage(imageUpload: IImageUpload) {
    this._newsImageUpload = imageUpload;
  }

  deleteNewsImage(): void {
    this.newsService
      .deleteNewsImage(this._newsId)
      .pipe(
        finalize(() => {
          this._newsImage = null;
          this.showSuccessToast('toast.IMAGE_DELETE_SUCCESS');
        })
      )
      .subscribe();
  }

  hideNews(): void {
    this.showLoading = true;
    this.newsService
      .showHideNews(this._newsId, false)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.NEWS_HIDE_SUCCESS');
          this.router.navigate([`news-list`]);
        })
      )
      .subscribe();
  }

  showNews(): void {
    this.showLoading = true;
    this.newsService
      .showHideNews(this._newsId, true)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.NEWS_SHOW_SUCCESS');
          this.router.navigate([`news-list`]);
        })
      )
      .subscribe();
  }

  private getNewsData() {
    this.showLoading = true;
    this.newsService
      .getNewsById(this._newsId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.newsForm.patchValue(data[0]);
        if (!data[0].showInDashboard) {
          this.showMarkDashboardButton = true;
        }
        this._newsImage = data[0].image;
        this.showNewsBool = data[0].showNews;
      });
  }

  private initForm() {
    this.newsForm = this.fb.group({
      id: [null],
      title: ['', Validators.required],
      subtitle: [''],
      info: ['', Validators.required]
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
