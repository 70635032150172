<app-titlebar
  [title]="'titles.suggestion_list' | translate"
  icon="fas fa-inbox"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="user-list">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addSuggestion' | translate }}"
          icon="fa-plus"
          color="success"
          routerLink="/suggestion-detail/0"
        ></bindy-button>

        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}:
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="message"
                  label="{{ 'forms.message' | translate }}"
                  placeholder="{{ 'forms.messagge_placeholder' | translate }}"
                ></bindy-input-text>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'suggestions.subject' | translate }}:
                </p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="subject">
                    <mat-option value="">{{
                      'forms.select' | translate
                    }}</mat-option>
                    <mat-option
                      *ngFor="let subject of subjects"
                      [value]="subject"
                    >
                      {{ subjectsLabels[subject] | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'suggestions.status' | translate }}:
                </p>
                <div class="u-input__content">
                  <mat-select class="u-input__input" formControlName="status">
                    <mat-option
                      *ngFor="let status of statuses"
                      [value]="status"
                    >
                      {{ suggestionStatusLabels[status] | translate }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addSuggestion' | translate }}"
          icon="fa-plus"
          color="success"
          routerLink="/suggestion-detail/0"
        ></bindy-button>
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ suggestions.length }}
        </div>
      </div>
    </div>

    <div class="u-table">
      <div class="hidden-xs">
        <div class="u-table__header suggestion-list-table-header hidden-xs">
          <div>{{ 'suggestions.id' | translate }}</div>
          <div>{{ 'suggestions.user' | translate }}</div>
          <div>{{ 'suggestions.subject' | translate }}</div>
          <div>{{ 'suggestions.messagge' | translate }}</div>
          <div>{{ 'suggestions.status' | translate }}</div>
          <div *ngIf="!isDoneSearch">
            {{ 'suggestions.creationDate' | translate }}
          </div>
          <div *ngIf="isDoneSearch">
            {{ 'suggestions.doneDate' | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="suggestions.length > 0">
        <div
          class="u-table__data suggestion-list-table-data"
          *ngFor="let suggestion of suggestions; let i = index"
          (click)="goToSuggestionDetail(suggestion.id)"
          [ngClass]="{ 'is-done': suggestion.status === isDone }"
        >
          <p>
            <span class="visible-xs u-bold"
              >{{ 'suggestions.id' | translate }}: </span
            >{{ suggestion.id }}
          </p>
          <p>
            <span class="visible-xs u-bold"
              >{{ 'suggestions.user' | translate }}: </span
            >{{ suggestion.userName }} {{ suggestion.userSurName }}
          </p>
          <p>
            <span class="visible-xs u-bold"
              >{{ 'suggestions.subject' | translate }}: </span
            >{{ subjectsLabels[suggestion.subject] | translate }}
          </p>
          <p>
            <span class="visible-xs u-bold"
              >{{ 'suggestions.messagge' | translate }}: </span
            >{{ suggestion.message }}
          </p>
          <p>
            <span class="visible-xs u-bold"
              >{{ 'suggestions.status' | translate }}: </span
            >{{ suggestionStatusLabels[suggestion.status] | translate }}
          </p>
          <p *ngIf="!isDoneSearch">
            <span class="visible-xs u-bold"
              >{{ 'suggestions.creationDate' | translate }}: </span
            >{{
              suggestion.creationDate.replace(' ', 'T') | date : 'dd/MM/YYYY'
            }}
          </p>
          <p *ngIf="isDoneSearch">
            <span class="visible-xs u-bold"
              >{{ 'suggestions.doneDate' | translate }}: </span
            >{{ suggestion.doneDate.replace(' ', 'T') | date : 'dd/MM/YYYY' }}
          </p>
        </div>
      </div>
      <div class="u-table__no_results" *ngIf="suggestions.length === 0">
        {{ 'common.noResult' | translate }}
      </div>
    </div>
  </div>
</div>
