export enum Genders {
  MALE = 1,
  FEMALE = 2,
  UNKNOWN = 3
}

export const GenderLabels: Record<Genders, string> = {
  [Genders.MALE]: 'common.gender_male',
  [Genders.FEMALE]: 'common.gender_female',
  [Genders.UNKNOWN]: 'common.gender_unknown'
};
