import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IEventsFilters } from 'src/app/interfaces/filters/eventsFilters';
import { IEvent } from 'src/app/interfaces/event';
import { IImageUpload } from 'src/app/interfaces/imageUpload';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private apiUrl = SETTINGS.APIRUL + 'event/';

  constructor(private httpClient: HttpClient) {}

  getEventsByFilters(filters: IEventsFilters): Observable<IEvent[]> {
    return this.httpClient
      .post<IEvent[]>(this.apiUrl + 'getEventsByFilters.php', filters)
      .pipe(
        map((data: IEvent[]) => {
          return data;
        })
      );
  }

  postEvent(news: IEvent): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'addOrUpdateEvent.php', news)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  deleteEventById(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteEvent.php?eventId=' + id
    );
  }

  uploadEventImage(imageUpload: IImageUpload) {
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadEventImage.php',
      imageUpload,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  deleteEventImage(eventId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteEventImage.php?eventId=' + eventId
    );
  }
}
