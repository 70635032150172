import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { Profiles } from 'src/app/interfaces/enums/profiles';
import { ISpecie } from 'src/app/interfaces/specie';
import { ITenantSpecies } from 'src/app/interfaces/tenantSpecies';
import { IUserFirstTime } from 'src/app/interfaces/userFirstTime.model';
import { MasterDataService } from 'src/app/services/master-data.service';
import { TenantSpecieService } from 'src/app/services/tenantSpecie.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal-welcome',
  templateUrl: './modal-welcome.component.html',
  styleUrls: ['./modal-welcome.component.scss']
})
export class ModalWelcomeComponent implements OnInit {
  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get userProfileProfileId(): number {
    return this.userProfileService.userProfile.profileId;
  }

  @Input() openModal = false;
  @Output() closeModal = new EventEmitter<boolean>();

  tenantSpecies: Array<ISpecie> = [];
  showLoading: boolean;
  hasSpecies: boolean;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private masterDataService: MasterDataService,
    private tenantSpecieService: TenantSpecieService,
    private userProfileService: UserProfileService,
    private translate: TranslateService,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getTenantSpecies();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  activeSpecie(specieId: number): void {
    const specieSelected = this.tenantSpecies.find((s) => s.id === specieId);
    specieSelected.activeForTenant = !specieSelected.activeForTenant;
  }

  saveTenantSpecie(): void {
    if (this.checkIfIsAdminUser() && !this.hasSpecies) {
      const speciesSelected = this.tenantSpecies.filter(
        (s) => s.activeForTenant
      );
      if (speciesSelected.length === 0) {
        this.showWarningToast('toast.TENANT_SPECIE_NOT_SELECTED');
        return;
      }
      const specieIds = speciesSelected.map((s) => s.id);
      this.hasSpecies = true;
      this.setFirstTimeTrue();
      this.saveTenantSpecies(specieIds);
      this.close();
      return;
    }
    this.setFirstTimeTrue();
    this.close();
  }

  close(): void {
    this.openModal = false;
    this.closeModal.emit(true);
  }

  checkIfIsAdminUser(): boolean {
    return Number(this.userProfileProfileId) === Profiles.ADMIN;
  }

  private setFirstTimeTrue(): void {
    const userFirstTimeParams: IUserFirstTime = {
      id: this.userProfileService.userProfile.id
    };
    this.userService
      .setFirstTimeTrue(userFirstTimeParams)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe(() => this.userProfileService.updateUserProfileFirstTime());
  }

  private getTenantSpecies(): void {
    this.showLoading = true;
    this.tenantSpecieService
      .getTenantSpecieByTenantId(this.userProfileTenantId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        if (data.length === 0) {
          this.hasSpecies = false;
          this.fetchTenantSpecies();
          return;
        }
        this.hasSpecies = true;
      });
  }

  private fetchTenantSpecies(): void {
    const masterSpecies = this.masterDataService.species;
    for (const specie of masterSpecies) {
      this.tenantSpecies.push(specie);
    }
  }

  private saveTenantSpecies(speciesId: number[]): void {
    this.showLoading = true;
    const tenantSpecies: ITenantSpecies = {
      tenantId: this.userProfileTenantId,
      speciesId
    };

    this.tenantSpecieService
      .editTenantSpecies(tenantSpecies)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe(() => {
        this.showSuccessToast('toast.TENANT_SPECIE_SUCCESS');
        this.updateMasterData(speciesId);
      });
  }

  private updateMasterData(speciesId: number[]): void {
    this.masterDataService.updateTenantSpecies(speciesId);
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }

  private showWarningToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('w'));
  }
}
