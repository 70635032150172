import { UntypedFormGroup } from '@angular/forms';
import { IEvent } from '../interfaces/event';

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
export const markFormGroupTouched = (formGroup: UntypedFormGroup): void => {
  (<any>Object).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
};

export const inputValidationUtil = (
  formName: UntypedFormGroup,
  inputName: string
): boolean => {
  return (
    (formName.get(inputName).invalid && formName.get(inputName).touched) ||
    formName.get(inputName).dirty ||
    formName.dirty
  );
};

export const compressImage = (src, newX = 400, newY = 400) => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    img.onerror = (error) => console.log(error);
  });
};

export const mapOrcaEventsToFullCalendarEvents = (orcaEvents: IEvent[]) => {
  const fcEvents = orcaEvents.map((event) => ({
    id: String(event.id),
    title: event.title,
    start: String(event.startDate),
    end: addOneDayToDate(event.endDate),
    //description: event.description,
    //address: event.address,
    //city: event.city,
    //startHour: string;
    //endHour?: string;
    classNames: ['u-event']
  }));
  return fcEvents;
};

export const addOneDayToDate = (date: Date): string => {
  if (!date) return null;
  const newDate = new Date(String(date));
  newDate.setDate(newDate.getDate() + 1);
  return String(newDate.toISOString().split('T')[0]);
};
