import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IUser } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ProfileLabels, Profiles } from 'src/app/interfaces/enums/profiles';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { TenantService } from 'src/app/services/tenant.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { ITenant } from 'src/app/interfaces/tenant';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IUsersFilters } from 'src/app/interfaces/filters/usersFilters';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  users: IUser[] = [];
  showLoading: boolean;
  profileLabels = ProfileLabels;
  profiles = Object.values(Profiles).filter(
    (value) => typeof value === 'number' && value !== 1
  );
  tenants: ITenant[] = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();
  private filters: IUsersFilters = {
    tenantId: this.userProfileTenantId
  };

  constructor(
    private userService: UserService,
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get userProfile(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnDestroy(): void {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  getUsers(): void {
    this.showLoading = true;
    this.userService
      .getUsersByFilters(this.filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data: IUser[]) => {
        this.users = data;
      });
  }

  goToUserDetail(id: number): void {
    this.router.navigate([`user-detail/${id}`]);
  }

  userImage(image: string): string {
    return SETTINGS.USERS_IMAGE_PATH + image;
  }
}
