import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SETTINGS } from 'src/app/SETTINGS';
import { ITestimony } from 'src/app/interfaces/testimony';
import { LandingService } from 'src/app/services/landing.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  host: { class: 'app-landing' }
})
export class LandingComponent implements OnInit {
  isMenuVisible = false;

  constructor(
    private scroller: ViewportScroller,
    private landingService: LandingService
  ) {}

  get testimonies(): ITestimony[] {
    return this.landingService.testimonies;
  }

  ngOnInit(): void {
    this.landingService.getJSONTestimonies();
  }

  goToScroll(divName) {
    this.scroller.scrollToAnchor(divName);
  }

  tenantImage(image: string): string {
    return SETTINGS.TENANT_IMAGE_PATH + image;
  }
}
