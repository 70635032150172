import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BindyButtonComponent } from './bindy-button/bindy-button.component';
import { BindyInputTextComponent } from './bindy-input-text/bindy-input-text.component';
import { BindyFileUploadComponent } from './bindy-file-upload/bindy-file-upload.component';
import { BindyFileUploadMultipleComponent } from './bindy-file-upload-multiple/bindy-file-upload-multiple.component';
import { MaterialModule } from 'src/app/material.module';
import { BindyConfirmComponent } from './bindy-confirm/bindy-confirm.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    BindyInputTextComponent,
    BindyButtonComponent,
    BindyFileUploadComponent,
    BindyConfirmComponent,
    BindyFileUploadMultipleComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ImageCropperModule,
    TranslateModule
  ],
  exports: [
    BindyInputTextComponent,
    BindyButtonComponent,
    BindyFileUploadComponent,
    BindyConfirmComponent,
    BindyFileUploadMultipleComponent
  ],
  providers: [],
  bootstrap: []
})
export class BindyCommonModule {}
