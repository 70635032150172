export enum SuggestionStatus {
  NEW = 'NEW',
  WORKING = 'WORKING',
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE'
}

export const SuggestionStatusLabels: Record<SuggestionStatus, string> = {
  [SuggestionStatus.NEW]: 'common.suggestionStatus_new',
  [SuggestionStatus.WORKING]: 'common.suggestionStatus_working',
  [SuggestionStatus.DONE]: 'common.suggestionStatus_done',
  [SuggestionStatus.NOT_DONE]: 'common.suggestionStatus_notDone'
};
