import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Downloads } from 'src/app/interfaces/enums/downloads';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { ControlPanelService } from 'src/app/services/controlPanel.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-downloads-list',
  templateUrl: './downloads-list.component.html',
  styleUrls: ['./downloads-list.component.scss']
})
export class DownloadsListComponent {
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  downloads = Downloads;

  constructor(
    private userProfileService: UserProfileService,
    private controlPanelService: ControlPanelService
  ) {}

  get userProfileProfile(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  downloadFile(downloadId: number, fileName: string) {
    const url = SETTINGS.DOWNLOADS_PATH + fileName;
    window.open(url, '_blank');
    this.setDownloadControl(downloadId);
  }

  setDownloadControl(downloadId: number) {
    if (!environment.production) return;
    if (this.userProfileProfile.id === 1 || this.userProfileProfile.id === 2)
      return;
    this.controlPanelService
      .postDownloadControl(downloadId, this.userProfileProfile.id)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe();
  }
}
