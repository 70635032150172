import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { SETTINGS } from 'src/app/SETTINGS';

export class ISnackbarMaterialConfig {
  duration: number = SETTINGS.SNACKBAR_CONFIG_DURATION;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right'; //'start' | 'center' | 'end' | 'left' | 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top'; //'top' | 'bottom';
  panelClass = 'toast-base';

  constructor(_panelClass?: string) {
    if (_panelClass === 's') {
      this.panelClass = 'toast-success';
    } else if (_panelClass === 'w') {
      this.panelClass = 'toast-warning';
    } else if (_panelClass === 'e') {
      this.panelClass = 'toast-error';
    }
  }
}
