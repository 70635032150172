import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import { IAnimal } from 'src/app/interfaces/animal';
import { IAnimalBill } from 'src/app/interfaces/animalBill';
import { IAnimalImage } from 'src/app/interfaces/animalImage';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { BillTypeLabels, BillTypes } from 'src/app/interfaces/enums/billTypes';
import { Profiles } from 'src/app/interfaces/enums/profiles';
import { ILocalizationsFilters } from 'src/app/interfaces/filters/localizationsFilters';
import { ILocalization } from 'src/app/interfaces/localization';
import { ISpecie } from 'src/app/interfaces/specie';
import { AnimalService } from 'src/app/services/animal.service';
import { AnimalBillService } from 'src/app/services/animalBill.service';
import { AnimalImageService } from 'src/app/services/animalImage.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { GenderLabels, Genders } from '../../../interfaces/enums/genders';
import { InfoModalEnum } from 'src/app/interfaces/enums/modalsInfo';

@Component({
  selector: 'app-animal-detail',
  templateUrl: './animal-detail.component.html',
  styleUrls: ['./animal-detail.component.scss']
})
export class AnimalDetailComponent implements OnInit, OnDestroy {
  @ViewChild('input', { static: false }) input;

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new UntypedFormControl(this.positionOptions[1]);
  hideDelay = new UntypedFormControl(2000);

  animalDetailForm: UntypedFormGroup;
  billForm: UntypedFormGroup;
  showLoading = false;
  genderLabels = GenderLabels;
  genders = Object.values(Genders).filter((value) => typeof value === 'number');
  billLabels = BillTypeLabels;
  billTypes = Object.values(BillTypes).filter(
    (value) => typeof value === 'number'
  );

  localizations: ILocalization[] = [];
  animalPhoto: IAnimalImage;
  imagesArray: IAnimalImage[] = [];
  tenantHasWeb = false;
  showSponsorPage = false;
  showAdoptedPage = false;
  showPPPLicense = false;
  animalId: number;
  showBottomModalLocalization: boolean;
  showBottomModalAdoptionDate: boolean;
  showBottomModalPassAwayDate: boolean;
  modalInfoText: string;

  openModal = false;
  showBillsLoading = false;
  showBillsForm = false;
  bills: IAnimalBill[] = [];
  billsTotal = '0';

  tata = 1000;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private animalService: AnimalService,
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private masterDataService: MasterDataService,
    private dateAdapter: DateAdapter<Date>,
    private snackBar: MatSnackBar,
    private localizationService: LocalizationService,
    private translate: TranslateService,
    private router: Router,
    private userProfileService: UserProfileService,
    public dialog: MatDialog,
    private animalImageService: AnimalImageService,
    private animalBillService: AnimalBillService
  ) {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  ngOnInit(): void {
    this.getLocalizationsByUser();
    this.animalId = this.activatedRoute.snapshot.params.id;
    if (this.animalId) {
      this.initForm();
      this.getAnimalData();
      this.getAnimalImages();
      this.getAnimalBills();
      this.initBillForm();
    }
  }

  ngAfterViewChecked(): void {
    this.getUserData();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  get species(): Array<ISpecie> {
    const tenantSpecies = this.masterDataService.tenantSpecies;
    return this.masterDataService.species.filter(function (item) {
      return tenantSpecies.indexOf(item.id) !== -1;
    });
  }

  getUserData(): void {
    this.tenantHasWeb = this.userProfileService.userProfile.tenantHasWebPage;
    this.showSponsorPage =
      this.tenantHasWeb &&
      this.userProfileService.userProfile.tenantHasSponsorPage;
    this.showAdoptedPage =
      this.tenantHasWeb &&
      this.userProfileService.userProfile.tenantHasAdoptedPage;
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.animalDetailForm, inputName);
  }

  billInputValidation(inputName: string): boolean {
    return inputValidationUtil(this.billForm, inputName);
  }

  saveImage(animalImage: IAnimalImage): void {
    this.showLoading = true;
    animalImage.isPrincipal = this.imagesArrayEmpty();
    this.animalImageService
      .uploadAnimalImage(animalImage)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.getAnimalImages();
        })
      )
      .subscribe();
  }

  removeImage(animalImage: IAnimalImage): void {
    this.showLoading = true;
    const iHaveToMarkNewPrincipal: boolean =
      animalImage.isPrincipal && this.imagesArrayHasMoreThanOneImage();

    this.animalImageService
      .deleteAnimalImage(animalImage.id)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          if (iHaveToMarkNewPrincipal) {
            const newPrincipalImage = this.imagesArray[1];
            this.animalImageService
              .makeAnimalImageAsPrincipal(newPrincipalImage)
              .pipe(takeUntil(this._ngUnsuscribe))
              .pipe(
                finalize(() => {
                  this.getAnimalImages();
                })
              )
              .subscribe();
          } else {
            this.getAnimalImages();
          }
        })
      )
      .subscribe();
  }

  getAnimalImages(): void {
    const maxArrayLength = 4;
    this.animalImageService
      .getAnimalImagesByAnimalId(this.animalId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
        })
      )
      .subscribe((data) => {
        this.imagesArray = data;
        if (this.imagesArray.length !== maxArrayLength) {
          for (let i = this.imagesArray.length; i < maxArrayLength; i++) {
            const newImage = new IAnimalImage();
            this.imagesArray.push(newImage);
          }
        }
      });
  }

  sendAnimal() {
    if (!this.animalDetailForm.valid) {
      markFormGroupTouched(this.animalDetailForm);
      return;
    }
    this.showLoading = true;
    if (this.animalDetailForm.get('adoptionDate').value !== null) {
      this.animalDetailForm.get('showInAdoptionPage').setValue(false);
    }
    if (this.animalDetailForm.get('passAwayDate').value !== null) {
      this.animalDetailForm.get('showInAdoptionPage').setValue(false);
    }
    this.formatDate('birthDate');
    this.formatDate('adoptionDate');
    this.formatDate('passAwayDate');
    this.animalService
      .editAnimal(this.animalDetailForm.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.showSuccessToast('toast.ANIMAL_EDITED_SUCCESS');
          this.getAnimalData();
        })
      )
      .subscribe();
  }

  deleteAnimal(): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_ANIMAL_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteAnimal();
        }
      });
  }

  confirmDeleteAnimal(): void {
    this.showLoading = true;
    this.animalService
      .deleteAnimalById(this.animalId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.ANIMAL_DELETE_SUCCESS');
          this.goToList();
        })
      )
      .subscribe();
  }

  getAnimalBills(): void {
    this.showBillsLoading = true;
    this.animalBillService
      .getAnimalBillsByAnimalId(this.animalId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showBillsLoading = false;
        })
      )
      .subscribe((data) => {
        this.bills = data;
        this.calculateBillsTotal();
      });
  }

  calculateBillsTotal(): void {
    const sum = this.bills.reduce((accumulator, object) => {
      return accumulator + Number(object.price);
    }, 0);

    this.billsTotal = sum.toFixed(2);
  }

  deleteBill(billId: number): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_ANIMAL_BILL'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.confirmDeleteBill(billId);
        }
      });
  }

  showNewBillForm(): void {
    this.showBillsForm = true;
  }

  addNewBill(): void {
    if (!this.billForm.valid) {
      markFormGroupTouched(this.billForm);
      return;
    }
    this.billFormatDate('billDate');
    this.showBillsLoading = true;
    this.animalBillService
      .postBill(this.billForm.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.closeNewBillForm();
          this.showSuccessToast('toast.ANIMAL_BILL_CREATED_SUCCESS');
          this.getAnimalBills();
        })
      )
      .subscribe();
  }

  closeNewBillForm(): void {
    this.showBillsForm = false;
    this.initBillForm();
  }

  checkForDecimalKeys(e): void {
    if (e.charCode === 46) {
      event.preventDefault();
    }
  }

  showHideDescription(bill: IAnimalBill, value: boolean) {
    bill.showDescription = value;
  }

  openTooltip(id: number, text: string) {
    if (id === InfoModalEnum.LOCALIZATION) {
      this.showBottomModalLocalization = !this.showBottomModalLocalization;
    } else if (id === InfoModalEnum.ADOPTED_DATE) {
      this.showBottomModalAdoptionDate = !this.showBottomModalAdoptionDate;
    } else if (id === InfoModalEnum.PASS_AWAY_DATE) {
      this.showBottomModalPassAwayDate = !this.showBottomModalPassAwayDate;
    }

    this.modalInfoText = this.translate.instant(text);
  }

  closeModal() {
    this.showBottomModalLocalization = false;
    this.showBottomModalAdoptionDate = false;
    this.showBottomModalPassAwayDate = false;
  }

  private confirmDeleteBill(billId: number): void {
    this.showBillsLoading = true;
    this.animalBillService
      .deleteAnimalById(billId, this.animalId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showSuccessToast('toast.ANIMAL_BILL_DELETE_SUCCESS');
          this.getAnimalBills();
        })
      )
      .subscribe();
  }

  private imagesArrayEmpty(): boolean {
    return !this.imagesArray.some((image) => image.fileName);
  }

  private imagesArrayHasMoreThanOneImage(): boolean {
    return this.imagesArray.filter((image) => image.fileName).length > 1;
  }

  private formatDate(inputName: string) {
    if (this.animalDetailForm.get(inputName).value) {
      this.animalDetailForm
        .get(inputName)
        .setValue(
          formatDate(
            this.animalDetailForm.get(inputName).value,
            'dd-MM-yyy',
            'en'
          )
        );
    }
  }

  private billFormatDate(inputName: string) {
    if (this.billForm.get(inputName).value) {
      this.billForm
        .get(inputName)
        .setValue(
          formatDate(this.billForm.get(inputName).value, 'dd-MM-yyy', 'en')
        );
    }
  }

  private goToList(): void {
    this.router.navigate([`animal-list`]);
  }

  private getAnimalData() {
    this.showLoading = true;
    this.animalService
      .getAnimalById(String(this.animalId))
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data: IAnimal) => {
        this.animalDetailForm.patchValue(data[0]);
        this.animalDetailForm
          .get('creationDate')
          .setValue(
            formatDate(
              data[0].creationDate.replace(' ', 'T'),
              'dd-MM-yyy',
              'en'
            )
          );
        if (data[0].specieId === 1) {
          //PERRETE
          this.showPPPLicense = true;
        }
      });
  }

  private getLocalizationsByUser() {
    this.showLoading = true;
    const filters = {} as ILocalizationsFilters;
    const userProfile = this.userProfileService.userProfile;
    // if (userProfile.profileId !== Profiles.GOD) {
    filters.tenantId = userProfile.tenantId;
    // }
    this.localizationService
      .getLocalizationsByFilters(filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.localizations = data;
      });
  }

  private initForm() {
    this.animalDetailForm = this.fb.group({
      id: [this.animalId],
      name: ['', Validators.required],
      breed: [''],
      specieId: ['', Validators.required],
      genderId: ['', Validators.required],
      subspecie: [''],
      fur: [''],
      chip: [''],
      contactInfo: [''],
      isSterilized: [''],
      isVaccinated: [''],
      birthDate: [''],
      passAwayDate: [''],
      adoptionDate: [''],
      creationDate: [''],
      showInAdoptionPage: [''],
      showInAdoptedPage: [''],
      showInSponsorPage: [''],
      needLicense: [''],
      behaviour: [''],
      story: [''],
      compatibleWith: [''],
      health: [''],
      localizationId: [''],
      extraInformation: [''],
      link: ['']
    });
  }

  private initBillForm() {
    this.billForm = this.fb.group({
      id: [''],
      animalId: [this.animalId],
      billDate: ['', Validators.required],
      title: ['', Validators.required],
      description: [''],
      price: [0],
      billType: [this.billTypes[0], Validators.required]
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
