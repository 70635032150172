<app-titlebar
  [title]="titleText | translate"
  icon="fas fa-users"
  [parentTitle]="'titles.users_list' | translate"
  parentPath="/user-list"
></app-titlebar>
<div class="c-user-detail">
  <div class="u-content u-content--has-fixed-buttons">
    <app-loading *ngIf="showLoading"></app-loading>

    <div *ngIf="!showLoading">
      <form
        [formGroup]="userForm"
        (ngSubmit)="sendUser()"
        class="c-user-detail__form"
      >
        <fieldset class="u-fieldset">
          <legend class="u-subtitle u-legend">
            <i class="fas fa-info"></i> {{ 'titles.information' | translate }}
          </legend>
          <div class="u-fieldset-info">
            <p [innerHTML]="'users.userDetailInformation' | translate"></p>
            <p *ngIf="isNewUser">
              {{ 'users.informationCreateUser' | translate }}
            </p>
          </div>
        </fieldset>

        <div class="c-user-detail__header">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-user"></i>
              {{ 'titles.generalData' | translate }}
            </legend>
            <div class="c-user-detail__fieldset-content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="name"
                  label="{{ 'forms.name' | translate }}"
                  placeholder="{{ 'forms.name_placeholder' | translate }}"
                  required="true"
                  [disabled]="!isNewUser"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('name')">
                  <p
                    class="u-error"
                    *ngIf="userForm.get('name').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="u-input">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="surName"
                    label="{{ 'forms.surname' | translate }}"
                    placeholder="{{ 'forms.surname_placeholder' | translate }}"
                    required="true"
                    [disabled]="!isNewUser"
                  ></bindy-input-text>
                  <ng-container *ngIf="inputValidation('surName')">
                    <p
                      class="u-error"
                      *ngIf="userForm.get('surName').errors?.required"
                    >
                      {{ 'common.mandatoryField' | translate }}
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="email"
                  label="{{ 'forms.email' | translate }}"
                  placeholder="{{ 'forms.email_placeholder' | translate }}"
                  required="true"
                  type="email"
                  [disabled]="!isNewUser"
                ></bindy-input-text>
                <ng-container *ngIf="inputValidation('email')">
                  <p
                    class="u-error"
                    *ngIf="userForm.get('email').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                  <p
                    class="u-error"
                    *ngIf="userForm.get('email').errors?.email"
                  >
                    {{ 'forms.email_isWrong' | translate }}
                  </p>
                </ng-container>
              </div>
              <div class="u-input u-input--select">
                <p class="u-input__text">
                  {{ 'forms.profile' | translate }}
                  <span class="u-required">{{
                    'common.required' | translate
                  }}</span>
                </p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="profileId"
                  >
                    <mat-option value="">
                      {{ 'forms.select' | translate }}
                    </mat-option>
                    <mat-option
                      *ngFor="let profile of profiles"
                      [value]="profile"
                    >
                      {{ profileLabels[profile] | translate }}
                    </mat-option>
                  </mat-select>
                </div>
                <ng-container *ngIf="inputValidation('profileId')">
                  <p
                    class="u-error"
                    *ngIf="userForm.get('profileId').errors?.required"
                  >
                    {{ 'common.mandatoryField' | translate }}
                  </p>
                </ng-container>
              </div>
            </div>

            <div *ngIf="!isNewUser" class="c-user-detail__fieldset-content">
              <div class="u-input">
                <div class="u-input">
                  <bindy-input-text
                    formControlName="address"
                    label="{{ 'common.adrress' | translate }}"
                    placeholder=""
                    disabled="true"
                  ></bindy-input-text>
                </div>
              </div>
              <div class="u-input">
                <bindy-input-text
                  formControlName="city"
                  label="{{ 'common.city' | translate }}"
                  placeholder=""
                  disabled="true"
                ></bindy-input-text>
              </div>
              <div class="u-input">
                <p class="u-input__text">
                  {{ 'common.country' | translate }}
                </p>
                <div class="u-input__content">
                  <mat-select
                    class="u-input__input"
                    formControlName="countryId"
                    disabled="true"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.id"
                    >
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>

              <div class="u-input">
                <bindy-input-text
                  formControlName="phone"
                  label="{{ 'common.phone' | translate }}"
                  type="number"
                  disabled="true"
                  placeholder=""
                ></bindy-input-text>
              </div>
            </div>

            <div class="u-detail__button">
              <div class="u-detail__button-left">
                <bindy-button
                  *ngIf="!isNewUser"
                  text="{{ 'common.button_delete' | translate }}"
                  icon="fa-trash-alt"
                  color="danger"
                  (onClick)="deleteUser()"
                ></bindy-button>
              </div>
              <div>
                <bindy-button
                  *ngIf="isNewUser"
                  text="{{ 'common.button_save' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  type="submit"
                ></bindy-button>
                <bindy-button
                  *ngIf="!isNewUser"
                  text="{{ 'common.button_saveProfile' | translate }}"
                  icon="fa-save"
                  color="secondary"
                  (onClick)="saveUserProfile()"
                ></bindy-button>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    </div>
  </div>
</div>
