import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITestimony } from 'src/app/interfaces/testimony';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  public testimonies: ITestimony[] = [];

  constructor(private httpClient: HttpClient) {}

  public getJSONTestimonies(): void {
    this.httpClient
      .get('assets/objects/testimonies.json')
      .subscribe((data: ITestimony[]) => {
        this.testimonies = this.shuffle(data).splice(0, 3);
      });
  }

  private shuffle(array): ITestimony[] {
    return array.sort(() => Math.random() - 0.5);
  }
}
