import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IControlPanelData } from '../interfaces/controlPanelData';

@Injectable({
  providedIn: 'root'
})
export class ControlPanelService {
  private apiUrl = SETTINGS.APIRUL + 'controlPanel/';

  constructor(private httpClient: HttpClient) {}

  getEvents(): Observable<IControlPanelData[]> {
    const params = new HttpParams().set('totalPerPage', 15);

    return this.httpClient
      .get<IControlPanelData[]>(this.apiUrl + 'getEvents.php', { params })
      .pipe(
        map((data: IControlPanelData[]) => {
          return data;
        })
      );
  }

  getLogs(): Observable<IControlPanelData[]> {
    const params = new HttpParams().set('totalPerPage', 15);

    return this.httpClient
      .get<IControlPanelData[]>(this.apiUrl + 'getLogs.php', { params })
      .pipe(
        map((data: IControlPanelData[]) => {
          return data;
        })
      );
  }

  postDownloadControl(downloadId: number, userId: number): Observable<boolean> {
    const params: IControlPanelData = {
      userId,
      downloadId
    };

    return this.httpClient
      .post<boolean>(this.apiUrl + 'addDownloadControl.php', params)
      .pipe(
        map((data: boolean) => {
          return data;
        })
      );
  }

  getDownloadControl(): Observable<IControlPanelData[]> {
    const params = new HttpParams().set('totalPerPage', 15);

    return this.httpClient
      .get<IControlPanelData[]>(this.apiUrl + 'getDowloadsControl.php', {
        params
      })
      .pipe(
        map((data: IControlPanelData[]) => {
          return data;
        })
      );
  }

  getLastAnimals(): Observable<IControlPanelData[]> {
    const params = new HttpParams().set('totalPerPage', 15);

    return this.httpClient
      .get<IControlPanelData[]>(this.apiUrl + 'getLastAnimals.php', {
        params
      })
      .pipe(
        map((data: IControlPanelData[]) => {
          return data;
        })
      );
  }

  getAnimalsBills(): Observable<IControlPanelData[]> {
    const params = new HttpParams().set('totalPerPage', 15);

    return this.httpClient
      .get<IControlPanelData[]>(this.apiUrl + 'getAnimalBills.php', {
        params
      })
      .pipe(
        map((data: IControlPanelData[]) => {
          return data;
        })
      );
  }
}
