import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { GenderLabels, Genders } from 'src/app/interfaces/enums/genders';
import { IRandomAnimalName } from 'src/app/interfaces/randomAnimalName';
import { ISpecie } from 'src/app/interfaces/specie';
import { AnimalService } from 'src/app/services/animal.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';

@Component({
  selector: 'app-modal-add-animal',
  templateUrl: './modal-add-animal.component.html',
  styleUrls: ['./modal-add-animal.component.scss']
})
export class ModalAddAnimalComponent implements OnInit {
  @Input() openModal = false;
  @Output() closeModal = new EventEmitter<boolean>();

  animalForm: UntypedFormGroup;
  genderLabels = GenderLabels;
  genders = Object.values(Genders).filter((value) => typeof value === 'number');

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  get species(): Array<ISpecie> {
    const tenantSpecies = this.masterDataService.tenantSpecies;
    return this.masterDataService.species.filter((item) => {
      return tenantSpecies.indexOf(item.id) !== -1;
    });
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  constructor(
    private masterDataService: MasterDataService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private userProfileService: UserProfileService,
    private translate: TranslateService,
    private animalService: AnimalService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  goToAnimalForm(): void {
    if (!this.animalForm.valid) {
      return;
    }
    this.animalService
      .postAnimal(this.animalForm.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((animalId) => {
        this.showSuccessToast('toast.ANIMAL_CREATED_SUCCESS');
        this.close();
        this.router.navigate([`animal-detail/${animalId}`]);
      });
  }

  close(): void {
    this.openModal = false;
    this.closeModal.emit(true);
  }

  getRandomName(): void {
    this.animalService
      .getRandomName()
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((randomAnimalName: IRandomAnimalName) => {
        this.animalForm.get('name').patchValue(randomAnimalName.name);
      });
  }

  private initForm(): void {
    this.animalForm = this.fb.group({
      name: ['', Validators.required],
      specieId: ['', Validators.required],
      genderId: ['', Validators.required],
      tenantId: [this.userProfileTenantId, Validators.required]
    });

    if (this.species.length === 1) {
      this.animalForm.get('specieId').patchValue(this.species[0].id);
    }
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
