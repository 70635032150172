<div class="titlebar">
  <div class="titlebar__title">
    <i [class]="icon"></i>
    <a *ngIf="parentTitle" [routerLink]="parentPath"> {{ parentTitle }} </a>
    <span *ngIf="parentTitle"> >> </span>
    {{ title }}
  </div>
  <div class="hidden-xs titlebar__icons">
    <div class="titlebar__icon" (click)="goToTenantWeb()" *ngIf="tenantHasWeb">
      <i class="fas fa-globe"></i>
      <div class="titlebar__tooltip">{{ 'menu.tooltip_web' | translate }}</div>
    </div>
    <div
      class="titlebar__icon"
      (click)="goToFacebook()"
      *ngIf="tenantHasFacebook"
    >
      <i class="fab fa-facebook"></i>
      <div class="titlebar__tooltip">{{ 'menu.tooltip_face' | translate }}</div>
    </div>
    <div
      class="titlebar__icon"
      (click)="goToInstagram()"
      *ngIf="tenantHasInstagram"
    >
      <i class="fab fa-instagram"></i>
      <div class="titlebar__tooltip">
        {{ 'menu.tooltip_insta' | translate }}
      </div>
    </div>
    <div
      class="titlebar__icon"
      (click)="goToTwitter()"
      *ngIf="tenantHasTwitter"
    >
      <i class="fab fa-twitter"></i>
      <div class="titlebar__tooltip">{{ 'menu.tooltip_twit' | translate }}</div>
    </div>
    <div
      class="titlebar__icon"
      (click)="goToYoutube()"
      *ngIf="tenantHasYoutube"
    >
      <i class="fab fa-youtube"></i>
      <div class="titlebar__tooltip">
        {{ 'menu.tooltip_youtu' | translate }}
      </div>
    </div>
    <div class="titlebar__icon" (click)="goToTiktok()" *ngIf="tenantHasTiktok">
      <i class="fab fa-tiktok"></i>
      <div class="titlebar__tooltip">
        {{ 'menu.tooltip_tiktok' | translate }}
      </div>
    </div>
  </div>
</div>
