import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SETTINGS } from 'src/app/SETTINGS';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { MasterDataService } from 'src/app/services/master-data.service';
import { ITenant } from '../interfaces/tenant';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private _userProfile: IUserProfile = {};

  constructor(
    private router: Router,
    private masterDataService: MasterDataService
  ) {}

  get userProfile(): IUserProfile {
    return this._userProfile;
  }

  get userTenantId(): string {
    return this.userProfile.tenantId;
  }

  checkUserStorage() {
    const userProfile = localStorage.getItem(SETTINGS.USER_PROFILE_COOKIE);
    if (userProfile) {
      this._userProfile = JSON.parse(userProfile) as IUserProfile;
      this.masterDataService
        .getMasterData(this._userProfile.tenantId)
        .subscribe();
    } else {
      this.logout();
    }
  }

  loginSuccess(user: IUserProfile) {
    this._userProfile = user;
    this.setUserCookie();
    this.masterDataService
      .getMasterData(this._userProfile.tenantId)
      .subscribe();
  }

  updateUserProfileData(user: IUserProfile) {
    this._userProfile.name = user.name;
    this._userProfile.surName = user.surName;
    this._userProfile.email = user.email;
    this._userProfile.address = user.address;
    this._userProfile.city = user.city;
    this._userProfile.countryId = user.countryId;
    this._userProfile.phone = user.phone;
    this.setUserCookie();
  }

  updateUserProfileTenantData(tenant: ITenant) {
    this._userProfile.tenantFacebook = tenant.facebook;
    this._userProfile.tenantInstagram = tenant.instagram;
    this._userProfile.tenantTiktok = tenant.tiktok;
    this._userProfile.tenantTwitter = tenant.twitter;
    this._userProfile.tenantYoutube = tenant.youtube;
    this._userProfile.tenantEmail = tenant.email;
    this._userProfile.tenantPhone = tenant.phone;
    this.setUserCookie();
  }

  isLoggedIn(): boolean {
    return this._userProfile.id != null;
  }

  updateUserProfileImage(image: string) {
    this._userProfile.image = image;
    this.setUserCookie();
  }

  updateUserProfileFirstTime() {
    this._userProfile.firstTime = false;
    this.setUserCookie();
  }

  setUserCookie() {
    localStorage.setItem(
      SETTINGS.USER_PROFILE_COOKIE,
      JSON.stringify(this._userProfile)
    );
  }

  logout() {
    this._userProfile = {};
    localStorage.removeItem(SETTINGS.USER_PROFILE_COOKIE);
    this.router.navigate(['']);
  }
}
