import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IUser } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { ProfileLabels, Profiles } from 'src/app/interfaces/enums/profiles';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { TenantService } from 'src/app/services/tenant.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { ITenant } from 'src/app/interfaces/tenant';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';

@Component({
  selector: 'app-user-suplant',
  templateUrl: './user-suplant.component.html',
  styleUrls: ['./user-suplant.component.scss']
})
export class UserSuplantComponent implements OnInit, OnDestroy {
  users: IUser[] = [];
  searchForm: UntypedFormGroup;
  showLoading: boolean;
  filtersVisibles: boolean;
  profileLabels = ProfileLabels;

  profiles = Object.values(Profiles).filter(
    (value) => typeof value === 'number' && value !== 1
  );
  tenants: ITenant[] = [];

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private userService: UserService,
    private userProfileService: UserProfileService,
    private tenantService: TenantService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  get userProfile(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  ngOnInit(): void {
    this.initForm();
    this.getTenants();
    this.getUsers();
  }

  ngOnDestroy(): void {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  search(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.showLoading = true;
    this.userService
      .getUsersByFilters(this.searchForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data: IUser[]) => {
        this.users = data;
      });
  }

  suplantIdentity(id: number): void {
    this.userService
      .suplantIdentity(id)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data: IUserProfile) => {
        this.userProfileService.loginSuccess(data);
        this.showSuccessToast('toast.USER_SUPLANT_SUCCESS');
        this.router.navigate([`dashboard`]);
      });
  }

  userImage(image: string): string {
    return SETTINGS.USERS_IMAGE_PATH + image;
  }

  getTenants(): void {
    this.tenantService
      .getTenantsByFilters(null)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.tenants = data;
      });
  }

  getTenantName(tenantId: string): string {
    const tenant = this.tenants.find((t) => t.uuid === tenantId);
    return tenant.name;
  }

  private initForm() {
    this.searchForm = this.fb.group({
      name: [''],
      profileId: [''],
      tenantId: ['']
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
