import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { Profiles } from 'src/app/interfaces/enums/profiles';
import { UserService } from 'src/app/services/user.service';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';

@Component({
  selector: 'app-modal-add-admin',
  templateUrl: './modal-add-admin.component.html',
  styleUrls: ['./modal-add-admin.component.scss']
})
export class ModalAddAdminComponent implements OnInit {
  @Input() openModal = false;
  @Input() tenantId = '';
  @Output() closeModal = new EventEmitter<boolean>();

  userForm: UntypedFormGroup;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.userForm, inputName);
  }

  saveUserForm(): void {
    if (!this.userForm.valid) {
      markFormGroupTouched(this.userForm);
      return;
    }
    const userData = this.userForm.value;
    userData.tenantId = this.tenantId;
    this.userService
      .postUser(userData)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((userId) => {
        this.showSuccessToast('toast.USER_CREATE_SUCCESS');
        this.router.navigate([`user-detail/${userId}`]);
      });
  }

  close(): void {
    this.openModal = false;
    this.closeModal.emit(true);
  }

  private initForm(): void {
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      surName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      profileId: [Profiles.ADMIN, Validators.required]
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
