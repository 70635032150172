import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { IAnimalBill } from 'src/app/interfaces/animalBill';

@Injectable({
  providedIn: 'root'
})
export class AnimalBillService {
  private apiUrl = SETTINGS.APIRUL + 'animalBill/';

  constructor(private httpClient: HttpClient) {}

  getAnimalBillsByAnimalId(animalId: number): Observable<IAnimalBill[]> {
    const params = new HttpParams().set('animalId', animalId);

    return this.httpClient
      .get<IAnimalBill[]>(this.apiUrl + 'getBillsByAnimalId.php', {
        params
      })
      .pipe(
        map((data: IAnimalBill[]) => {
          return data;
        })
      );
  }

  postBill(bill: IAnimalBill): Observable<number> {
    return this.httpClient.post<number>(this.apiUrl + 'addBill.php', bill).pipe(
      map((data: number) => {
        return data;
      })
    );
  }

  deleteAnimalById(billId: number, animalId: number) {
    const params = new HttpParams().set('id', billId).set('animalId', animalId);
    return this.httpClient.delete<void>(this.apiUrl + 'deleteBill.php', {
      params
    });
  }
}
