import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BindyConfirmComponent } from 'src/app/components/common/bindy-confirm/bindy-confirm.component';
import { IAnimalImage } from 'src/app/interfaces/animalImage';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { SETTINGS } from 'src/app/SETTINGS';

@Component({
  selector: 'bindy-file-upload-multiple',
  templateUrl: 'bindy-file-upload-multiple.component.html',
  styleUrls: ['bindy-file-upload-multiple.component.scss']
})
export class BindyFileUploadMultipleComponent implements OnInit {
  @ViewChild('fileUpload')
  fileUpload: ElementRef;

  @Input() set images(items: IAnimalImage[]) {
    this.imagesArray = items;
  }

  get animalImages() {
    return this.imagesArray;
  }

  @Input() animalId: number;
  @Input() openModal: boolean;
  @Input() freeAspect = false;

  @Output() imageUploaded = new EventEmitter<IAnimalImage>();
  @Output() onDelete = new EventEmitter();

  showCropperTool = false;

  file: File;
  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  maintainAspectRatio = true;
  aspectRatio = 1;
  resizeToWidth = 400;

  imagePath = SETTINGS.ANIMALS_IMAGE_PATH;
  imagesArray: IAnimalImage[] = [];
  principalImagePath: string;

  private _imageIndex = 0;
  private _slides: IAnimalImage[] = [];
  private _fileToUpload: IAnimalImage;
  private _isModalVisible = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.freeAspect) {
      this.maintainAspectRatio = false;
    }
    this.resizeToWidth = 550;
  }

  deleteImageFromServer(image: IAnimalImage): void {
    this.dialog
      .open(BindyConfirmComponent, {
        data: 'confirm.DELETE_IMAGE_CONFIRM'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.onDelete.emit(image);
        }
      });
  }

  onFileChange(event: any): void {
    this.showCropperTool = true;
    this.imgChangeEvt = event;
    this.file = event.target.files[0];
    this.openModal = true;
    this.addOverflowHidden();
  }

  close(): void {
    this.openModal = false;
    this.removeOverflowHidden();
  }

  cropImg(e: ImageCroppedEvent): void {
    this.cropImgPreview = e.base64;
    this._fileToUpload = {
      fileBase64: this.cropImgPreview,
      animalId: this.animalId,
      isPrincipal: false
    } as IAnimalImage;
  }

  saveImage(): void {
    this.imageUploaded.emit(this._fileToUpload);
    this.openModal = false;
    this.removeOverflowHidden();
  }

  imgLoad(): void {
    this.showCropperTool = true;
    // display cropper tool
  }

  initCropper(): void {
    // init cropper
  }

  imgFailed(): void {
    this.showErrorToast('toast.IMAGE_UPLOAD_FAILED');
  }

  trackByFn(index: number, item: IAnimalImage): number {
    return index;
  }

  private addOverflowHidden() {
    const htmlTag = document.getElementById('html');
    document.body.classList.add('overflow-hidden');
    htmlTag.classList.add('overflow-hidden');
  }

  private removeOverflowHidden() {
    const htmlTag = document.getElementById('html');
    htmlTag.classList.remove('overflow-hidden');
    document.body.classList.remove('overflow-hidden');
  }

  private showErrorToast(message: string): void {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('e'));
  }
}
