import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { environment } from 'src/environments/environment';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { IUserProfile } from 'src/app/interfaces/user-profile';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: { class: 'app-login' }
})
export class LoginComponent implements OnInit {
  public loginForm;
  public recoverForm;
  public showLoading = false;
  public showErrorUserText = false;
  public showLogin = true;
  public showRecovery = false;
  public showEmailSent = false;
  public showEmailError = false;

  test = environment.environmentText;

  constructor(
    private accountService: AccountService,
    private userProfileService: UserProfileService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      isProduction: [environment.production]
    });
    this.recoverForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.loginForm, inputName);
  }

  onSubmit(): void {
    this.showLoading = true;
    this.showErrorUserText = false;
    this.accountService
      .postUserOnLogin(this.loginForm.value)
      .subscribe((data: IUserProfile) => {
        this.showLoading = false;
        if (!data) {
          this.showErrorUserText = true;
        } else {
          this.userProfileService.loginSuccess(data);
          this.router.navigate(['dashboard']);
        }
      });
  }

  onSubmitRecover(): void {
    if (!this.recoverForm.valid) {
      markFormGroupTouched(this.recoverForm);
      return;
    }
    this.showLoading = true;
    const email = this.recoverForm.get('email').value;

    this.accountService.recoverPassword(email).subscribe((data) => {
      this.showLoading = false;
      this.showEmailSent = true;
      this.showRecovery = false;
      if (!data) {
        //user does not exist
        this.showEmailError = true;
      }
    });
  }

  recoverPassword(): void {
    this.showLogin = false;
    this.showRecovery = true;
  }

  backToLogin(): void {
    this.showRecovery = false;
    this.showEmailSent = false;
    this.showEmailError = false;
    this.showLogin = true;
  }
}
