<p class="u-input__text">
  {{ 'forms.language' | translate }}
</p>
<div class="c-language-selector u-input__content">
  <form [formGroup]="languageForm">
    <mat-select
      class="u-input__input"
      formControlName="language"
      [(value)]="selectedLanguage"
      (selectionChange)="selectLanguage()"
    >
      <mat-select-trigger>
        <div class="c-language-selector__option">
          <div class="c-language-selector__option-img">
            <img [src]="getLanguageImage(selectedLanguage)" alt="" />
          </div>
          <div class="c-language-selector__option-label">
            {{ languageLabels[selectedLanguage] | translate }}
          </div>
        </div>
      </mat-select-trigger>
      <mat-option *ngFor="let lang of languages" [value]="lang">
        <div class="c-language-selector__option">
          <div class="c-language-selector__option-img">
            <img [src]="getLanguageImage(lang)" alt="" />
          </div>
          <div class="c-language-selector__option-label">
            {{ languageLabels[lang] | translate }}
          </div>
        </div>
      </mat-option>
    </mat-select>
  </form>
</div>
