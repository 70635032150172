import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ISnackbarMaterialConfig } from 'src/app/interfaces/configs/snackbarMaterialConfig';
import { SuggestionStatus } from 'src/app/interfaces/enums/suggestionStatus';
import {
  SuggestionSubjects,
  SuggestionSubjectsLabels
} from 'src/app/interfaces/enums/suggestionSubjects';
import { ISuggestion } from 'src/app/interfaces/suggestion';
import { SuggestionService } from 'src/app/services/suggestion.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import {
  inputValidationUtil,
  markFormGroupTouched
} from 'src/app/shared/utils';
import { Profiles } from 'src/app/interfaces/enums/profiles';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-suggestion-detail',
  templateUrl: './suggestion-detail.component.html',
  styleUrls: ['./suggestion-detail.component.scss']
})
export class SuggestionDetailComponent implements OnInit, OnDestroy {
  suggestions: ISuggestion[] = [];
  suggestionForm: UntypedFormGroup;
  showLoading: boolean;
  submitted: boolean;
  suggestion: ISuggestion;
  subjectsLabels = SuggestionSubjectsLabels;
  subjects = Object.values(SuggestionSubjects);
  statuses = SuggestionStatus;
  isNew = true;

  private _suggestionId: number;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private suggestionService: SuggestionService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private userProfileService: UserProfileService
  ) {}

  get userId(): number {
    return this.userProfileService.userProfile.id;
  }

  get showBackButton(): boolean {
    return (
      Number(this.userProfileService.userProfile.profileId) === Profiles.GOD
    );
  }

  ngOnInit(): void {
    this._suggestionId = Number(this.activatedRoute.snapshot.params.id);
    this.initForm();
    if (this._suggestionId !== 0) {
      this.isNew = false;
      this.getSuggestionData();
    }
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  sendSuggestion() {
    if (!this.suggestionForm.valid) {
      markFormGroupTouched(this.suggestionForm);
      return;
    }
    this.submitted = true;
    this.showLoading = true;
    this.suggestionService
      .postSuggestion(this.suggestionForm?.value)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.initForm();
          this.showSuccessToast('toast.SUGGESTION_CREATED_SUCCESS');
        })
      )
      .subscribe((data) => {
        this.suggestion = data;
      });
  }

  changeStatus(status: SuggestionStatus) {
    this.showLoading = true;
    this.suggestionService
      .changeSuggestionStatus(this._suggestionId, status)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(
        finalize(() => {
          this.showLoading = false;
          this.showSuccessToast('toast.SUGGESTION_UPDATE_STATUS_SUCCESS');
          this.navigateToList();
        })
      )
      .subscribe((data) => {
        this.suggestion = data;
      });
  }

  deleteSuggestion() {
    this.showLoading = true;
    this.suggestionService
      .deleteSuggestionById(this._suggestionId)
      .subscribe((response) => {
        this.showSuccessToast('toast.SUGGESTION_DELETE_SUCCESS');
        this.navigateToList();
      });
  }

  inputValidation(inputName: string): boolean {
    return inputValidationUtil(this.suggestionForm, inputName);
  }

  private navigateToList() {
    this.router.navigate(['suggestion-list']);
  }

  private getSuggestionData() {
    this.showLoading = true;
    this.suggestionService
      .getSuggestionById(this._suggestionId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .pipe(finalize(() => (this.showLoading = false)))
      .subscribe((data) => {
        this.suggestionForm.patchValue(data[0]);
        this.suggestionForm.disable();
      });
  }

  private initForm() {
    this.suggestionForm = this.fb.group({
      userId: [this.userId],
      message: ['', Validators.required],
      subject: [SuggestionSubjects.SUGGESTION]
    });
  }

  private showSuccessToast(message: string) {
    const translatedMessage = this.translate.instant(message);
    this.snackBar.open(translatedMessage, '', new ISnackbarMaterialConfig('s'));
  }
}
