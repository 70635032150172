<app-titlebar
  [title]="'titles.newsList' | translate"
  icon="fas fa-newspaper"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="user-list">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addNews' | translate }}"
          icon="fa-plus"
          color="success"
          routerLink="/news-detail/0"
        ></bindy-button>

        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}:
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="title"
                  label="{{ 'forms.title' | translate }}"
                  placeholder="{{ 'forms.title_placeholder' | translate }}"
                ></bindy-input-text>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addNews' | translate }}"
          icon="fa-plus"
          color="success"
          routerLink="/news-detail/0"
        ></bindy-button>
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ newsList.length }}
        </div>
      </div>
    </div>

    <div class="u-table">
      <div class="hidden-xs">
        <div class="u-table__header news-list-table-header hidden-xs">
          <div>{{ 'news.image' | translate }}</div>
          <div>{{ 'news.active' | translate }}</div>
          <div>{{ 'news.title' | translate }}</div>
          <div>{{ 'news.subtitle' | translate }}</div>
          <div>{{ 'news.creationDate' | translate }}</div>
        </div>
      </div>
      <div *ngIf="newsList.length > 0">
        <div
          class="u-table__data news-list-table-data"
          *ngFor="let news of newsList; let i = index"
          (click)="goToNewsDetail(news.id)"
          [ngClass]="{ 'is-done': news.showInDashboard }"
        >
          <div class="u-table__square_image">
            <img *ngIf="news.image" [src]="newsImage(news.image)" />
          </div>
          <div>
            <span class="visible-xs u-bold">
              {{ 'news.active' | translate }}
            </span>
            {{
              (news.showNews === true ? 'common.yes' : 'common.no') | translate
            }}
          </div>
          <div>
            <span class="visible-xs u-bold"
              >{{ 'news.title' | translate }}: </span
            >{{ news.title }}
          </div>
          <div>
            <span class="visible-xs u-bold"
              >{{ 'news.subtitle' | translate }}: </span
            >{{ news.subtitle }}
          </div>
          <div>
            <span class="visible-xs u-bold"
              >{{ 'news.creationDate' | translate }}: </span
            >{{ news.creationDate.replace(' ', 'T') | date : 'dd/MM/YYYY' }}
          </div>
        </div>
      </div>
      <div class="u-table__no_results" *ngIf="newsList.length === 0">
        {{ 'common.noResult' | translate }}
      </div>
    </div>
  </div>
</div>
