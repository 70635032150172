import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { IAnimal } from 'src/app/interfaces/animal';
import { IChartData } from 'src/app/interfaces/charts/chartData';
import { IUserProfile } from 'src/app/interfaces/user-profile';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NewsService } from 'src/app/services/news.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { SETTINGS } from 'src/app/SETTINGS';
import { INews } from 'src/app/interfaces/news';
import { IDashboardTotals } from 'src/app/interfaces/dashboardTotals';
import { UserService } from 'src/app/services/user.service';
import { IUser } from 'src/app/interfaces/user';
import { IUsersFilters } from '../../interfaces/filters/usersFilters';
import { ProfileLabels } from 'src/app/interfaces/enums/profiles';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  isLinear = false;
  labels = true;
  showSpeciesChart = true;
  open = false;
  stepperOrientation = 'horizontal';

  getScreenWidth: any;
  chartData: IChartData[] = [];
  lastCreatedAnimal: IAnimal;
  tenantHasWeb: boolean;
  news: INews;

  profileLabels = ProfileLabels;
  users: IUser[] = [];

  totals: IDashboardTotals = {
    adopteds: 0,
    animals: 0,
    events: 0,
    localizations: 0
  };

  private _tenantWebUrl: string;
  private _isModalVisible = false;
  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private dashboardService: DashboardService,
    private newsService: NewsService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setScreenSize();
    this.getTotals();
    this.getNews();
    this.getUsers();
    this.checkIfShowSpeciesModal();
  }

  ngAfterViewInit() {
    //this.checkStepper();
    //this.createChartData();
    //this.getLastCreatedAnimal();
    this.getUserData();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  get userProfileData(): IUserProfile {
    return this.userProfileService.userProfile;
  }

  get tenantHasFacebook(): boolean {
    return (
      this.userProfileData.tenantFacebook &&
      this.userProfileData.tenantFacebook.trim() !== ''
    );
  }

  get tenantHasInstagram(): boolean {
    return (
      this.userProfileData.tenantInstagram &&
      this.userProfileData.tenantInstagram.trim() !== ''
    );
  }

  get tenantHasTwitter(): boolean {
    return (
      this.userProfileData.tenantTwitter &&
      this.userProfileData.tenantTwitter.trim() !== ''
    );
  }

  get tenantHasYoutube(): boolean {
    return (
      this.userProfileData.tenantYoutube &&
      this.userProfileData.tenantYoutube.trim() !== ''
    );
  }

  get tenantHasTiktok(): boolean {
    return (
      this.userProfileData.tenantTiktok &&
      this.userProfileData.tenantTiktok.trim() !== ''
    );
  }

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  goToFacebook() {
    window.open(this.userProfileData.tenantFacebook, '_blank');
  }

  goToInstagram() {
    window.open(this.userProfileData.tenantInstagram, '_blank');
  }

  goToTwitter() {
    window.open(this.userProfileData.tenantTwitter, '_blank');
  }

  goToYoutube() {
    window.open(this.userProfileData.tenantYoutube, '_blank');
  }

  goToTiktok() {
    window.open(this.userProfileData.tenantTiktok, '_blank');
  }

  getUserData(): void {
    this.tenantHasWeb = this.userProfileService.userProfile.tenantHasWebPage;
    this._tenantWebUrl = this.userProfileService.userProfile.tenantWebUrl;
  }

  goToTenantWeb() {
    window.open(this._tenantWebUrl, '_blank');
  }

  getTotals(): void {
    this.dashboardService
      .getTotals(this.userProfileService.userTenantId)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data) => {
        this.totals = data[0];
      });
  }

  // createChartData(): void {
  //   this.dashboardService
  //     .getSpeciesData(this.userProfileService.userTenantId)
  //     .pipe(takeUntil(this._ngUnsuscribe))
  //     .subscribe((data) => {
  //       this.chartData = data;
  //       this.checkIfShowSpeciesChart();
  //     });
  // }

  // checkIfShowSpeciesChart(): void {
  //   if (
  //     this.chartData.length === 0 ||
  //     (this.chartData.length > 2 &&
  //       this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE)
  //   ) {
  //     this.showSpeciesChart = false;
  //   }
  // }

  // checkStepper(): void {
  //   let stop = false;

  //   if (!this.userProfileProfile.address) {
  //     this.stepper.selectedIndex = 0;
  //     stop = true;
  //   }
  //   if (!stop && !this.userProfileProfile.phone) {
  //     this.stepper.selectedIndex = 1;
  //     stop = true;
  //   }
  //   if (!stop && !this.userProfileProfile.image) {
  //     this.stepper.selectedIndex = 1;
  //     this.stepper.selectedIndex = 2;
  //     stop = true;
  //   }
  //   if (
  //     this.userProfileProfile.address &&
  //     this.userProfileProfile.phone &&
  //     this.userProfileProfile.image
  //   ) {
  //     this.stepper.selectedIndex = 1;
  //     this.stepper.selectedIndex = 2;
  //     this.stepper.selectedIndex = 3;
  //   }
  // }

  // getLastCreatedAnimal(): void {
  //   this.dashboardService
  //     .getLastCreatedAnimal(this.userProfileService.userTenantId)
  //     .pipe(takeUntil(this._ngUnsuscribe))
  //     .subscribe((data) => {
  //       if (data.length > 0) {
  //         this.lastCreatedAnimal = data[0];
  //       }
  //     });
  // }

  // goToMyProfile(): void {
  //   this.router.navigate([`my-profile`]);
  // }

  goToMoreNews(): void {
    this.router.navigate([`news`]);
  }

  // goToAnimalDetail(animalId: string): void {
  //   this.router.navigate([`animal-detail/${animalId}`]);
  // }

  // animalImage(image: string): string {
  //   return SETTINGS.ANIMALS_IMAGE_PATH + image;
  // }

  newsImage(image: string): string {
    return SETTINGS.NEWS_IMAGE_PATH + image;
  }

  closeModalWelcome(): void {
    this.open = false;
    this.closeModal();
  }

  private closeModal() {
    document.body.classList.remove('overflow-hidden');
    this._isModalVisible = false;
    this.removeOverflowHidden();
  }

  private checkIfShowSpeciesModal(): void {
    if (this.userProfileData.firstTime) {
      this.open = true;
      this.presentModal();
    }
  }

  private presentModal() {
    const htmlTag = document.getElementById('html');
    this._isModalVisible = !this._isModalVisible;
    if (this._isModalVisible) {
      document.body.classList.add('overflow-hidden');
      htmlTag.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      this.removeOverflowHidden();
    }
  }

  private removeOverflowHidden() {
    const htmlTag = document.getElementById('html');
    htmlTag.classList.remove('overflow-hidden');
  }

  private setScreenSize() {
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < SETTINGS.MOBILE_WINDOWS_SIZE) {
      this.stepperOrientation = 'vertical';
    }
  }

  private getNews(): void {
    this.newsService
      .getDashboardNews()
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data) => {
        this.news = data[0];
      });
  }

  private getUsers(): void {
    const params: IUsersFilters = {
      tenantId: this.userProfileTenantId
    };
    this.userService
      .getUsersByFilters(params)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data: IUser[]) => {
        this.users = data.sort((a, b) => a.profileId - b.profileId);
      });
  }
}
