import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEventsFilters } from 'src/app/interfaces/filters/eventsFilters';
import { EventService } from 'src/app/services/event.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { mapOrcaEventsToFullCalendarEvents } from 'src/app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { IEvent } from 'src/app/interfaces/event';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  events: IEvent[] = [];
  calendarOptions!: CalendarOptions;
  showLoading = false;

  private _ngUnsuscribe: Subject<void> = new Subject<void>();

  constructor(
    private eventService: EventService,
    private userProfileService: UserProfileService,
    private translate: TranslateService,
    private router: Router
  ) {}

  get userProfileTenantId(): string {
    return this.userProfileService.userTenantId;
  }

  ngOnInit() {
    this.getEvents();
  }

  ngOnDestroy() {
    this._ngUnsuscribe.next();
    this._ngUnsuscribe.complete();
  }

  createCalendarOptions(): void {
    this.calendarOptions = {
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin],
      firstDay: 1,
      locale: this.translate.instant('fullCalendar.locale'),
      customButtons: {
        addEvent: {
          text: '+ ' + this.translate.instant('fullCalendar.addEvent'),
          click: () => this.addEvent()
        }
      },
      eventClick: (data) => this.eventClick(data.event),
      headerToolbar: {
        left: 'addEvent',
        center: 'title',
        right: 'prev,next'
      }
    };
  }

  getEvents(): void {
    this.showLoading = true;
    const filters: IEventsFilters = {
      tenantId: this.userProfileTenantId,
      month: 0,
      year: 0,
      id: 0
    };

    this.eventService
      .getEventsByFilters(filters)
      .pipe(takeUntil(this._ngUnsuscribe))
      .subscribe((data) => {
        this.createCalendarOptions();
        this.showLoading = false;
        this.calendarOptions.events = mapOrcaEventsToFullCalendarEvents(data);
      });

    //
  }

  addEvent() {
    this.router.navigate([`events-detail/0`]);
  }

  eventClick(event) {
    this.router.navigate([`events-detail/${event.id}`]);
  }
}
