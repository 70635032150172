<app-titlebar
  [title]="'titles.tenant_list' | translate"
  icon="fas fa-building"
></app-titlebar>

<app-loading *ngIf="showLoading"></app-loading>

<div *ngIf="!showLoading" class="tenant-list">
  <div class="u-content">
    <div class="visible-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addTenant' | translate }}"
          icon="fa-plus"
          color="success"
          (onClick)="goToTenantDetail(0)"
        ></bindy-button>

        <div class="u-filters__button">
          <i
            class="color-light-blue fas fa-sliders-h"
            (click)="showFilters()"
          ></i>
        </div>
      </div>
    </div>

    <div class="u-filters__container" *ngIf="filtersVisibles">
      <div class="u-filters__form">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
          <fieldset class="u-fieldset">
            <legend class="u-subtitle u-legend">
              <i class="fas fa-search"></i> {{ 'titles.filters' | translate }}:
            </legend>
            <div class="u-filters__content">
              <div class="u-input">
                <bindy-input-text
                  formControlName="name"
                  label="{{ 'forms.name' | translate }}"
                  placeholder="{{ 'forms.name_placeholder' | translate }}"
                  type="text"
                ></bindy-input-text>
              </div>
              <div class="u-filters__buttons">
                <bindy-button
                  text="{{ 'common.button_clean' | translate }}"
                  icon="fa-broom"
                  color="third"
                  (onClick)="cleanFilters()"
                ></bindy-button>
                <bindy-button
                  text="{{ 'common.button_search' | translate }}"
                  icon="fa-search"
                  type="submit"
                  color="secondary"
                >
                </bindy-button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>

    <div class="hidden-xs">
      <div class="u-actions__container">
        <bindy-button
          text="{{ 'common.button_addTenant' | translate }}"
          icon="fa-plus"
          color="success"
          (onClick)="goToTenantDetail(0)"
        ></bindy-button>
        <div class="u-list__total">
          {{ 'common.total_results' | translate }}: {{ tenants.length }}
        </div>
      </div>
    </div>

    <div *ngIf="tenants.length > 0" class="u-table">
      <div class="u-table__header tenant-list-table-header">
        <div></div>
        <div>{{ 'common.name' | translate }}</div>
        <div>{{ 'common.email' | translate }}</div>
        <div>{{ 'common.phone' | translate }}</div>
        <div>{{ 'tenant.hasWebPage' | translate }}</div>
        <div>{{ 'tenant.totalAdmins' | translate }}</div>
      </div>
      <div>
        <div
          class="u-table__data tenant-list-table-data"
          *ngFor="let tenant of tenants"
          (click)="goToTenantDetail(tenant.id, tenant.uuid)"
        >
          <div class="u-table__circle_image">
            <img
              [src]="
                tenant.image !== null
                  ? tenantImage(tenant.image)
                  : '/assets/img/user_default.png'
              "
            />
          </div>
          <div class="hidden-xs">{{ tenant.name }}</div>
          <div class="hidden-xs tenant-list-table-email">
            {{ tenant.email }}
          </div>
          <div class="hidden-xs">{{ tenant.phone }}</div>
          <div class="hidden-xs">
            {{
              (tenant.hasWebPage === true ? 'common.yes' : 'common.no')
                | translate
            }}
          </div>
          <div class="hidden-xs">
            <div *ngIf="tenant.totalAdmins > 0">{{ tenant.totalAdmins }}</div>
            <div *ngIf="tenant.totalAdmins === 0">
              <bindy-button
                text="{{ 'common.button_addAdmin' | translate }}"
                icon="fa-plus"
                color="success"
                (click)="
                  $event.stopPropagation(); showNewAdminModal(tenant.uuid)
                "
              ></bindy-button>
            </div>
          </div>

          <div class="visible-xs">
            <div>{{ tenant.name }}</div>
            <div class="break-all">{{ tenant.email }}</div>
            <div>{{ tenant.phone }}</div>
            <div *ngIf="tenant.totalAdmins > 0">
              <b>{{ 'tenant.totalAdmins' | translate }}: </b
              >{{ tenant.totalAdmins }}
            </div>
            <div *ngIf="tenant.totalAdmins === 0">
              <bindy-button
                text="{{ 'common.button_addAdmin' | translate }}"
                icon="fa-plus"
                color="success"
                (click)="
                  $event.stopPropagation(); showNewAdminModal(tenant.uuid)
                "
              ></bindy-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-add-admin
  [openModal]="showAdminModal"
  [tenantId]="tenantSelectedId"
  (closeModal)="closeModalAdminModal()"
></app-modal-add-admin>
