import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SETTINGS } from 'src/app/SETTINGS';
import { INewsFilters } from 'src/app/interfaces/filters/newsFilters';
import { INews } from 'src/app/interfaces/news';
import { IImageUpload } from 'src/app/interfaces/imageUpload';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private apiUrl = SETTINGS.APIRUL + '/news/';

  constructor(private httpClient: HttpClient) {}

  getNewsByFilters(filters: INewsFilters): Observable<INews[]> {
    return this.httpClient
      .post<INews[]>(this.apiUrl + 'getNewsByFilters.php', filters)
      .pipe(
        map((data: INews[]) => {
          return data;
        })
      );
  }

  getNewsById(newsId: number): Observable<INews> {
    const filters = {
      id: newsId
    } as INewsFilters;
    return this.httpClient
      .post<INews>(this.apiUrl + 'getNewsByFilters.php', filters)
      .pipe(
        map((data: INews) => {
          return data;
        })
      );
  }

  getDashboardNews(): Observable<INews> {
    const filters = {
      showInDashboard: true,
      showNews: true
    } as INewsFilters;
    return this.httpClient
      .post<INews>(this.apiUrl + 'getNewsByFilters.php', filters)
      .pipe(
        map((data: INews) => {
          return data;
        })
      );
  }

  postNews(news: INews): Observable<number> {
    return this.httpClient
      .post<number>(this.apiUrl + 'addOrUpdateNews.php', news)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  deleteNewsById(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteNews.php?newsId=' + id
    );
  }

  showHideNews(id: number, show: boolean): Observable<number> {
    const data = {
      id: id,
      showNews: show
    };
    return this.httpClient
      .post<number>(this.apiUrl + 'showHideNews.php', data)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  markNewsAsDashboard(newsId: number): Observable<number> {
    const filters = {
      id: newsId
    } as INewsFilters;
    return this.httpClient
      .post<number>(this.apiUrl + 'markNewsAsDashboard.php', filters)
      .pipe(
        map((data: number) => {
          return data;
        })
      );
  }

  deleteNewsImage(newsId: number): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrl + 'deleteNewsImage.php?newsId=' + newsId
    );
  }

  uploadNewsImage(imageUpload: IImageUpload) {
    return this.httpClient.post<void>(
      this.apiUrl + 'uploadNewsImage.php',
      imageUpload,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }
}
